import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ResponsiveMasonry } from "react-responsive-masonry";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import Pagination from "components/Pagination/Pagination";
import ButtonHover from "./ButtonHover";
import InstaOne from "assets/images/insta1.png";
import { MENUS } from "./data";

import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "./socialMedia.styles";

const SocialMediaLinkedIn = () => {
  const dispatch = useDispatch();

  const [CurrentPage, setCurrentPage] = useState(1);
  const [SelectedCategory, setSelectedCategory] = useState(0);
  const [SelectedSubCategory, setSelectedSubCategory] = useState(0);

  const linkedinPostsByCategory = useSelector(
    (state) => state.dashboard.linkedinPostsByCategory
  );

  const linkedinSubCategoriesByCategory = useSelector(
    (state) => state.dashboard.linkedinSubCategoriesByCategory
  );

  const allLinkedinCategories = useSelector(
    (state) => state.dashboard.allLinkedinCategories
  );

  const allLinkedinPosts = useSelector(
    (state) => state.dashboard.allLinkedinPosts
  );

  const isAllLinkedinPostsLoading = useSelector(
    (state) => state.dashboard.isAllLinkedinPostsLoading
  );

  const isLinkedinPostsByCategoryLoading = useSelector(
    (state) => state.dashboard.isLinkedinPostsByCategoryLoading
  );

  const linkedinPosts = useSelector((state) => state.dashboard.linkedinPosts);

  const arePostsLoading =
    isAllLinkedinPostsLoading || isLinkedinPostsByCategoryLoading;

  useEffect(() => {
    dispatch(
      DashboardActions.getAllLinkedinPostsRequest({
        payload: {
          pageNo: CurrentPage,
        },
        callback: () => null,
      })
    );

    dispatch(
      DashboardActions.getAllLinkedinCategoriesRequest({
        callback: (res) => {
          // console.log("getAllLinkedinCategoriesRequest", res)
          dispatch(
            DashboardActions.getLinkedinSubCategoriesByCategoryRequest({
              payload: {
                category: res?.getAllCategories?.data[0]?.id,
              },
              callback: () => null,
            })
          );
        },
      })
    );
  }, []);

  const getSubCategories = (categoryId) => {
    dispatch(
      DashboardActions.getLinkedinSubCategoriesByCategoryRequest({
        payload: {
          category: categoryId,
        },
        callback: () => null,
      })
    );
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    getSubCategories(categoryId);
  };

  const handleSubCategoryChange = (subCategoryId) => {
    setCurrentPage(1);
    setSelectedSubCategory(subCategoryId);
    dispatch(
      DashboardActions.getAllLinkedinPostsByCategoriesRequest({
        payload: {
          category: SelectedCategory,
          sub_category: subCategoryId,
          pageNo: 1,
        },
        callback: () => null,
      })
    );
  };

  const categories =
  allLinkedinCategories?.data && allLinkedinCategories?.data?.length
    ? allLinkedinCategories?.data?.map((category) => (
      { value: category.id, label: category.title }
    ))
    : []

  const subCategories =
    linkedinSubCategoriesByCategory?.data && linkedinSubCategoriesByCategory?.data?.length
    ? linkedinSubCategoriesByCategory?.data?.map((subCategory) => (
      { value: subCategory.id, label: subCategory.title }
    ))
    : []

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="linkedin-page second-sidebar-content">
          <S.MCard>
            <S.ClientDetailsContentHeading>
              LinkedIn Datenbank
            </S.ClientDetailsContentHeading>
            <S.ClientDetailsContentSubHeading>
              Mit LinkedIn erreichst du einfach mögliche Kooperationspartner.
              Verwende unsere Assets für dein Profil.
            </S.ClientDetailsContentSubHeading>

            <br />
            <br />
            <S.Divider />

            <S.DropDown>
              <Select
                options={categories}
                name="sex"
                className="select-width"
                classNamePrefix="react-select"
                placeholder="Auswählen"
                onChange={(item) => handleSubCategoryChange(item.value)}
                isSearchable={false}
              />

              <span>&gt;&gt;</span>

              <Select
                options={subCategories}
                name="sex"
                className="select-width"
                classNamePrefix="react-select"
                placeholder="Auswählen"
                isSearchable={false}
                onChange={(item) => handleSubCategoryChange(item.value)}
              />
            </S.DropDown>

            {arePostsLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            {!arePostsLoading && linkedinPosts?.data?.length === 0 && (
              <h3 className="text-center">No record found</h3>
            )}

            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <S.LinklindWrap>
                {!arePostsLoading &&
                  linkedinPosts?.data?.map(
                    ({
                      id,
                      title,
                      cover_image,
                      category_name,
                      direct_download_link,
                      google_link,
                      canva_link,
                    }) => (
                      <S.LinklindBox key={id}>
                        <S.LinklindImage>
                          <img src={cover_image || InstaOne} alt="" />
                        </S.LinklindImage>
                        <S.LinklindInfo>
                          <span>{category_name}</span>
                          <h3>{title}</h3>
                          <ButtonHover
                            links={{
                              direct_download_link,
                              google_link,
                              canva_link,
                            }}
                          />
                        </S.LinklindInfo>
                      </S.LinklindBox>
                    )
                  )}
              </S.LinklindWrap>
            </ResponsiveMasonry>

            <br />
            {!arePostsLoading && (
              <Pagination
                currentPage={CurrentPage}
                recordsPerPage={10}
                totalRecords={linkedinPosts?.total}
                onPageChange={(pageNo) => {
                  setCurrentPage(pageNo);
                  if (linkedinPosts?.path.includes("getAllPostsByCategory")) {
                    dispatch(
                      DashboardActions.getAllLinkedinPostsByCategoriesRequest({
                        payload: {
                          category: SelectedCategory,
                          sub_category: SelectedSubCategory,
                          pageNo: pageNo,
                        },
                        callback: () => null,
                      })
                    );
                  } else {
                    dispatch(
                      DashboardActions.getAllLinkedinPostsRequest({
                        payload: {
                          pageNo: pageNo,
                        },
                        callback: () => null,
                      })
                    );
                  }
                }}
              ></Pagination>
            )}
          </S.MCard>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default SocialMediaLinkedIn;
