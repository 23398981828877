import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { number, object, string } from "yup";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import Input from "components/formComponents/input/Input";

import CopyIcon from "assets/images/new-copy.png";
import ClientDetailsSidebar from "./ClientDetailsSidebar";

import * as AuthActions from "redux/auth/auth.actions";
import * as S from "./ClientDetails.styles";
import "./clientDetails.scss";

const Overview = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const updateUserFormRef = useRef();

  const trainerData = useSelector((state) => state.auth.trainerData);
  const clientDetails = useSelector(
    (state) => state.auth.getClientDetails?.data
  );
  const isClientDetailsLoading = useSelector(
    (state) => state.auth.isClientDetailsLoading
  );

  const [IsSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getClientDetails();
  }, []);

  const initialValues = {
    prename: clientDetails?.prename || "",
    surname: clientDetails?.surname || "",
    email: clientDetails?.email || "",
    phoneNumber: clientDetails?.mobile || "",
    startDate: clientDetails?.startdate || "",
    endDate: clientDetails?.locked || "",
    token: clientDetails?.nutritionconcept_token || "",
    sex: clientDetails?.sex || "",
    birthday: clientDetails?.birthday || "",
    subscribeStatus: clientDetails?.subscribe_status || "",
  };

  const validationSchema = object().shape({
    prename: string().typeError("You must enter a String").required("Required"),
    surname: string().typeError("You must enter a String").required("Required"),
    email: string()
      .email()
      .typeError("You must enter a String")
      .required("Required"),
    phoneNumber: number()
      .typeError("You must enter a Number")
      .required("Required"),
    // startDate: string().typeError("You must enter a String").required("Required"),
    // endDate: string().typeError("You must enter a String").required("Required"),
    // token: string().typeError("You must enter a String").required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    formData.append("userID", userId);
    formData.append("prename", values.prename);
    formData.append("surname", values.surname);
    formData.append("email", values.email);
    formData.append("mobile", values.phoneNumber);
    // formData.append("startDate", values.startDate);
    // formData.append("endDate", values.endDate);
    // formData.append("token", values.token);
    setIsSubmitting(true);

    dispatch(
      AuthActions.updateUserDetailsByTrainerRequest({
        formData,
        func: () => {
          setIsSubmitting(false);
          getClientDetails();
        },
      })
    );
  };

  const handleUserUpdateFormSubmit = () => {
    if (updateUserFormRef.current) {
      updateUserFormRef.current.handleSubmit();
    }
  };

  const getClientDetails = () => {
    dispatch(
      AuthActions.getClientDetailsRequest({
        payload: {
          trainerID: trainerData?.trainerID,
          userID: +userId,
        },
        callback: (res) => {},
      })
    );
  };

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
    toast("📃 Erfolgreich kopiert.");
  };

  return (
    <>
      <ToastContainer />
      <S.ClientDetailsOverallContainer>
        <S.ClientDetailsContainer>
          <div className="second-sidebar-wrapper">
            <ClientDetailsSidebar />
          </div>
          <div className="second-sidebar-content" style={{ padding: "1.5rem" }}>
            <S.ContainerBox>
              <S.Header>
                <div>
                  <S.Heading>Übersicht</S.Heading>
                  <S.SubHeading>
                    Die personenbezogenen Daten deines Klienten.
                  </S.SubHeading>
                </div>
                <div>
                  <button
                    className="btn-dark"
                    onClick={() => handleUserUpdateFormSubmit()}
                    disabled={IsSubmitting}
                  >
                    {IsSubmitting ? "Loading..." : "Speichern"}
                  </button>
                </div>
              </S.Header>

              <S.Divider></S.Divider>
              <S.PaddingBox>
                {isClientDetailsLoading && (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="text-center">
                      <div className="loader mx-auto"></div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}
                {!isClientDetailsLoading && (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onFormSubmit}
                    innerRef={updateUserFormRef}
                    enableReinitialize
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      errors,
                      isValid,
                      dirty,
                      isSubmitting,
                    }) => (
                      <form
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                      >
                        <>
                          <S.ClientDetailsOverviewContainer>
                            <S.ClientDetailsOverviewInnerContainer>
                              <S.ClientDetailsOverviewInputContainer>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    Vorname
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    style={{ width: "100%" }}
                                    placeholder="prename"
                                    name="prename"
                                    onChange={handleChange}
                                    value={values.prename}
                                  />
                                </div>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    Nachname
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    style={{ width: "100%" }}
                                    placeholder="surname"
                                    name="surname"
                                    onChange={handleChange}
                                    value={values.surname}
                                  />
                                </div>
                                <S.FormControl>
                                  <S.ClientDetailsOverviewInputLabel className="info-label">
                                    E-mail
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    className="input-custom copy-text"
                                    filled
                                    style={{ width: "100%" }}
                                    disabled
                                    placeholder="email"
                                    name="email"
                                    onChange={handleChange}
                                    value={values.email}
                                  />
                                  <img
                                    className="copy-icon"
                                    src={CopyIcon}
                                    alt="CopyIcon"
                                    onClick={() =>
                                      copyToClipBoard(values.email)
                                    }
                                  />
                                </S.FormControl>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    Handy
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    style={{ width: "100%" }}
                                    placeholder="phoneNumber"
                                    name="phoneNumber"
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                  />
                                </div>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    Start des Coaching
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    className="input-custom copy-text"
                                    placeholder="Start des Coaching"
                                    name="startDate"
                                    value={
                                      values.startDate
                                        ? moment(
                                            new Date(values.startDate)
                                          ).format("DD.MM.YYYY")
                                        : ""
                                    }
                                    filled
                                    disabled
                                  />
                                </div>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    Ende des Coachings
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    className="input-custom copy-text"
                                    placeholder="Ende des Coachings"
                                    name="endDate"
                                    value={
                                      values.endDate
                                        ? moment(
                                            new Date(values.endDate)
                                          ).format("DD.MM.YYYY")
                                        : ""
                                    }
                                    filled
                                    disabled
                                  />
                                </div>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    Geschlecht
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    className="input-custom copy-text"
                                    placeholder="gender"
                                    name="sex"
                                    value={
                                      values.sex === "female"
                                        ? "Frau"
                                        : values.sex === "male"
                                        ? "Mann"
                                        : values.sex
                                    }
                                    filled
                                    disabled
                                  />
                                </div>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    Geburtstag
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    className="input-custom copy-text"
                                    placeholder="12.12.1996"
                                    name="birthday"
                                    value={
                                      values.birthday
                                        ? moment(
                                            new Date(values.birthday)
                                          ).format("DD.MM.YYYY")
                                        : ""
                                    }
                                    filled
                                    disabled
                                  />
                                </div>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    Status
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    className="input-custom copy-text"
                                    placeholder="Status"
                                    name="status"
                                    value={values.subscribeStatus}
                                    filled
                                    disabled
                                  />
                                </div>
                                <div>
                                  <S.FormControl>
                                    <S.ClientDetailsOverviewInputLabel className="info-label">
                                      Token
                                    </S.ClientDetailsOverviewInputLabel>
                                    <Input
                                      className="input-custom copy-text"
                                      style={{ width: "100%" }}
                                      placeholder="token"
                                      name="token"
                                      onChange={handleChange}
                                      value={values.token}
                                      filled
                                      disabled
                                    />
                                    <img
                                      className="copy-icon"
                                      src={CopyIcon}
                                      alt="CopyIcon"
                                      onClick={() =>
                                        copyToClipBoard(values.token)
                                      }
                                    />
                                  </S.FormControl>
                                </div>
                              </S.ClientDetailsOverviewInputContainer>
                            </S.ClientDetailsOverviewInnerContainer>
                          </S.ClientDetailsOverviewContainer>
                        </>
                      </form>
                    )}
                  </Formik>
                )}
              </S.PaddingBox>
            </S.ContainerBox>
          </div>
        </S.ClientDetailsContainer>
      </S.ClientDetailsOverallContainer>
    </>
  );
};

export default Overview;
