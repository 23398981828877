import styled from "styled-components";
import DownArrow from "assets/images/down-arrow.svg";

export const Container = styled.div`
  /* display: grid;
  grid-template-columns: 300px 1fr; */
  display: flex;
`;
export const Wrapper = styled.div`
  padding: 1.5rem;
`;
export const MCard = styled.div`
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 10px;
  padding: 1.875rem 1.56rem;
  background-color: white;
`;

export const ClientDetailsContentHeading = styled.h6`
  font-size: 1.25rem;
  color: #142c1f;
  margin-bottom: 0.3125rem;
  margin-top:  0;
`;

export const ClientDetailsContentSubHeading = styled.div`
  font-size: 0.9375rem;
  color: #818d88;
  margin-top: 1.25rem;
  font-weight: 400;
`;


export const InstagramWrap = styled.div``;
export const HalfImage = styled.div`
  border: 1px solid #dddddd;
  border-radius: 10px;
  /* max-width: 310px; */

  img {
  }
`;
export const UpperSection = styled.div`
  width: 100%;
  /* height: 200px; */
  img {
    width: 100%;
    border-radius: 10px;
    /* height: 190px; */
    object-fit: cover;
    object-position: top;
  }
`;
export const BottomSection = styled.div`
  padding: 1.25rem;
  width: 100%;
  box-sizing: border-box;

  h5 {
    letter-spacing: 0.2px;
    color: #2ecf96;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    margin: 0 0 0.625rem;
  }
  p {
    margin: 0 0 1.25rem;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #44554c;
  }
`;
export const FullImage = styled.div`
  width: 100%;
  /* max-width: 310px; */
  /* height: 500px; */
  position: relative;
  border-radius: 10px;
  border: 1px solid #dddddd;
  img {
    object-fit: cover;
    object-position: top;
    height: 100%;
    border-radius: 10px;
    width: 100%;
  }
  p {
    color: #ffffff;
  }
`;
export const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    12deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 212, 255, 0) 41%
  ) !important;
`;

export const DropDown = styled.div`
  display: flex;
  align-items: center;
  margin: 2.5rem 0 1.875rem;

  span {
    padding: 0px 0.937rem;
    color: #a5b5af;
  }

  .select-width {
    min-width: 150px;
  }

  select {
    height: 39px;
    background: #f5f5f5;
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #44554c;
    padding: 0 2.25rem 0 1rem;
    background-image: url("${DownArrow}");
    background-repeat: no-repeat;
    background-position: center right 0.3125rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
  }
`;

export const LinklindWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.75rem;
`;

export const LinklindBox = styled.div`
  border: 0.0625rem solid #dddddd;
  border-radius: 0.63rem;
`;

export const LinklindImage = styled.div`
  img {
    border-radius: 0.625rem;
    /* height: 9.375rem; */
    width: 100%;
    object-fit: cover;
  }
`;

export const LinklindInfo = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  span {
    color: #2ecf96;
    text-transform: uppercase;
    font-size: 0.8125rem;
    line-height: 0.9375rem;
    font-weight: 500;
  }
  h3 {
    margin: 0;
    color: #44554c;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 500;
    padding: 0.625rem 0 1.25rem;
  }
  .button-hover-inner {
    left: 1.875rem;
  }
  .plus-btn {
    width: 1.875rem;
    height: 1.875rem;
    span {
      font-size: 1.2rem;
    }
  }
  .button-hover-inner a {
    width: 1.875rem;
    height: 1.875rem;
    img {
      max-width: 1.25rem;
    }
  }
`;

// ticket page
export const OpenTicketWrap = styled.div`
  margin-top: 2.5rem;
`;
export const OpenTicketRow = styled.div`
  display: grid;
  grid-template-columns: 7.5rem 1fr 1fr;
  gap: 1.25rem;
`;

export const OpenTicketBox = styled.div`
  input {
    font-size: 1rem;
    line-height: 1.1875rem;
    font-weight: 500;
    color: #44554c;
    background: #f8f8f8;
    -webkit-text-fill-color: #44554c;
    &:disabled {
      background: #f8f8f8;
    }
  }

  textarea {
    height: 18.75rem;
    resize: none;
  }
`;
export const ClientDetailsOverviewInputLabel = styled.div`
  font-size: 14px;
  color: #44554c;
  line-height: 24px;
  margin: 20px 0 10px;
  font-weight: 500;
`;
export const ClientDetailsSaveButton = styled.div`
  div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
  }
  button {
    width: 127px;
    height: 43px;
    font-size: 1rem;
    line-height: 1.1875rem;
    font-weight: 400;
    background-color: #1c9d87;
  }
`;

export const TablePaginationDetailsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
`;

export const TablePaginationNumbersContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const TablePaginationNumber = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 5px;
  border: ${(props) => (props.active ? "none" : "1px solid #DDDDDD")};
  background: ${(props) => (props.active ? "#44554C" : "white")};
  color: ${(props) => (props.active ? "white" : "#44554C")};
  cursor: pointer;
  line-height: 25px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;
