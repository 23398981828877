import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";

import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import { MENUS } from "./SideMenu";
import * as S from "./MarketingPortal.styles";
import * as SupplementsActions from "redux/supplements/supplements.actions";
import { getEmailTriggerData } from "./emailData";
import BackArrowIcon from "assets/images/icon-back-arrow.svg";
import { DetailsDropDown } from "./data";
import DatePicker from "components/formComponents/datePicker/DatePicker";
import TextArea from "components/formComponents/textArea/TextArea";

function TriggerEmailButton({ emailData }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const triggerSendEmail = () => {
    setLoading(true);

    dispatch(
      SupplementsActions.triggerTrainerEmail({
        payload: emailData,
        cb: (err) => {
          setLoading(false);
          if (err) return toast("Es ist ein Fehler aufgetreten.");
          toast("E-Mail erfolgreich gesendet");
        },
      })
    );
  };

  return (
    <button onClick={triggerSendEmail} className="btn-outline sm">
      Senden
      {loading ? <div className="loader-small"></div> : ""}
    </button>
  );
}

const MainLeadDetails = () => {
  const [dropDownValue, setDropDownValue] = useState("");
  const [leadDetailValues, setLeadDetailValues] = useState({
    lead_notice: "",
    lead_first_contacted: "",
    lead_second_contacted: "",
    lead_appointment_date: "",
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { leadId } = useParams();
  const trainerProfileData = useSelector((state) => state.auth.trainerData);

  const isTrainerLeadDetailsLoading = useSelector(
    (state) => state.supplements.isTrainerLeadDetailsLoading
  );
  const trainerLeadDetails = useSelector(
    (state) => state.supplements.trainerLeadDetails
  );

  const trainerProfileTempDetails = useSelector(
    (state) => state.dashboard.trainerProfileTempDetails
  );
  
  useEffect(() => {
    console.log("trainerProfileData", trainerProfileData);
    dispatch(
      SupplementsActions.getTrainerLeadDetailsRequest({
        payload: {
          lead_id: leadId,
        },
        callback: (res) => {
          setDropDownValue(
            DetailsDropDown.find(
              (item) =>
                item.value === res.getTrainerLeadDataByLeadId.lead_status
            )
          );
          setLeadDetailValues({
            lead_notice: res.getTrainerLeadDataByLeadId?.lead_notice ?? "",
            lead_first_contacted:
              res.getTrainerLeadDataByLeadId?.lead_first_contacted ?? "",
            lead_second_contacted:
              res.getTrainerLeadDataByLeadId?.lead_second_contacted ?? "",
            lead_appointment_date:
              res.getTrainerLeadDataByLeadId?.lead_appointment_date ?? "",
          });
        },
      })
    );
  }, [dispatch, leadId]);
  useEffect(() => {
    if (!dropDownValue.value) return;
    const payload = {
      connected_trainer: trainerProfileData?.trainerID,
      lead_id: leadId,
      lead_status: dropDownValue.value,
    };
    dispatch(
      SupplementsActions.updateLeadCenterUpdateRequest({
        payload,
        callback: () => {},
      })
    );
  }, [dispatch, dropDownValue.value, leadId, trainerProfileData?.trainerID]);

  const handleSave = () => {
    const payload = {
      connected_trainer: trainerProfileData?.trainerID,
      lead_id: leadId,
    };
    if (dropDownValue.value) {
      payload.lead_status = dropDownValue.value;
    }
    if (leadDetailValues.lead_first_contacted) {
      payload.lead_first_contacted = moment(
        leadDetailValues.lead_first_contacted,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");
    }
    if (leadDetailValues.lead_second_contacted) {
      payload.lead_second_contacted = moment(
        leadDetailValues.lead_second_contacted,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");
    }
    if (leadDetailValues.lead_appointment_date) {
      payload.lead_appointment_date = moment(
        leadDetailValues.lead_appointment_date,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");
    }
    if (leadDetailValues.lead_notice) {
      payload.lead_notice = leadDetailValues.lead_notice;
    }
    setLoading(true)
    dispatch(SupplementsActions.updateLeadCenterUpdateRequest({
      payload,
      callback: (res) => {
        if (res?.response) {
          toast.error('Fehler beim Speichern', {
            type: 'error'
          })
        }
        else {
          toast("✅ Erfolgreich gespeichert");
        }
        setLoading(false)
      }
    })
    )
  }


  const leadDetails = trainerLeadDetails?.getTrainerLeadDataByLeadId || {};

  const generalEmailData = {
    leadName: leadDetails?.prename,
    coachPrename: trainerProfileData?.prename,
    foodiaryEmail: trainerProfileTempDetails?.data?.foodiaryEmail,
    leadEmail: leadDetails?.email,
  };

  const button1EmailData = getEmailTriggerData("leads-persoenlicher-anruf", {
    ...generalEmailData,
    subject: "👋 Dein Erstgespräch mit Foodiary",
    coachNumber: trainerProfileData?.Handynummer,
  });

  const button2EmailData = getEmailTriggerData(
    "leads-pers-nlicher-anruf-nicht-erreicht",
    {
      ...generalEmailData,
      subject: "🚨 Wir haben dich nicht erreicht",
      calendlyurl: trainerProfileData?.pp_first_meeting_calendly_url_profilpage,
    }
  );

  const button3EmailData = getEmailTriggerData(
    "leads-terminvereinbarung-erstgespreach",
    {
      ...generalEmailData,
      subject: "👋 Dein Erstgespräch mit Foodiary",
      calendlyurl: trainerProfileData?.pp_first_meeting_calendly_url_profilpage,
    }
  );

  const button4EmailData = getEmailTriggerData(
    "leads-terminvereinbarung-follow-up",
    {
      ...generalEmailData,
      subject: "👋 Rückfrage Erstgespräch",
      calendlyurl: trainerProfileData?.pp_first_meeting_calendly_url_profilpage,
    }
  );

  const goToMainLeadsAll = () => history.push("/dashboard/leads/all");
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: "20px",
      minWidth: "195px",
      marginTop: 10,
      // '&:hover': {
      //   borderColor: '#ddd', // Optional: Change border color on hover
      // }
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      height: "20px", // Set a fixed height
      padding: "0 8px", // Optional: Adjust padding
      fontSize: "14px !important",
      minWidth: "195px",
    }),
    placeholder: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      minWidth: "195px",
      fontSize: "14px",
    }),
  };

  // Create a custom single value component
  const SingleValue = ({ data }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "auto",
        marginTop: -25,
        fontSize: "14px",
      }}
    >
      <div
        style={{
          width: 8,
          height: 8,
          borderRadius: "50%",
          backgroundColor: "#65C9FB",
          marginRight: 8,
        }}
      />
      {data.label}
    </div>
  );
  return (
    <>
      <ToastContainer />
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.TrainerProfileDetailsInnerContainer>
            <S.Header className="p-0">
              <div className="heading-with-back-icon">
                <span onClick={goToMainLeadsAll}>
                  <img src={BackArrowIcon} alt="" />
                </span>
                <div>
                  <S.TrainerProfileDetailsSectionHeading>
                    Details deines Interessenten
                  </S.TrainerProfileDetailsSectionHeading>
                  <S.TrainerProfileDetailsSectionSubHeading>
                    Wichtige Infos über deinen Interessenten
                  </S.TrainerProfileDetailsSectionSubHeading>
                </div>
              </div>
              <button
                disabled={loading}
                onClick={handleSave}
                className="btn-dark"
              >
                {loading ? <div className="loader-small" /> : "Speichern"}
              </button>
              {/* <span>
                <span className="lead-status">
                  {leadDetails?.lead_status ? <TranslationMessage id={`leads_status.${leadDetails?.lead_status}`} /> : 'loading...'}
                </span>
              </span> */}
            </S.Header>

            <S.Divider />
            <br />
            {isTrainerLeadDetailsLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            {!isTrainerLeadDetailsLoading && (
              <div className="lead-details">
                <div className="d-flex justify-content-between align-items-center ">
                  <h3 className="name text-size-28-38 text-dark-1 font-rubik-medium mt-3">
                    {trainerLeadDetails?.getTrainerLeadDataByLeadId?.prename}{" "}
                    {trainerLeadDetails?.getTrainerLeadDataByLeadId?.surename}
                  </h3>
                  <Select
                    options={DetailsDropDown}
                    styles={customStyles}
                    components={{ SingleValue }}
                    placeholder="Status ändern"
                    value={dropDownValue}
                    onChange={(value) => setDropDownValue(value)}
                  />
                </div>
                <div className="general-info-box">
                  <h6 className="title text-size-20-24 text-dark-1 font-rubik-medium my-0">
                    Allgemeines
                  </h6>
                  {/* d-flex align-items-center justify-content-between */}
                  <div className="relative">
                    <div className="vertical-line" />
                  </div>
                  <div className="lead-first-row">
                    <div className="sub-grid">
                      <div className="d-flex">
                        <p className="received-date-title text-size-16-19 font-rubik-regular text-dark-1 mb-0">
                          Lead erhalten am:
                        </p>
                        <p className="received-date text-size-16-19 font-rubik-regular text-light-grey-1 mb-0 ml-3">
                          {trainerLeadDetails?.getTrainerLeadDataByLeadId
                            ?.lead_received && (
                            <>
                              {moment(
                                new Date(
                                  trainerLeadDetails?.getTrainerLeadDataByLeadId?.lead_received
                                )
                              ).format("DD.MM.YYYY")}
                            </>
                          )}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="received-date-title text-size-16-19 font-rubik-regular text-dark-1 mb-0">
                          Leadquelle:
                        </p>
                        <p className="received-date text-size-16-19 font-rubik-regular text-light-grey-1 mb-0 ml-3">
                          {
                            trainerLeadDetails?.getTrainerLeadDataByLeadId
                              ?.lead_source
                          }
                        </p>
                      </div>
                    </div>
                    <div />
                  </div>

                  {/* Second row with 1.Kontakt, 2.Kontakt, and Erstgespräch */}
                  <div className="lead-second-row">
                    <div className="d-flex align-items-center">
                      <p className="received-date-title text-size-16-19 font-rubik-regular text-dark-1 mb-0">
                        1.Kontakt:
                      </p>
                      <DatePicker
                        value={leadDetailValues.lead_first_contacted}
                        onDateChange={(date) => {
                          setLeadDetailValues((prev) => ({
                            ...prev,
                            lead_first_contacted: date,
                          }));
                        }}
                        placeholder={"dd/mm/yyyy"}
                        name="leadContactDate"
                        format={"DD/MM/YYYY"}
                        width={"90%"}
                        margin={"10px 0px 0px 15px"}
                      />
                    </div>
                    <div className="d-flex align-items-center ml-3 mt-2">
                      <p className="received-date-title text-size-16-19 font-rubik-regular text-dark-1 mb-0">
                        2.Kontakt:
                      </p>
                      <DatePicker
                        value={leadDetailValues.lead_second_contacted}
                        onDateChange={(date) => {
                          setLeadDetailValues((prev) => ({
                            ...prev,
                            lead_second_contacted: date,
                          }));
                        }}
                        placeholder={"dd/mm/yyyy"}
                        name="leadRecontactedDate"
                        format={"DD/MM/YYYY"}
                        width={"90%"}
                        margin={"10px 0px 0px 15px"}
                      />
                    </div>
                    <div className="d-flex align-items-center ml-3">
                      <p className="received-date-title text-size-16-19 font-rubik-regular text-dark-1 mb-0">
                        Erstgespräch:
                      </p>
                      <DatePicker
                        value={leadDetailValues.lead_appointment_date}
                        onDateChange={(date) => {
                          setLeadDetailValues((prev) => ({
                            ...prev,
                            lead_appointment_date: date,
                          }));
                        }}
                        placeholder={"dd/mm/yyyy"}
                        name="intialMeetingDate"
                        format={"DD/MM/YYYY"}
                        width={"90%"}
                        margin={"10px 0px 0px 15px"}
                      />
                    </div>
                  </div>
                </div>

                <div className="personal-data mt-3">
                  <h6 className="title text-size-20-24 text-dark-1 font-rubik-medium my-0">
                    Persönliche Daten
                  </h6>
                  {/* d-flex align-items-center justify-content-between */}
                  <div className="personal-data-info">
                    <div>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1">
                        <span className="text-dark-1">Vorname:</span>{" "}
                        {
                          trainerLeadDetails?.getTrainerLeadDataByLeadId
                            ?.prename
                        }
                      </p>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0">
                        <span className="text-dark-1">Telefonnummer:</span>{" "}
                        {
                          trainerLeadDetails?.getTrainerLeadDataByLeadId
                            ?.mobilenumber
                        }
                      </p>
                    </div>

                    <div>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1">
                        <span className="text-dark-1">Nachname:</span>{" "}
                        {
                          trainerLeadDetails?.getTrainerLeadDataByLeadId
                            ?.surename
                        }
                      </p>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0">
                        <span className="text-dark-1"> E-Mail:</span>{" "}
                        {trainerLeadDetails?.getTrainerLeadDataByLeadId?.email}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="lead-details mt-3">
                  <h6 className="title text-size-20-24 text-dark-1 font-rubik-medium my-0">
                    Erhaltene Informationen
                  </h6>
                  <div className="lead-details-info">
                    <div className="mb-3">
                      <p className="text-size-16-19 font-rubik-regular text-dark-1">
                        Welches Ziel verfolgst du?
                      </p>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1">
                        {
                          trainerLeadDetails?.getTrainerLeadDataByLeadId
                            ?.lead_goal
                        }
                      </p>
                    </div>

                    <div className="mb-3">
                      <p className="text-size-16-19 font-rubik-regular text-dark-1">
                        Wie identifizierst du dich?
                      </p>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1">
                        {
                          trainerLeadDetails?.getTrainerLeadDataByLeadId
                            ?.lead_gender
                        }
                      </p>
                    </div>

                    <div className="mb-3">
                      <p className="text-size-16-19 font-rubik-regular text-dark-1">
                        Wie alt bist du?
                      </p>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0">
                        {
                          trainerLeadDetails?.getTrainerLeadDataByLeadId
                            ?.lead_age
                        }
                      </p>
                    </div>

                    <div className="mb-3">
                      <p className="text-size-16-19 font-rubik-regular text-dark-1">
                        Was möchtest du erreichen?
                      </p>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0">
                        {
                          trainerLeadDetails?.getTrainerLeadDataByLeadId
                            ?.lead_informations
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="title text-size-20-24 text-dark-1 font-rubik-medium my-0">
                    Notizen
                  </h6>
                  <TextArea
                    rows={6}
                    value={leadDetailValues.lead_notice}
                    name="leadInformations"
                    placeholder="Schreibe deine Notizen …"
                    onChange={(e) =>
                      setLeadDetailValues({
                        ...leadDetailValues,
                        lead_notice: e.target.value,
                      })
                    }
                    style={{
                      margin: "12px 0 5px",
                      border: "1px solid #E2E4E9",
                      height: "20vh",
                      borderRadius: "10px",
                    }}
                    disabled={false}
                  />
                </div>
                <div className="lead-actions mt-3">
                  <h6 className="title text-size-20-24 text-dark-1 font-rubik-medium my-0">
                    Aktionen
                  </h6>
                  <div className="lead-actions-list">
                    <div className="lead-actions-list-item">
                      <div>
                        <h5 className="text-size-16-19 font-rubik-regular mb-0">
                          E-Mail: Information über persönlichen Anruf
                        </h5>
                        <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0 mt-2">
                          Versende eine standardisierte Nachricht und kündige
                          deinen Anruf für die nächsten 24 Stunden an.
                        </p>
                      </div>
                      <TriggerEmailButton emailData={button1EmailData} />
                    </div>

                    <div className="lead-actions-list-item">
                      <div>
                        <h5 className="text-size-16-19 font-rubik-regular mb-0">
                          E-Mail: Interessenten nicht erreicht
                        </h5>
                        <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0 mt-2">
                          Sende eine E-Mail mit deinem Kalender, wenn du den
                          Interessenten beim Anruf nicht erreicht hast.
                        </p>
                      </div>
                      <TriggerEmailButton emailData={button2EmailData} />
                    </div>

                    <div className="lead-actions-list-item">
                      <div>
                        <h5 className="text-size-16-19 font-rubik-regular mb-0">
                          E-Mail: Terminvereinbarung Erstgespräch
                        </h5>
                        <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0 mt-2">
                          Versende eine standardisierte Nachricht mit deinem
                          Calendly Link für die Buchung eines Erstgespräch per
                          E-Mail.
                        </p>
                      </div>
                      <TriggerEmailButton emailData={button3EmailData} />
                    </div>

                    <div className="lead-actions-list-item">
                      <div>
                        <h5 className="text-size-16-19 font-rubik-regular mb-0">
                          E-Mail: Follow Up - Erstgespräch
                        </h5>
                        <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0 mt-2">
                          Versende eine standardisierte Follow Up Nachricht mit
                          deinem Calendly Link, solltest du keine Rückmeldung
                          erhalten haben.
                        </p>
                      </div>
                      <TriggerEmailButton emailData={button4EmailData} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <br />
          </S.TrainerProfileDetailsInnerContainer>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default MainLeadDetails;
