import styled from "styled-components";
import DownArrow from "assets/images/down-arrow.svg";

export const Container = styled.div`
  /* display: grid;
  grid-template-columns: 300px 1fr; */
  display: flex;
`;
export const Wrapper = styled.div`
  padding: 1rem;
`;
export const WhitePaperWrap = styled.div``;

export const MCard = styled.div`
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 10px;
  padding: 1.875rem 1rem;
  background: #fff;
`;

export const Header = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;

  .right-sec {
    display: flex;
    gap: .5rem;
    align-items: center;
  }

  .select-width {
    min-width: 200px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;

export const DropDown = styled.div`
  display: flex;
  align-items: center;

  span {
    padding: 0px 0.937rem;
    color: #a5b5af;
  }
  select {
    height: 39px;
    background: #f5f5f5;
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #44554c;
    padding: 0 2.5rem 0 1rem;
    background-image: url("${DownArrow}");
    background-repeat: no-repeat;
    background-position: center right 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
  }
`;

export const WhitePaperTitle = styled.div`
  color: #44554c;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  margin: 2.5rem 0 1.5rem;
`;

export const WhitePaperInner = styled.div``;

export const Expert = styled.div`
  display: flex;
  gap: 2.5rem;
  border-radius: 0.625rem;
  cursor: pointer;
  padding: 0.5rem;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const ExpertImage = styled.div`
  width: 17rem;
  height: 12.625rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.625rem;
  }
`;

export const ExpertContent = styled.div`
  max-width: calc(100% - 19.5rem);

  .label {
    width: max-content;
    border-radius: 0.3125rem;
    padding: 0.3125rem 0.625rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    font-weight: 500;

    &.danger {
      color: #ee2b2b;
      background: #ee2b2b1a;
    }

    &.success {
      color: #2ecf96;
      background: #2ecf961a;
    }

    &.warning {
      color: #ffba31;
      background: #ffba311a;
    }
  }

  h4 {
    color: #142c1f;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0.9375rem 0 1.25rem 0;
    font-weight: 500;
  }

  p {
    color: #44554c;
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
  }

  .date-time {
    display: flex;
    align-items: center;
    gap: 1.875rem;
    margin-top: 1.5rem;

    span {
      color: #a5b5af;
      font-size: 1rem;
      line-height: 1.1875rem;
      font-weight: 300;
    }
  }
`;

export const WhitePaperCardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0.5rem;
  margin-block: 1rem;

  h3 {
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: 500;
    color: #142c1f;
    margin: 0;
  }
  p {
    color: #44554c;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
    margin: 1rem 0;
  }

  a {
    background: #f5f5f5;
    border-radius: 0.3125rem;
    padding: 0.5rem;
    color: #44554c;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1.0625rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    gap: 0.5rem;
    width: fit-content;
    text-align: left;
    text-decoration: none;

    &:hover {
      background: #eeeeee;
    }
  }
`;

export const ClientDetailsContentHeading = styled.h6`
  font-size: 1.25rem;
  color: #142c1f;
  margin-bottom: 0.3125rem;
  margin-top: 0;
`;

export const ClientDetailsContentSubHeading = styled.div`
  font-size: 0.9375rem;
  color: #808D87;
  margin-top: 1.25rem;
  font-weight: 400;
`;
