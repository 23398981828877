import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
`;

export const Wrapper = styled.div`
  padding: 1.5rem;

  .link {
    text-decoration: none;
  }
`;

export const MCard = styled.div`
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 10px;
  padding: 1.875rem 1.56rem;
  background-color: white;
`;

export const ClientDetailsContentHeading = styled.div`
  font-size: 1.25rem;
  color: #142c1f;
  margin-bottom: 0.3125rem;
`;

export const ClientDetailsContentSubHeading = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  color: #818d88;
  margin-bottom: 0.3125rem;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
`;

export const PartnershipCard = styled.div`
  border: 1px solid #E3E4E9;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;

  .content {
    height: 180px;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h6, p {
      margin: 0;
    }

    h6 {
      font-size: 1.125rem;
      line-height: 1.375rem;
      color: #44554c;
    }

    p {
      margin-top: 1rem;
      font-size: 0.9rem;
      color: #818d88;
      line-height: 1.4rem;
      font-weight: 300;
    }
  }
`;

export const Image = styled.div`
  height: 300px;
  background: url('${({ image }) => image}');
  background-size: cover;
  background-position: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;
