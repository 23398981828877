import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { object, string } from "yup";
import emailjs from "@emailjs/browser";

import Input from "components/formComponents/input/Input";
import TextArea from "components/formComponents/textArea/TextArea";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import Button from "components/formComponents/button/Button";
import { MENUS } from "./data";
import * as S from "./support.styles";

const Support = () => {
  const [IsEmailSending, setIsEmailSending] = useState(false);
  const [IsSuccess, setIsSuccess] = useState(false);
  const [IsError, setIsError] = useState(false);
  const trainerData = useSelector((state) => state.auth.trainerData);

  const initialValues = {
    prename: trainerData?.prename,
    surname: trainerData?.surname,
    FranchiseID: trainerData?.FranchiseID,
    reference: "",
    news: "",
  };

  const validationSchema = object().shape({
    FranchiseID: string()
      .typeError("You must enter a String")
      .required("Required"),
    surname: string().typeError("You must enter a String").required("Required"),
    prename: string().typeError("You must enter a String").required("Required"),
    reference: string()
      .typeError("You must enter a String")
      .required("Required"),
    news: string().typeError("You must enter a String").required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    setIsEmailSending(true);
    let templateParams = {
      subject: "Support",
      reference: values.reference,
      news: values.news,
      franchiseId: values.FranchiseID,
      prename: values.prename,
      surname: values.surname,
      fromName: `${values.prename} ${values.surname}`,
      email: trainerData?.email,
      senderEmail: trainerData?.email,
      recipientEmail: "Backoffice@foodiary.app",
    };

    emailjs
      .send(
        "service_srrcjm9",
        "template_d49rcah",
        templateParams,
        "uowiKoxYT_i1TYcoP"
      )
      .then(
        function (response) {
          setIsEmailSending(false);
          setIsSuccess(true);
          resetForm();
          const timer = setTimeout(() => {
            setIsSuccess(false);
            clearTimeout(timer);
          }, 3000);
        },
        function (error) {
          setIsEmailSending(false);

          setIsError(true);

          const timer = setTimeout(() => {
            setIsError(false);
            clearTimeout(timer);
          }, 3000);
        }
      );
  };

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <S.TrainerProfileDetailsInnerContainer>
                  <S.TrainerProfileDetailsSectionHeading>
                    <span>Ticket eröffnen</span>
                    <button type="submit" disabled={IsEmailSending} className="btn-dark">
                      Ticket absenden
                    </button>
                  </S.TrainerProfileDetailsSectionHeading>
                  <S.TrainerProfileDetailsSectionSubHeading>
                    Sende dein anliegen direkt an unser Support Team
                  </S.TrainerProfileDetailsSectionSubHeading>
                  {IsSuccess && (
                    <S.SuccessAlert>
                      Email has been sent successfully!
                    </S.SuccessAlert>
                  )}
                  {IsError && <S.ErrorAlert>Error! Try again.</S.ErrorAlert>}

                  {/* <br /> */}
                  <S.Divider />
                  <br />
                  <br />

                  <S.InlineThreeControls>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        FRANCHISE-ID
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        name="FranchiseID"
                        onChange={handleChange}
                        value={values.FranchiseID}
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        VORNAME
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        name="prename"
                        onChange={handleChange}
                        value={values.prename}
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        NACHNAME
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        name="surname"
                        onChange={handleChange}
                        value={values.surname}
                      />
                    </S.FormControl>
                  </S.InlineThreeControls>

                  <S.FormControl>
                    <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                      BETREFF
                    </S.TrainerProfileDetailsSection1InputLabel>
                    <Input
                      className="input-custom copy-text"
                      style={{ width: "100%", marginBottom: "10px" }}
                      name="reference"
                      onChange={handleChange}
                      value={values.reference}
                    />
                  </S.FormControl>
                  <div className="textarea-box">
                    <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                      NACHRICHT
                    </S.TrainerProfileDetailsSection1InputLabel>
                    <TextArea
                      rows={10}
                      name="news"
                      onChange={handleChange}
                      value={values.news}
                    />
                  </div>
                </S.TrainerProfileDetailsInnerContainer>
              </form>
            )}
          </Formik>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default Support;
