import React, { useState } from "react";
import * as S from "./tokenRequest.styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import Modal from "components/modal/Modal";
import CrossIcon from "assets/images/cross.svg";
import ErrorMessageIcon from "assets/images/error-message.png";
import BackIconButton from "components/backIconButton/BackIconButton";
import TranslationMessage from "configs/translations/TranslationMessage";
import Card from "components/card/Card";
import Input from "components/formComponents/input/Input";
import Button from "components/formComponents/button/Button";
import Loader from "components/loader/Loader";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import * as DashboardActions from "redux/dashboard/dashboard.actions";

// Images
import TokenIcon from "assets/images/token-icon.svg";

// Redux
import * as AuthActions from "redux/auth/auth.actions";
import { MENUS } from "./data";

function SideNav() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [
    IsStripeConnectedAccountStatusModal,
    SetIsStripeConnectedAccountStatusModal,
  ] = useState(false);

  const isCheckStripeConnectedAccountStatusLoading = useSelector(
    (state) => state.dashboard.isCheckStripeConnectedAccountStatusLoading
  );

  const trainerData = useSelector((state) => state.auth.trainerData);

  const checkStripeConnection = () => {
    dispatch(
      DashboardActions.checkStripeConnectedAccountStatusRequest({
        payload: {
          trainerID: trainerData?.trainerID,
        },
        callback: (res) => {
          console.log("checkStripeConnectedAccountStatusRequest", res);
          if (
            res?.connected_account_status === "completed" &&
            res?.connected_user_account_status === "completed"
          ) {
            history.push("/onboarding/legal-informations");
          } else {
            SetIsStripeConnectedAccountStatusModal(true);
          }
        },
      })
    );
  };

  return (
    <>
      <Modal
        width="450px"
        height="auto"
        show={IsStripeConnectedAccountStatusModal}
        setShow={() => null}
        heading={
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="my-0 text-left"> </h4>

            <img
              className="cursor-pointer"
              src={CrossIcon}
              alt="CrossIcon"
              onClick={() => SetIsStripeConnectedAccountStatusModal(false)}
            />
          </div>
        }
      >
        <div className="congratulations-modal-body-inner">
          <img src={ErrorMessageIcon} alt="" />

          <h6>Achtung!</h6>
          <p>
            Dein Zahlungsanbieter wurde noch nicht aktiviert. <br />
            Bitte aktiviere deinen Anbieter direkt.
          </p>
          <button
            className="btn-dark"
            onClick={() => history.push("/dashboard/settings/payment-provider")}
          >
            Zahlungsanbieter aktivieren
          </button>
        </div>
      </Modal>
      <div className="second-sidebar-wrapper">
        <InnerSideNav
          menus={MENUS}
          onCheckStripeConnection={checkStripeConnection}
          isCheckStripeConnectedAccountStatusLoading={
            isCheckStripeConnectedAccountStatusLoading
          }
          hideUserInfo
        ></InnerSideNav>
      </div>
    </>
  );
}

function TokenRequest() {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const loading = useSelector((state) => state.auth.loading);
  const trainerData = useSelector((state) => state.auth.trainerData);
  const hasExistingPlan = false;

  const initialValues = {
    name: "",
    email: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const formData = {
      name: values.name,
      email: values.email,
    };

    const formDataLoginWithToken = {
      formData: {
        grant_type: "password",
        client_id: 2,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        trainerID: trainerData.trainerID,
        FranchiseID: trainerData.FranchiseID,
      },
      func: () => history.push("/onboarding/nutrition/type-update"),
    };
    dispatch(
      AuthActions.tokenLoginRequest({ formData, formDataLoginWithToken })
    );

    resetForm();
  };

  return (
    <>
      <SideNav />
      <S.TokenRequestWrapper className="second-sidebar-content">
        <S.TokenRequestInnerWrapper>
          <S.TokenRequestHeading>Token abfragen</S.TokenRequestHeading>
          <S.TokenRequestSubHeading>
            Um einen bestehenden Ernährungsplan zu öffnen verwende den Vornamen
            und die E-Mail Adresse deines Klienten.
          </S.TokenRequestSubHeading>

          <br />
          <S.Divider />
          <br />
          <br />

          <S.FormContainer>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <S.FormInputs>
                    <div className="form-group">
                      <label htmlFor="name">
                        {intl.formatMessage({ id: "placeholder.name" })}
                      </label>
                      <Input
                        filled
                        type="text"
                        placeholder={intl.formatMessage({
                          id: "placeholder.name",
                        })}
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                      />
                      <ErrorMessage error={errors.name} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">
                        {intl.formatMessage({ id: "placeholder.email" })}
                      </label>
                      <Input
                        filled
                        type="text"
                        placeholder={intl.formatMessage({
                          id: "placeholder.email",
                        })}
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                      />
                      <ErrorMessage error={errors.email} />
                    </div>
                  </S.FormInputs>

                  <Input
                    className="hidden"
                    type="checkbox"
                    name="isExisting"
                    checked={hasExistingPlan}
                  />
                  <Loader loading={loading} top="-50px" />

                  <S.SubmitButton>
                    <button type="submit" className="btn-dark">
                      <TranslationMessage id="button.openNutritionPlan" />
                    </button>
                  </S.SubmitButton>
                </form>
              )}
            </Formik>
          </S.FormContainer>
        </S.TokenRequestInnerWrapper>
      </S.TokenRequestWrapper>
    </>
  );
}

export default TokenRequest;
