import React, { useState, useEffect, useRef } from "react";
import * as S from "./trainerProfileDetails.styles";
// import { useParams, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { object, string } from "yup";
import { useIntl } from "react-intl";
import StarRatings from "react-star-ratings";
import { useAlert } from "react-alert";
import { ToastContainer, toast } from "react-toastify";

// Components
import Input from "components/formComponents/input/Input";
import TextArea from "components/formComponents/textArea/TextArea";
// Images
import UploadIcon from "assets/images/upload.svg";

// Redux
import * as AuthActions from "redux/auth/auth.actions";

const TrainerProfileFeedback = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const alert = useAlert();
  const profileFormRef = useRef(null);

  const coachProfileIntroVideoRef = useRef(null);
  const websiteIdeaPicRef = useRef(null);
  const websiteWhyImYourCoachPicRef = useRef(null);
  const websiteYourWayPicRef = useRef(null);
  const websitePersonalityPicRef = useRef(null);
  const websiteCoachingPerBookingPicRef = useRef(null);
  const feedbackPicRef = useRef(null);

  const loading = useSelector((state) => state.auth.loading);
  const feedbackRes = useSelector((state) => state.auth.feedback);
  // const saveCreatedSiteInDBRes = useSelector((state) => state.auth.createWebsite)
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const trainerProfileDataRes = useSelector(
    (state) => state.auth.trainerDataRes
  );
  // const registrationData = useSelector((state) => state.auth.registrationData)
  console.log("trainerProfileDataRes", trainerProfileDataRes);
  const [section1Edit, setSection1Edit] = useState(true);
  const [section2Edit, setSection2Edit] = useState(true);
  const [profileImage, setProfileImage] = useState(null);

  const [coachProfileIntroVideo, setCoachProfileIntroVideoImage] =
    useState(null);
  const [websiteIdeaImage, setWebsiteIdeaImage] = useState(null);
  const [websiteWhyImYourCoachImage, setWebsiteWhyImYourCoachImage] =
    useState(null);
  const [websiteYourWayImage, setWebsiteYourWayImage] = useState(null);
  const [websitePersonality, setWebsitePersonality] = useState(null);
  const [websiteCoachingPerBookingImage, setWebsiteCoachingPerBookingImage] =
    useState(null);
  const [feedbackImage, setFeedbackImage] = useState(null);
  const [mediaValidations, setMediaValidations] = useState({
    introVideo: false,
    idea: false,
    whyImYourCoach: false,
    yourWay: false,
    personality: false,
    coachingPerBooking: false,
  });
  const [mediaTypeValidations, setMediaTypeValidations] = useState({
    introVideo: false,
    idea: false,
    whyImYourCoach: false,
    yourWay: false,
    personality: false,
    coachingPerBooking: false,
  });

  const [selectedSubtitle, setSelectedSubtitle] = useState(
    trainerProfileData?.subtitle
  );
  const [rating, setRating] = useState(0);
  const [CustomerService, setCustomerService] = useState(false);
  const [isFeedbackSending, setIsFeedbackSending] = useState(false);
  const [showFeedbackMsg, setShowFeedbackMsg] = useState(false);

  const [saveWebsiteMsg, setSaveWebsiteMsg] = useState(false);

  const [isWebsiteCreating, setIsWebsiteCreating] = useState(false);
  const [showCreateWebsiteMsg, setShowCreateWebsiteMsg] = useState(false);
  const [createWebsiteRes, setCreateWebsiteRes] = useState(null);

  const [passwordResetModal, setPasswordResetModal] = useState(false);

  useEffect(() => {
    getTrainerProfileDetails();
  }, []);

  const getTrainerProfileDetails = () => {
    dispatch(
      AuthActions.getTrainerDetailsRequest({
        FranchiseID: trainerProfileData?.FranchiseID,
        trainerID: trainerProfileData.id,
      })
    );
  };

  const validationSchemaFeedback = object().shape({
    fbFirstName: string(),
    fbLastName: string(),
    fbReviewText: string(),
  });

  const initialValuesFeedback = {
    fbFirstName: trainerProfileData.fbFirstName || "",
    fbLastName: trainerProfileData.fbLastName || "",
    fbReviewText: trainerProfileData.fbReviewText || "",
  };

  const onFeedbackFormSubmit = (values, { resetForm }) => {
    setIsFeedbackSending(true);
    const formData = new FormData();
    formData.append("trainerID", trainerProfileData.trainerID);
    formData.append("prename", values.fbFirstName);
    formData.append("prename", values.fbFirstName);
    formData.append("lastname", values.fbLastName);
    formData.append("text", values.fbReviewText);
    formData.append("rating", rating);

    if (feedbackImage) {
      const extension = feedbackImage.type.split("/")[1];
      formData.append("extension", extension);
      formData.append("picture", feedbackImage);
    }

    if (!feedbackImage) {
      setIsFeedbackSending(false);
      return;
    }

    dispatch(
      AuthActions.trainerFeedbackRequest({
        formData,
        funcSuccess: () => {
          getTrainerProfileDetails();
          setRating(0);
          setFeedbackImage(null);
          setIsFeedbackSending(false);

          if (feedbackRes?.success) {
            toast("✅ Dein Feedback wurde hochgeladen");
          }

          if (!feedbackRes?.success) {
            toast("❌ Dein Feedback konnte nicht hochgeladen werden");
          }

          // setShowFeedbackMsg(true);
          // const timer = setTimeout(() => {
          //   setShowFeedbackMsg(false);
          //   clearTimeout(timer);
          // }, 5000);
        },
      })
    );
    resetForm();
  };

  const handleImageChange = (key) => {
    switch (key) {
      case "Video":
        coachProfileIntroVideoRef.current.click();
        break;
      case "IdeaPic":
        websiteIdeaPicRef.current.click();
        break;
      case "WhyImYourCoachPic":
        websiteWhyImYourCoachPicRef.current.click();
        break;
      case "YourWayPic":
        websiteYourWayPicRef.current.click();
        break;
      case "PersonalityPic":
        websitePersonalityPicRef.current.click();
        break;
      case "CoachingPerBookingPic":
        websiteCoachingPerBookingPicRef.current.click();
        break;
      case "FeedbackPic":
        feedbackPicRef.current.click();
        break;
      default:
        break;
    }
  };

  const onFeedback = () => {
    if (profileFormRef.current) {
      profileFormRef.current.handleSubmit();
    }
  };

  return (
    <S.TrainerProfileDetailsOverallContainer>
      <S.TrainerProfileDetailsContainer style={{ gridTemplateColumns: "1fr" }}>
        <div>
          <S.TrainerProfileDetailsRightSection2Container>
            <S.TrainerProfileDetailsInnerContainer className="p-0">
              <S.Header>
                <div>
                  <S.TrainerProfileDetailsSectionHeading>
                    Klientenfeedback
                  </S.TrainerProfileDetailsSectionHeading>
                  <S.TrainerProfileDetailsSectionSubHeading>
                    Lade das Feedback deiner Kunden hoch, um dieses zu
                    veröffentlichen.
                  </S.TrainerProfileDetailsSectionSubHeading>
                </div>
                <div>
                  <button
                    className="btn-dark"
                    onClick={onFeedback}
                    disabled={loading || isFeedbackSending}
                  >
                    Bewertung hochladen
                  </button>
                </div>
              </S.Header>
              <S.Divider />

              <Formik
                initialValues={initialValuesFeedback}
                validationSchema={validationSchemaFeedback}
                onSubmit={onFeedbackFormSubmit}
                innerRef={profileFormRef}
                enableReinitialize
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  isValid,
                  dirty,
                }) => (
                  <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <S.PaddingBox>
                      {/* {showFeedbackMsg && feedbackRes?.success && (
                        <S.TrainerProfileDetailsSectionSubHeading
                          style={{
                            color: "#1C9D87",
                            fontWeight: 400,
                            padding: 0,
                            marginTop: 16,
                          }}
                        >
                          Dein Feedback wurde hochgeladen.
                        </S.TrainerProfileDetailsSectionSubHeading>
                      )}
                      {showFeedbackMsg && !feedbackRes?.success && (
                        <S.TrainerProfileDetailsSectionSubHeading
                          style={{
                            color: "red",
                            fontWeight: 400,
                            padding: 0,
                            marginTop: 16,
                          }}
                        >
                          Dein Feedback konnte nicht hochgeladen werden.
                        </S.TrainerProfileDetailsSectionSubHeading>
                      )} */}
                      <ToastContainer />

                      <S.UploadHeader>
                        <S.MediaUploadItemHorizontal>
                          <S.MediaUploadLabel>
                            {intl.formatMessage({
                              id: "trainerProfile.profilePic",
                            })}
                          </S.MediaUploadLabel>
                          <S.MediaUploadHorizontal
                            onClick={() => handleImageChange("FeedbackPic")}
                          >
                            <div className="upload-icon">
                              <img src={UploadIcon} alt="" />
                            </div>
                            <div className="upload-box-content">
                              {feedbackImage && (
                                <p>
                                  <strong>{feedbackImage?.name}</strong>
                                </p>
                              )}
                              {!feedbackImage && (
                                <p>
                                  <strong>Klicke</strong> auf das Icon zum
                                  hochladen.
                                </p>
                              )}

                              <span>PNG, JPG bis 2.8 MB erlaubt</span>
                            </div>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              ref={feedbackPicRef}
                              onChange={(e) =>
                                setFeedbackImage(e.target.files[0])
                              }
                            />
                          </S.MediaUploadHorizontal>
                        </S.MediaUploadItemHorizontal>
                        <div>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            {intl.formatMessage({
                              id: "trainerProfile.evaluation",
                            })}
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <StarRatings
                            rating={rating}
                            starRatedColor="#FFC400"
                            changeRating={(newRating, name) => {
                              setRating(newRating);
                            }}
                            numberOfStars={5}
                            starDimension={"26px"}
                            name="rating"
                          />
                        </div>
                      </S.UploadHeader>
                    </S.PaddingBox>
                    <S.Divider />
                    <S.PaddingBox>
                      <div>
                        <S.SectionHeading>
                          Details des Feedbacks
                        </S.SectionHeading>
                        <br />
                      </div>
                      <S.FormGrid>
                        <div>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            {intl.formatMessage({
                              id: "trainerProfile.firstName",
                            })}
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            style={{ width: "280px", marginBottom: "10px" }}
                            placeholder={intl.formatMessage({
                              id: "trainerProfile.firstName",
                            })}
                            name="fbFirstName"
                            onChange={handleChange}
                            value={values.fbFirstName}
                          />
                        </div>
                        <div>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            {intl.formatMessage({
                              id: "trainerProfile.lastName",
                            })}
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            style={{ width: "280px", marginBottom: "10px" }}
                            placeholder={intl.formatMessage({
                              id: "trainerProfile.lastName",
                            })}
                            name="fbLastName"
                            onChange={handleChange}
                            value={values.fbLastName}
                          />
                        </div>
                      </S.FormGrid>
                      <div className="textarea-box">
                        <S.TrainerProfileDetailsSection1InputLabel>
                          {intl.formatMessage({
                            id: "trainerProfile.reviewText",
                          })}
                        </S.TrainerProfileDetailsSection1InputLabel>
                        <TextArea
                          rows={6}
                          placeholder={intl.formatMessage({
                            id: "trainerProfile.reviewText",
                          })}
                          name="fbReviewText"
                          onChange={handleChange}
                          value={values.fbReviewText}
                          style={{
                            margin: "0",
                          }}
                        />
                      </div>
                    </S.PaddingBox>
                    {/* <S.OrangeButton
                      type="submit"
                      disabled={isFeedbackSending}
                      className="disabled"
                    >
                      {intl.formatMessage({
                        id: "trainerProfile.updateRatingButtonText",
                      })}
                    </S.OrangeButton>
                    <Loader loading={isFeedbackSending} top="-200px" /> */}
                  </form>
                )}
              </Formik>
            </S.TrainerProfileDetailsInnerContainer>
          </S.TrainerProfileDetailsRightSection2Container>
        </div>
      </S.TrainerProfileDetailsContainer>
    </S.TrainerProfileDetailsOverallContainer>
  );
};

export default TrainerProfileFeedback;
