import axios from 'axios';

const endpoint = 'https://api.tapfiliate.com/1.6/customers/';

async function TapFiliate(customerData) {
    const payload = {
        customer_id: customerData.customer_id,
        referral_code: customerData.referral_code,
        meta_data: {
            vorname: customerData.meta_data.vorname,
            nachname: customerData.meta_data.nachname,
            franchiseID: customerData.meta_data.franchiseID,
            email: customerData.meta_data.email,
            user_agent: navigator.userAgent,
            ip: customerData.meta_data.ip
        }
    }
    try {
        console.log(payload);

        const res = await axios.post(endpoint, payload, {
            headers: {
                'Api-Key': '3acc178a1eff97b14cc1b12c00222e070a3a1b91',
                'Content-Type': 'application/json',
            },
        });
        return res.data;
    } catch (error) {
        console.error('Error creating customer:', error.response);
        throw error;
    }
}

export default TapFiliate;
