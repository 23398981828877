import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationMessage from "configs/translations/TranslationMessage";
import { ToastContainer, toast } from "react-toastify";

import CopyIcon from "assets/images/copy-icon-black.svg";
// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as S from "../trainerProfileDetails.styles";

const TrainerProfileWebsiteOverview = () => {
  const dispatch = useDispatch();

  const trainerProfileData = useSelector((state) => state.auth.trainerData);

  useEffect(() => {
    getTrainerProfileDetails();
  }, []);

  const getTrainerProfileDetails = () => {
    dispatch(
      AuthActions.getTrainerDetailsRequest({
        FranchiseID: trainerProfileData?.FranchiseID,
        trainerID: trainerProfileData?.id,
      })
    );
  };

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
    toast("📃 Erfolgreich kopiert.");
  };

  return (
    <S.TrainerProfileDetailsOverallContainer className="trainer-profile-website-overview">
      <S.TrainerProfileDetailsInnerContainer className="p-0">
        <S.Header className="">
          <div>
            <S.TrainerProfileDetailsSectionHeading>
              Deine Webseite
            </S.TrainerProfileDetailsSectionHeading>
            <S.TrainerProfileDetailsSectionSubHeading>
              Hier findest du wichtige URLs deiner Webseite und deines
              Kalenders.
            </S.TrainerProfileDetailsSectionSubHeading>
          </div>
          <div></div>
        </S.Header>
        <ToastContainer />
        <S.Divider />
        <S.DataRow>
          <div>
            <h6>Status</h6>
          </div>
          <div>
            <div className="online-status">
              <div className="dot"></div>
              Online
            </div>
          </div>
        </S.DataRow>
        <S.Divider />
        <S.DataRow>
          <div>
            <h6>Webseite URL</h6>
            <p>{trainerProfileData?.coach_website_url || ""}</p>
          </div>
          <div>
            <button
              className="btn-copy"
              onClick={() =>
                copyToClipBoard(trainerProfileData?.coach_website_url)
              }
            >
              <img src={CopyIcon} alt="CopyIcon" /> Copy
            </button>
          </div>
        </S.DataRow>
        <S.Divider />
        <S.DataRow>
          <div>
            <h6>Webseite - Erstgespräch URL</h6>
            <p>{trainerProfileData?.first_meeting_url || ""}</p>
          </div>
          <div>
            <button
              className="btn-copy"
              onClick={() =>
                copyToClipBoard(trainerProfileData?.first_meeting_url)
              }
            >
              <img src={CopyIcon} alt="CopyIcon" /> Copy
            </button>
          </div>
        </S.DataRow>
        <S.Divider />
        <S.DataRow>
          <div>
            <h6>Deine Partner URL</h6>
            <p>{trainerProfileData?.first_meeting_url_affiliate_tag || ""}</p>
          </div>
          <div>
            <button
              className="btn-copy"
              onClick={() =>
                copyToClipBoard(
                  trainerProfileData?.first_meeting_url_affiliate_tag
                )
              }
            >
              <img src={CopyIcon} alt="CopyIcon" /> Copy
            </button>
          </div>
        </S.DataRow>
        <S.Divider />
        <S.DataRow>
          <div>
            <h6>Werbelink Foodiary App</h6>
            <p>{trainerProfileData?.werbelink_foodiary_app || ""}</p>
          </div>
          <div>
            <button
              className="btn-copy"
              onClick={() =>
                copyToClipBoard(trainerProfileData?.werbelink_foodiary_app)
              }
            >
              <img src={CopyIcon} alt="CopyIcon" /> Copy
            </button>
          </div>
        </S.DataRow>
        <S.Divider />
        <S.DataRow>
          <div>
            <h6>Erstgespräch Calendly URL</h6>
            <p>
              {trainerProfileData?.pp_first_meeting_calendly_url_profilpage ||
                ""}
            </p>
          </div>
          <div>
            <button
              className="btn-copy"
              onClick={() =>
                copyToClipBoard(
                  trainerProfileData?.pp_first_meeting_calendly_url_profilpage
                )
              }
            >
              <img src={CopyIcon} alt="CopyIcon" /> Copy
            </button>
          </div>
        </S.DataRow>
      </S.TrainerProfileDetailsInnerContainer>
    </S.TrainerProfileDetailsOverallContainer>
  );
};

export default TrainerProfileWebsiteOverview;
