import React from "react";
import { useHistory } from "react-router-dom";

import TranslationMessage from "configs/translations/TranslationMessage";
import Button from "components/formComponents/button/Button";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import InfoIcon from "assets/images/Onboarding/Screen6/icon-info.svg";
import ArrowLeft from "assets/images/arrowLeft.svg";
import { SidebarSteps } from "../data";

import * as S from "../OnBoardingPartThree.styles";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

const FoodiaryFamilyJoined = (props) => {
  const history = useHistory();

  const handleNext = () => {
    localStorage.removeItem("NutrtionIds");
    localStorage.removeItem("AnamneseUserId");
    history.push("/dashboard/main");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={5} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                {/* <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                /> */}
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Ernährungsform
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Starte mit unserer Unterstützung
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="foodiary-family-joined-content">
                <br />
                <br />
                <br />
                <h3 className="text-size-20-28 text-dark-1 text-center mt-0">
                  Willkommen in der Foodiary Family
                </h3>
                <p className="text-size-16-19 text-light-grey-1 text-center font-rubik-regular">
                  Schön, dass wir gemeinsam deine Ernährungsumstellung beginnen.
                </p>
                <br />
                <br />
                <br />
                <h6 className="text-size-14-28 text-dark-1 mt-0 mb-1">
                  Die nächsten Schritte:
                </h6>
                <div className="body-details-status-item">
                  <img className="info-img" src={InfoIcon} alt="" />
                  <div className="">
                    <p className="text-size-14-28 text-dark-1 font-rubik-medium my-0">
                      Du erhältst direkt eine E-Mail mit deinen Zugangsdaten.
                    </p>
                    <p className="text-size-14-19 text-light-grey-1 font-rubik-regular my-0">
                      {`Hier findest du den Link für den Download der App und zudem den Zugang für die Lernacademy. Nachdem du dein Passwort gesetzt hast, kannst du dich einloggen.`}
                    </p>
                  </div>
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
        <br />
        <br />
        <br />
      </S.OnBoardingPage>
    </>
  );
};

export default FoodiaryFamilyJoined;
