import styled, { css } from "styled-components";
import {
  MainCustomerContainer,
  MainCustomerHeadingContainer,
  MainCustomerHeading,
  MainCustomerSubHeading,
} from "components/MainCustomers/mainCustomers.styles";
import {
  NutritionFormCardContainer,
  NutritionFormCard,
  NutritionCardImage,
} from "pages/nurtritionForm/nutritionForm.styles";
import {
  BodyDetailsCard,
  BodyDetailsCardHeading,
  DailyRoutineInputContainer,
  DailyRoutineInputLabel,
  PersonalDataContainer,
  PersonalDataInnerContainer,
  PersonalDataInputContainer,
} from "pages/registration/registration.styles";

import RightArrow from "assets/images/active-arrow.png";
import Tick from "assets/images/tick.png";

export const ClientDetailsOverallContainer = styled(MainCustomerContainer)``;

export const ClientDetailsHeadingContainer = styled(
  MainCustomerHeadingContainer
)`
  width: 100%;
  padding-right: 1.5rem;
`;

export const ClientDetailsHeading = styled(MainCustomerHeading)``;

export const ClientDetailsSubHeading = styled(MainCustomerSubHeading)``;

export const ClientDetailsContainer = styled.div`
  /* display: grid;
  grid-template-columns: 300px 1fr; */
  display: flex;
  position: relative;
  /* gap:30px; */
  align-items: flex-start;
`;

export const ClientDetailsTabContainer = styled.div`
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  overflow-y: auto;
`;

export const ClientDetailsTab = styled.div`
  color: ${(props) => (props.active ? "#1C9D87" : "#44554C")};
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 8px;
  position: relative;
  padding: 8px 15px;
  border: 1px solid transparent;

  &.invoice-details {
    display: none;
  }

  &:hover {
    color: #1c9d87;
    box-shadow: 0px 1px 2px #1018280d;
    border-color: #d0d5dd;
    border-radius: 10px;
    background: white;
  }

  &:after {
    content: "";
    position: absolute;
    right: 6px;
    top: 10px;
    background-image: ${(props) =>
      props.active ? `url("${RightArrow}")` : "none"};
    width: 16px;
    height: 16px;
  }

  ${(props) =>
    props.active
      ? {
          boxShadow: "0px 1px 2px #1018280d",
          border: "1px solid #d0d5dd",
          borderRadius: "10px",
          background: "white",
        }
      : null}
`;

// second tab

export const Header = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .with-back-arrow {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
  }
`;

export const ClientDetailsContentContainer = styled.div`
  /* width: 100%; */
  border-radius: 8px;
  background: white;
  padding: 1.5rem;
  position: relative;
  height: 100%;
`;

export const DataRow = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h6 {
    font-size: 1rem;
    font-weight: 400;
    color: #142c1f;
    margin: 0;
  }

  p {
    font-size: 1rem;
    font-weight: 300;
    color: #818d88;
    margin-bottom: 0;
    margin-top: 0.4rem;
  }
`;

export const ContainerBox = styled.div`
  border-radius: 8px;
  background: white;
  box-shadow: 0px 2px 5px #0000001a;
  /* width: calc(100% - 4rem); */
  margin: 0 auto;
`;

export const ContainerBoxWithPadding = styled.div`
  padding: 1.5rem;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;

export const EmailBox = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const EmailBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h4 {
    color: #142c1f;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }

  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #818d88;
    margin: 0;
  }
`;

export const EmailBoxAction = styled.div`
  button {
    padding: 12px 26px;
    background: #ffffff;
    border: 1px solid #e2e4e9;
    border-radius: 10px;
    color: #141414;
    font-weight: 300;
    cursor: pointer;

    &:hover,
    &:focus {
      box-shadow: 0px 1px 2px #52586629;
    }

    &:disabled {
      pointer-events: none;
    }
  }
`;

export const DisableSectionOverlay = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  background: #0000005c;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
`;

export const OverlayContent = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 50px;
  }

  span {
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ClientDetailsContentHeading = styled.h6`
  font-size: 1.125rem;
  color: #142c1f;
  margin-bottom: 0;
  margin-top: 0;
`;

export const ClientDetailsContentSubHeading = styled.div`
  font-size: 15px;
  color: #a5b5af;
  font-weight: 400;
`;
export const AnamneseHeading = styled.div`
  margin-bottom: 2.1rem;
`;

export const Heading = styled.h4`
  margin: 0 0 0.8rem 0;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #142c1f;
  font-weight: 500;
`;

export const SubHeading = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #818d88;
  font-weight: 300;
`;

// Overfiew

export const ClientDetailsOverviewContainer = styled.div`
  min-height: 6.25rem;
  width: auto;
  margin: auto;
`;

export const ClientDetailsOverviewInnerContainer = styled.div`
  margin: 0;
`;

export const ClientDetailsOverviewInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const ClientDetailsOverviewInputLabel = styled.div`
  font-size: 0.94rem;
  color: #44554c;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.32px;
  margin-bottom: 0.4rem;
`;

// Calories
export const ClientDetailsCaloriesContainer = styled.div`
  position: relative;
  max-width: 70.5625rem;
  margin-inline: auto;
`;

export const ClientDetailsIncrementInputContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 1.25rem;
`;

export const ClientDetailsIncrementInputCard = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ClientDetailsIncrementInputCardHeading = styled.p`
  color: #142c1f;
  font-weight: 500;
  font-size: 1rem;
`;

export const ClientDetailsCaloriesLineChartContainer = styled.div`
  border: 1.5px solid #eeeeee;
  width: 100%;
  border-radius: 10px;
  p {
    margin-top: 100px;
    text-align: center;
  }
`;

export const ClientDetailsWeekNavigationContainer = styled.div`
  width: 185px;
  position: absolute;
  top: -60px;
  right: 15px;
  display: flex;
`;

export const ClientDetailsCaloriesNavigationIconContainer = styled.span`
  margin: 10px;
  cursor: pointer;
`;

export const ClientDetailsEditCaloriesWeekHeading = styled.span`
  line-height: 50px;
  width: 200px;
  text-align: center;
  color: #1c9d87;
`;

// Nutirtion Form

export const ClientDetailsNutritionFormContainer = styled(
  NutritionFormCardContainer
)`
  margin-block: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 11px;
`;

export const ClientDetailsNutritionFormCard = styled(NutritionFormCard)`
  width: 100%;
  height: 125px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  margin-top: 0;

  color: ${(props) => {
    if (props.color) return props.color;
    else if (props.selected) return "#142C1F";
    else return "rgba(20, 44, 31, 1)";
  }};
  background: ${(props) => {
    if (props.background) return props.background;
    else if (props.selected) return "#F3FFFD";
    else return "#fff";
  }};
`;

export const ClientDetailsNutritionCardImage = styled(NutritionCardImage)`
  margin: 0 0 0.8rem;
`;

// Daily Routine
export const ClientDetailsDailyRoutineContainer = styled.div`
  display: grid;
  font-size: 16px;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const ClientDetailsDailyRoutineTabContainer = styled.div`
  width: 100%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  /* max-height: 240px; */
  overflow: auto;
`;

export const NewRoutine = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  letter-spacing: 0.32px;
  color: #141414;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 1px 2px #5258660f;
  border: 1px solid #e2e4e9;
  border-radius: 10px;
  padding: 0.5rem 1rem;
`;

export const ClientDetailsDailyRoutineTab = styled.div`
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  /* background: ${(props) => (props.isActive ? "#F8F8F8" : "white")};
  border-color: ${(props) => (props.isActive ? "#2ECF96" : "#d0d5dd")}; */
  padding: 1rem;
  display: flex;
  align-items: center;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const ClientDetailsDailyRoutineTabHeading = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
`;
export const ClientDetailsDailyRoutineTabDescription = styled.p`
  color: #44554c;
  font-size: 0.875rem;
  margin: 5px 0 0 0;
`;

export const ClientDetailsDailyRoutineTabRightArrowIcon = styled.img`
  position: absolute;
  right: 1.25rem;

  &.active {
    right: 1.125rem;
  }
`;

export const ClientDetailsDailyRoutineTabDetailsContainer = styled.div`
  width: 100%;
  height: 285px;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  position: relative;
`;

export const ClientDetailsDailyRoutineTabDetailsInnerContainer = styled.div`
  margin: 1.8rem 1.8rem 0;

  .daily-routine-details-header {
    display: flex;
    justify-content: space-between;

    h6 {
      font-size: 1.125rem;
      margin: 0;
      color: #142c1f;
    }

    p {
      font-size: 0.875rem;
      margin: 0;
      color: #44554c;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 0.8rem;
    }
  }
`;

export const ClientDetailsDailyRoutineTabDetailsHeading = styled.div`
  color: #44554c;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  font-weight: 400;

  &:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  > span {
    color: #a5b5af;
    font-weight: 400;
    font-weight: 400;
  }
`;

export const ClientDetailsDailyroutineTabDetailsActionButtonsContainer = styled.div`
  position: absolute;
  right: 28px;
  bottom: 25px;
  display: flex;
`;

export const ClientDetailsDailyroutineIcon = styled.div`
  box-shadow: 0px 1px 2px #5258660f;
  border: 1px solid #e2e4e9;
  border-radius: 6px;
  padding: 0.4rem 0.5rem 0.2rem 0.5rem;
  cursor: pointer;

  img {
    width: 0.8rem;

    &.edit {
      width: 0.9rem;
      height: 1rem;
    }
  }
`;

export const ClientDetailsDailyroutineInputContainer = styled(
  DailyRoutineInputContainer
)``;

export const ClientDetailsDailyroutineInputLabel = styled(
  DailyRoutineInputLabel
)``;

// Donut Chart

export const ClientDetailsDonutContainer = styled.div`
  margin-top: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  align-items: center;
  gap: 1.5rem;

  .item {
    box-shadow: 0px 13px 20px #d6d6d629;
    border: 2px solid #e2e4e9;
    border-radius: 15px;
    padding: 1.25rem;
    height: 100%;
    display: flex;

    &.chart-item {
      align-items: center;
      justify-content: center;
    }

    &.legend-item {
      flex-direction: column;
      justify-content: space-between;

      h6 {
        font-size: 1rem;
        margin: 0;
      }
    }
  }
`;
export const TargetDateRange = styled.p``;

export const TargetInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const OrangeLine = styled.div`
  width: 30px;
  height: 2px;
  background: #e89280;
`;

export const TargetInfoTitle = styled.span`
  color: #44554c;
  font-size: 16px;
  line-height: 19px;
  margin-inline: 7px;
`;

export const TargetInfoValue = styled.span`
  color: #142c1f;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
`;

export const GoalsHeading = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.24px;
  color: #44554c;
  margin: 0;
`;

export const Goals = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 10px;
`;

export const Goal = styled.div`
  background: #fff;
  border: 2px solid #eeeeee;
  border-radius: 8px;
  width: 100%;
  height: 143px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  flex-direction: column;
  padding: 16px;

  ${(props) =>
    props.isActive &&
    css`
      background: #f3fffd;
      border-color: #2ecf96;
      color: #142c1f;
    `}

  img {
    width: 34px;
    margin-bottom: 1.4rem;
  }

  p {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    margin-block: 0;
  }
`;
export const FormWrap = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
`;

export const FormControl = styled.div`
  position: relative;

  img {
    cursor: pointer;
    position: absolute;
    bottom: 0.1375rem;
    right: 0.1875rem;

    &.copy-icon {
      bottom: .5rem;
      right: .3125rem;
    }
  }

  label {
    letter-spacing: 0.24px;
    color: #44554c;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    display: block;
    font-weight: 500;
  }
  input {
    width: 100%;
    margin: 0px;
  }
`;

export const ProgressHeadingWrap = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressHeading = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #142c1f;
`;

export const ChipBox = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const Chip = styled.div`
  border: 2px solid #ededed;
  border-radius: 17px;
  color: #a5b5af;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 18px;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      border-color: #2ecf96;
      background: #2ecf96;
      color: #ffffff;
    `}
`;

export const ProgressBody = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  display: flex;
  column-gap: 26px;
  flex-wrap: wrap;
`;

export const StatsCol = styled.div``;

export const StatsRow = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 1.4fr 1fr 1fr;
  margin-bottom: 15px;
  width: calc(50% - 13px);
`;

export const StatDate = styled.div`
  color: #a5b5af;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;
`;

export const StatProgress = styled.div`
  color: #a5b5af;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;
`;

export const StatTitleBox = styled.div`
  color: #44554c;
  font-size: 1rem;
  line-height: 17px;
  padding: 9px 15px;
  background: #f6f8fa;
  box-shadow: 0px 1px 2px #e4e5e73d;
  border: 2px solid #e2e4e9;
  border-radius: 10px;
`;

export const StatValueBox = styled.div`
  box-shadow: 0px 1px 2px #e4e5e73d;
  border: 2px solid #e2e4e9;
  border-radius: 10px;
  color: #44554c;
  font-size: 14px;
  line-height: 17px;
  padding: 9px 15px;
`;

export const StatCalculationBox = styled.div`
  box-shadow: 0px 1px 2px #e4e5e73d;
  border: 2px solid #e2e4e9;
  border-radius: 10px;
  padding: 9px 15px;
  display: flex;
  gap: 3px;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    color: #44554c;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const VisualProgressHeadingWrap = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
`;

export const VisualProgressHeading = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #142c1f;
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const ProgressImages = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 30px;
`;

export const ProgressImageItem = styled.div`
  position: relative;

  img {
    border-radius: 5px;
    width: 200px;
    height: 278px;
    object-fit: cover;
  }
`;

export const StartDay = styled.span`
  background: #142c1f;
  border-radius: 3px;
  padding: 5px 10px;
  color: white;
  position: absolute;
  top: 15px;
  left: 15px;
`;

export const Week = styled.span`
  background: #2ecf96;
  border-radius: 3px;
  padding: 5px 10px;
  color: white;
  position: absolute;
  top: 15px;
  left: 15px;
`;

// export const Hellow = styled()`
//   color:red;
// `

export const InnerMenuWrap = styled.div`
  width: 100%;
  max-width: 300px;
  height: 100%;
  background-color: #f5f5f5;
  margin-right: 30px;
`;

export const InnerMenuTop = styled.div`
  padding: 22px;
  div {
    justify-content: flex-start;
  }

  button {
    text-decoration: none !important;
    color: #44554c;
    font-size: 16px;
    line-height: 19px;
    display: inline-flex;
    font-weight: 400;
    align-items: center;
    padding: 0;
    background-color: transparent;
  }
`;

export const BackButton = styled.div`
  text-decoration: none !important;
  color: #44554c;
  font-size: 16px;
  line-height: 19px;
  display: inline-flex;
  font-weight: 400;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: #1c9d87;
  }
`;

export const InnerMenuMid = styled.div`
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  padding: 30px;

  .user-profile {
    display: flex;
    align-items: center;
  }
`;

export const InnerMenuInfo = styled.div`
  margin-left: 15px;
  h5 {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #142c1f;
    margin-bottom: 8px;
  }
  p {
    font-size: 15px;
    line-height: 18px;
    color: #44554c;
    margin-bottom: 0px;
    font-weight: 400;
    span {
      color: #a5b5af;
    }
  }
`;

export const PaymentInfo = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  p {
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #44554c;
    margin: 0;
    font-weight: 400;
  }

  .dots {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    .dot {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      &.grey {
        background: #00000042;
      }
      &.green {
        background: #2ecf96;
      }
      &.orange {
        background: #d87845;
      }
      &.red {
        background: #ff0000;
      }
    }
  }
`;

export const InnerMenuLast = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  h6 {
    letter-spacing: 0.8px;
    color: #142c1f;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  a {
    color: #44554c;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    text-decoration: none !important;
    margin-bottom: 20px;
    display: inline-flex;
    cursor: pointer;
    &:hover {
      color: #2ecf96;
    }
  }
  .MuiPaper-root {
    box-shadow: none !important;
    background-color: transparent;
    margin-bottom: 20px;
  }
  .MuiAccordionSummary-root {
    padding: 0px;
    height: 19px;
    min-height: 19px;
    &:hover {
      .MuiAccordionSummary-content p,
      .MuiIconButton-root {
        color: #2ecf96;
      }
    }
  }
  .MuiAccordionSummary-content {
    margin: 0px;
  }
  .MuiIconButton-root {
    padding: 0px;
    margin-right: 0px;
    height: 19px;
  }

  .MuiAccordionDetails-root {
    padding: 8px 16px 16px 20px;
    display: flex;
    flex-direction: column;
    a {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .MuiAccordion-root:before {
    display: none;
  }
  .MuiAccordionSummary-content {
    p {
      color: #44554c;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
    }
  }
  .MuiAccordion-root.Mui-expanded {
    margin: 0px;
  }
  .MuiAccordionSummary-expandIcon {
    transform: rotate(270deg) !important;
  }
  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(360deg) !important;
  }
`;

export const ClientDetailsSaveButton = styled.div`
  div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
  }
  button {
    width: 127px;
    height: 43px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    background-color: #1c9d87;
  }
`;

export const DonutChartLegend = styled.div`
  list-style: none;
  padding: 0;
  //margin: 30px 0 0 -140px;
  display: flex;
  flex-direction: column;

  span {
    margin-right: 2rem;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    margin-bottom: 1.1825rem;
    &:before {
      content: "";
      display: inline-block;
      width: 0.875rem;
      height: 0.875rem;
      margin-right: 0.5em;
      vertical-align: middle;
      border-radius: 50%;
    }
    &:nth-child(5n + 1)::before {
      background: #c74343;
    }
    &:nth-child(5n + 2)::before {
      background: #2ecf96;
    }
    &:nth-child(5n + 3)::before {
      background: #ffc400;
    }
  }
`;

// new style

export const DiaryWrap = styled.div`
  display: grid;
  grid-template-columns: 320px 1fr;
  gap: 20px;
`;

export const CalanderWrap = styled.div`
  .react-calendar {
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding: 10px;
  }
  .react-calendar__tile--active {
    background: #2ecf96 !important;
    border-radius: 8px;
    opacity: 0.9;
    color: #fff !important;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #2ecf96;
  }
  .react-calendar__navigation {
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    background: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
    gap: 5px;
  }
  .react-calendar__tile {
    color: #44554c;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 7px 0;
    max-width: 33px;
    gap: 10px;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    border-color: #2ecf96;
    background: transparent;
  }
  .react-calendar__month-view__weekdays__weekday {
    color: #a5b5af;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    text-transform: capitalize;
  }
  .react-calendar__month-view__days {
    gap: 10px;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none !important;
  }

  .react-calendar__navigation__label {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    background: #fff;
    border-radius: 8px;
    opacity: 0.9;
    span {
      color: #44554c;
    }
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 8px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2ecf96;
    min-width: 24px;
  }

  abbr[title] {
    text-decoration: none;
  }

  .highlight-c.react-calendar__tile {
    border-color: #2ecf96;
    background: transparent;
  }
`;

export const FeedbackWrap = styled.div`
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 25px;
`;

export const FeedbackWrapInner = styled.div`
  h5 {
    margin: 0;
    letter-spacing: 0.24px;
    color: #44554c;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
`;

export const FeedbackSmily = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: column;
`;

export const RadioRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const SmilyRow = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  p {
    margin: 0;
    color: #44554c;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
  }
`;

export const SmileFace = styled.div`
  display: flex;
  gap: 5px;

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 0;
    margin: 0;

    &:hover,
    .active {
      fill: #2ecf96;
    }
  }
`;
export const SelfMadeCheckBoxRow = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: 1rem;
  span {
    cursor: pointer;
    color: #44554c;
    font-weight: 400;
  }
  .form-control {
    color: #142c1f;
    font-size: 1rem;
    line-height: 1.1875rem;
    font-weight: 400;
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: center;
    gap: 0.325rem;
  }

  .form-control + .form-control {
    margin-top: 1em;
  }

  .form-control--disabled {
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }

  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.35rem;
    height: 1.35rem;
    border: 0.125rem solid #a5b5af;
    border-radius: 0.5rem;
    transform: translateY(-0.075em);
    background-color: #fff;

    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.9rem;
    height: 0.9rem;
    background-position: center;
    background-size: contain;
    background-image: url("${Tick}");
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }
  input[type="checkbox"]:checked {
    background-color: #2ecf96;
    border-color: #2ecf96;
  }
  input[type="checkbox"]:checked + span {
    /* text-decoration: line-through; */
  }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  span {
    font-weight: 400;
  }

  &.line-through {
    text-decoration: line-through;
  }
`;

export const PaddingBox = styled.div`
  padding: 1.5rem;
`;

export const InvoiceDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  h6 {
    color: #142c1f;
    font-weight: 500;
    font-size: 1.25rem;
    margin: 0;
  }

  span {
    font-weight: 400;
    color: #44554c;
    font-size: 1rem;
  }
`;

export const InvoiceDetailsContainer = styled.div`
  max-width: 53.625rem;
  width: 100%;
  margin-inline: auto;
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead {
    background: #f6f8fa;

    th {
      color: #535865;
      font-size: 1rem;
      /* font-weight: 400; */
      padding: 0.875rem 2rem;
      text-align: left;
      font-weight: 500;

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 1rem 2rem;
        border-bottom: 1px solid #e2e4e9;

        p {
          margin: 0;
          font-size: 1rem;
          font-weight: 300;
        }
      }
    }
  }

  .partner {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    img {
      background: #ffffff;
      box-shadow: 0px 1px 2px #e4e5e73d;
      border: 2px solid #e2e4e9;
      border-radius: 50%;
      width: 3.2rem;
      min-width: 3.2rem;
      height: 3.2rem;
    }

    h6 {
      margin: 0;
      color: #44554c;
      font-size: 0.9rem;
      line-height: 1.3rem;
      font-weight: 400;
    }

    p {
      margin: 0;
      color: #a5b5af;
      font-size: 0.9rem;
      font-weight: 300;
    }
  }

  .file-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    img {
      width: 2rem;
    }

    span {
      font-size: 1rem;
      font-weight: 300;
    }

    a {
      color: #44554c;
      text-decoration: underline;
      font-weight: 400;
      width: 15rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .contact-person {
    h6 {
      margin: 0;
      color: #44554c;
      font-size: 0.9rem;
      line-height: 1.3rem;
      font-weight: 400;
    }
  }
`;

export const DataSection = styled.div`
  padding: 0 1rem;
  margin-bottom: 1rem;
  h6 {
    letter-spacing: 0.8px;
    color: #142c1f;
    font-weight: 500;
    font-size: 1rem;
    margin: 1rem 0;
  }

  .data-rows {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .data-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    p {
      color: #44554c;
      font-weight: 400;
      font-size: 1rem;
      margin: 0;
    }
  }

  .note {
    margin-top: 0.5rem;
    color: #a5b5af;
    font-weight: 300;
    font-size: 0.875rem;
  }

  .link-box {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    img {
      background: #ffffff;
      box-shadow: 0px 1px 2px #5258660f;
      border: 1px solid #e2e4e9;
      border-radius: 5px;
      padding: 6px 4px;
    }

    a {
      color: #44554c;
      text-decoration: underline;
      font-weight: 400;
      width: 15rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const CardsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  h6,
  h4,
  p {
    margin: 0;
  }

  .credit-card {
    box-shadow: 0px 1px 2px #1018280d;
    border: 1px solid #d0d5dd;
    border-radius: 0.8125rem;

    .header,
    .body {
      padding: 1rem;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #d0d5dd;

      h6 {
        color: #142c1f;
        font-size: 1rem;
        /* font-weight: 400; */
      }

      .date-range {
        background: #f3f4f6;
        box-shadow: 0px 1px 2px #1018280d;
        border-radius: 0.5rem;
        color: #44554c;
        font-size: 0.875rem;
        font-weight: 400;
        padding: 0.3rem 0.4rem;
      }
    }

    .body {
      .income-title {
        color: #686e7c;
        font-size: 0.875rem;
        font-weight: 400;
      }

      .income {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.8rem;

        h4 {
          color: #191d1b;
          font-size: 2rem;
          font-weight: 500;
        }

        .income-status {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .arrow-up {
            background: #e6faf3;
            border-radius: 0.8125rem;
            padding: 0.4375rem 0.75rem;
          }

          span {
            color: #44554c;
            font-size: 0.75rem;
            font-weight: 400;
          }
        }
      }

      .download-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          border-bottom: 1px solid #d0d5dd;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
        }

        .left {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .pdf-icon {
            width: 1.375rem;
            height: 1.375rem;
          }

          span {
            color: #44554c;
            font-size: 1rem;
            font-weight: 400;
          }
        }

        .download-icon {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
`;

export const LineChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: ${(props) => props.margin};
`;

export const LineChartHeading = styled.h5`
  font-size: 1.5rem;
  width: auto;
  text-align: left;
  margin: 0;
`;

export const LineChartStatus = styled.span`
  font-size: 1.5rem;
  color: #2ecf96;
  text-align: right;
  margin-top: ${(props) => props.marginTop};
`;

export const LineChartDescription = styled.span`
  font-size: 1rem;
  color: #44554c;
  font-weight: 400;
`;
