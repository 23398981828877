import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { object, string } from "yup";
import emailjs from "@emailjs/browser";
import { getS3ImageUrl } from "configs/utils";
import { ToastContainer, toast } from "react-toastify";
import Input from "components/formComponents/input/Input";
import TextArea from "components/formComponents/textArea/TextArea";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import Modal from "components/modal/Modal";
import PasswordResetModal from "components/PasswordResetModal/PasswordResetModal";

import CrossIcon from "assets/images/cross.svg";
import TerminationSvgOne from "assets/images/foodiary-cancle-question.png";
import TerminationSvgTwo from "assets/images/foodiary-cancle-feedback.png";

import * as S from "./TerminationForm.styles";
import { MENUS } from "../data";

const TerminationForm = () => {
  const terminationFormRef = useRef(null);

  const [AreYouSureModelOpen, SetAreYouSureModelOpen] = useState(false);
  const [SuccessModel, SetSuccessModel] = useState(false);
  const [IsEmailSending, setIsEmailSending] = useState(false);
  const [IsSuccess, setIsSuccess] = useState(false);
  const [IsError, setIsError] = useState(false);

  const [passwordResetModal, setPasswordResetModal] = useState(false);

  const trainerData = useSelector((state) => state.auth.trainerData);

  const initialValues = {
    prename: trainerData?.prename,
    surname: trainerData?.surname,
    FranchiseID: trainerData?.FranchiseID,
    terminationReason: "",
  };

  const validationSchema = object().shape({
    FranchiseID: string()
      .typeError("You must enter a String")
      .required("Required"),
    surname: string().typeError("You must enter a String").required("Required"),
    prename: string().typeError("You must enter a String").required("Required"),
    terminationReason: string()
      .typeError("You must enter a String")
      .required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    setIsEmailSending(true);
    let templateParams = {
      subject: "Kündigung",
      news: values.terminationReason,
      franchiseId: values.FranchiseID,
      prename: values.prename,
      surname: values.surname,
      fromName: `${values.prename} ${values.surname}`,
      email: trainerData?.email,
      senderEmail: trainerData?.email,
      recipientEmail: "Backoffice@foodiary.app",
    };

    emailjs
      .send(
        "service_srrcjm9",
        "template_d49rcah",
        templateParams,
        "uowiKoxYT_i1TYcoP"
      )
      .then(
        function (response) {
          setIsEmailSending(false);
          setIsSuccess(true);
          resetForm();
          // const timer = setTimeout(() => {
          //   setIsSuccess(false);
          //   clearTimeout(timer);
          // }, 3000);
        },
        function (error) {
          setIsEmailSending(false);

          setIsError(true);

          // const timer = setTimeout(() => {
          //   setIsError(false);
          //   clearTimeout(timer);
          // }, 3000);
        }
      );
  };

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav
            menus={MENUS}
            userProfileInfo={{
              profilePic: getS3ImageUrl(trainerData.profilePic),
              franchiseId: trainerData?.FranchiseID,
              stripeId: trainerData?.["Stripe Connect ID"] || "",
              prename: trainerData.prename,
              surname: trainerData.surname,
              connected_user_account_id: trainerData?.connected_user_account_id,
            }}
            onPasswordLink={() => setPasswordResetModal(true)}
            idsSection
          ></InnerSideNav>
        </div>
        <PasswordResetModal
          show={passwordResetModal}
          setShow={setPasswordResetModal}
        />
        <ToastContainer />
        <S.Wrapper className="second-sidebar-content">
          <S.TrainerProfileDetailsInnerContainer>
            <S.Header className="p-0">
              <div>
                <S.TrainerProfileDetailsSectionHeading>
                  Kündigung einreichen
                </S.TrainerProfileDetailsSectionHeading>
                <S.TrainerProfileDetailsSectionSubHeading>
                  Reiche deine Kündigung ein und wir melden uns umgehend bei
                  dir.
                </S.TrainerProfileDetailsSectionSubHeading>
              </div>
              <div>
                <button
                  className="btn-dark"
                  onClick={(event) => {
                    event.preventDefault();
                    SetAreYouSureModelOpen(true);
                  }}
                >
                  Kündigung einreichen
                </button>
              </div>
            </S.Header>
            <br />
            <S.Divider />
            <br />

            {/* {IsSuccess && (
                    <S.SuccessAlert>
                      Deine Nachricht wurde verschickt
                    </S.SuccessAlert>
                  )}
                  {IsError && (
                    <S.ErrorAlert>
                      Da hat etwas nicht geklappt. Bitte versuche es erneut
                    </S.ErrorAlert>
                  )} */}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onFormSubmit}
              innerRef={terminationFormRef}
              enableReinitialize
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isValid,
                dirty,
              }) => (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <S.InlineThreeControls>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        FRANCHISE-ID
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        name="FranchiseID"
                        onChange={handleChange}
                        value={values.FranchiseID}
                        disabled
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        VORNAME
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <Input
                        filled
                        disabled
                        className="input-custom copy-text"
                        style={{ width: "100%", marginBottom: "10px" }}
                        name="prename"
                        onChange={handleChange}
                        value={values.prename}
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        NACHNAME
                      </S.TrainerProfileDetailsSection1InputLabel>
                      <Input
                        className="input-custom copy-text"
                        filled
                        disabled
                        style={{ width: "100%", marginBottom: "10px" }}
                        name="surname"
                        onChange={handleChange}
                        value={values.surname}
                      />
                    </S.FormControl>
                  </S.InlineThreeControls>

                  {/* <S.FormControl>
                    <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                      BETREFF
                    </S.TrainerProfileDetailsSection1InputLabel>
                    <Input
                      className="input-custom copy-text"
                      style={{ width: "100%", marginBottom: "10px" }}
                      name="reference"
                      onChange={handleChange}
                      value={values.reference}
                    />
                  </S.FormControl> */}
                  <div className="textarea-box">
                    <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                      KÜNDIGUNGSGRUND
                    </S.TrainerProfileDetailsSection1InputLabel>
                    <TextArea
                      rows={10}
                      name="terminationReason"
                      onChange={handleChange}
                      value={values.terminationReason}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </S.TrainerProfileDetailsInnerContainer>
        </S.Wrapper>
      </S.Container>
      <Modal
        width="450px"
        height="auto"
        show={AreYouSureModelOpen}
        setShow={() => null}
        heading={
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="my-0 text-left"> </h4>
            {!IsEmailSending && (
              <img
                className="cursor-pointer"
                src={CrossIcon}
                alt="CrossIcon"
                onClick={() => SetAreYouSureModelOpen(false)}
              />
            )}
          </div>
        }
      >
        <div className="congratulations-modal-body-inner">
          {!IsSuccess && <img src={TerminationSvgOne} alt="" />}
          {IsSuccess && <img src={TerminationSvgTwo} alt="" />}
          <div>
            {!IsSuccess && (
              <>
                <h6>Bist du sicher?</h6>
                <p>
                  Schade, dass du Foodiary verlassen möchtest. <br />
                  Gibt es einen Grund, bei dem wir weiterhelfen können?
                </p>
              </>
            )}

            {IsSuccess && (
              <>
                <h6>Kündigung verschickt.</h6>
                <p>
                  Wir möchten uns ständig weiterentwickeln. <br />
                  Dein Feedback wäre hier sehr hilfreich.
                </p>
              </>
            )}
          </div>
          <div className="text-center d-flex gap-3">
            {!IsSuccess && (
              <>
                {!IsEmailSending && (
                  <a
                    href="mailto:backoffice@foodiary.app"
                    className="btn-dark mx-auto"
                  >
                    Um Hilfe bitten
                  </a>
                )}
                <button
                  className="btn-dark mx-auto"
                  onClick={() => {
                    if (terminationFormRef.current) {
                      terminationFormRef.current.handleSubmit();
                    }
                  }}
                  disabled={IsEmailSending}
                >
                  {IsEmailSending ? "Loading..." : " Kündigung absenden"}
                </button>
              </>
            )}

            {IsSuccess && (
              <button
                className="btn-dark mx-auto"
                onClick={() =>
                  window.open("https://form.typeform.com/to/x2vEh2RX", "_blank")
                }
              >
                Anonymes Feedback
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TerminationForm;
