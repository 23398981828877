import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserRegistrationDataInLocalStorage,
  getUserRegistrationDataFromLocalStorage,
  removeDuplicatesUsingId,
} from "configs/utils";
import { TimePicker } from "@material-ui/pickers";
import moment from "moment";
// Components
import Button from "components/formComponents/button/Button";
import Modal from "components/modal/Modal";
import DatePicker from "components/formComponents/datePicker/DatePicker";
import TimePickerCustom from "components/formComponents/timePicker/TimePicker";
import TranslationMessage from "configs/translations/TranslationMessage";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import PlusCircleIcon from "assets/images/Onboarding/Screen6/Icon-plus.svg";
import ChevronDown from "assets/images/Onboarding/Screen6/Icon-arrow-down.svg";
import CrossIcon from "assets/images/cross.svg";
import ArrowLeft from "assets/images/arrowLeft.svg";

import {
  SidebarSteps,
  AddDailyRoutineValues,
  AccordionContentData,
} from "../data";
// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as S from "../OnBoardingPartOne.styles";
import OnboardingSidebar from "../OnboardingSidebar";

const DailyRoutine = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const registrationData = useSelector((state) => state.auth.registrationData);
  console.log(
    "registrationData.dailyRoutineData",
    registrationData.dailyRoutineData
  );

  const [dailyRoutineData, setDailyRoutineData] = useState([]);

  const [addDailyRoutineModal, setAddDailyRoutineModal] = useState(false);
  const [updateDailyRoutineModal, setUpdateDailyRoutineModal] = useState(false);
  const [selectedRoutine, setSelectedRoutine] = useState(null);
  const [routines, setRoutines] = useState(null);
  // const [routines, setRoutines] = useState(registrationData.routines);
  const [routineName, setRoutineName] = useState("");
  const [routineGetup, setRoutineGetup] = useState({
    dayroutineID: 1,
    time: "07:00",
  });
  const [routineLunchTime, setRoutineLunchTime] = useState({
    dayroutineID: 2,
    time: "12:00",
  });
  const [routineWorkoutTime, setRoutineWorkoutTime] = useState({
    dayroutineID: 3,
    time: "17:00",
  });
  const [routineBedTime, setRoutineBedTime] = useState({
    dayroutineID: 4,
    time: "22:00",
  });

  const [updateRoutineGetup, setUpdateRoutineGetup] = useState({
    dayroutineID: 1,
    id: "input.label.getup",
    time: "",
  });
  const [updateRoutineLunchTime, setUpdateRoutineLunchTime] = useState({
    dayroutineID: 2,
    id: "input.label.lunchTime",
    time: "",
  });
  const [updateRoutineWorkoutTime, setUpdateRoutineWorkoutTime] = useState({
    dayroutineID: 3,
    id: "input.label.workoutTime",
    time: "",
  });
  const [updateRoutineBedTime, setUpdateRoutineBedTime] = useState({
    dayroutineID: 4,
    id: "input.label.bedTime",
    time: "",
  });

  const [IsRoutineAddModalOpen, SetIsRoutineAddModalOpen] = useState(false);
  const [SelectedRoutineAccordian, SetSelectedRoutineAccordian] =
    useState(null);

  const [IsValidation, SetIsValidation] = useState(false);

  // useEffect(() => {
  //   const routines = [
  //     ...registrationData.dailyRoutineData,
  //     ...getUserRegistrationDataFromLocalStorage().dailyRoutineData,
  //   ];

  //   setDailyRoutineData(removeDuplicatesUsingId(routines));
  // }, []);

  useEffect(() => {
    setUniqueRoutines();
  }, [registrationData.dailyRoutineData]);

  const setUniqueRoutines = () => {
    // const registrationDataDailyRoutineData =
    //   registrationData?.dailyRoutineData || [];
    const dailyRoutineDataFromLocalStorage =
      getUserRegistrationDataFromLocalStorage()?.dailyRoutineData || [];

    let routines = [
      // ...registrationDataDailyRoutineData,
      ...dailyRoutineDataFromLocalStorage,
    ];

    setDailyRoutineData(removeDuplicatesUsingId(routines));

    // let routines = [
    //   ...registrationDataDailyRoutineData,
    //   ...dailyRoutineDataFromLocalStorage,
    // ];

    // setDailyRoutineData(removeDuplicatesUsingId(routines));
  };

  const handleAddRoutine = () => {
    SetIsValidation(false);
    const uniqueId = "id" + new Date().getTime();
    const newRoutine = {
      id: uniqueId,
      heading: routineName,
      data: [
        routineGetup["time"],
        routineLunchTime["time"],
        routineWorkoutTime["time"],
        routineBedTime["time"],
      ],
    };

    setUserRegistrationDataInLocalStorage({
      ...getUserRegistrationDataFromLocalStorage(),
      dailyRoutineData: [...dailyRoutineData, newRoutine],
    });

    setDailyRoutineData([...dailyRoutineData, newRoutine]);
    setRoutines({
      ...routines,
      [newRoutine.heading]: [
        { dayroutineID: 1, time: newRoutine.data[0] },
        { dayroutineID: 2, time: newRoutine.data[1] },
        { dayroutineID: 3, time: newRoutine.data[2] },
        { dayroutineID: 4, time: newRoutine.data[3] },
      ],
    });
    setRoutineName("");
    setRoutineGetup({ dayroutineID: 1, time: "07:00" });
    setRoutineLunchTime({ dayroutineID: 2, time: "12:00" });
    setRoutineWorkoutTime({ dayroutineID: 3, time: "17:00" });
    setRoutineBedTime({ dayroutineID: 4, time: "22:00" });
    SetIsRoutineAddModalOpen(false);
  };

  const handleTimeChange = (time, TimeId) => {
    const timeValue = moment(time).format("hh:mm");
    switch (TimeId) {
      case "input.label.getup":
        setRoutineGetup({ dayroutineID: 1, time: timeValue });
        break;
      case "input.label.lunchTime":
        setRoutineLunchTime({ dayroutineID: 2, time: timeValue });
        break;
      case "input.label.workoutTime":
        setRoutineWorkoutTime({ dayroutineID: 3, time: timeValue });
        break;
      case "input.label.bedTime":
        setRoutineBedTime({ dayroutineID: 4, time: timeValue });
        break;
      default:
        return dailyRoutineData;
    }
  };

  const updateRoutine = (accordion) => {
    const currentRoutine = dailyRoutineData.find(
      (item) => item?.id === accordion?.id
    );

    setUpdateRoutineGetup({
      dayroutineID: 1,
      id: "input.label.getup",
      timeString: currentRoutine.data[0],
      time: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        currentRoutine?.data[0]?.split(":")[0],
        currentRoutine?.data[0]?.split(":")[1],
        0
      ),
    });
    setUpdateRoutineLunchTime({
      dayroutineID: 2,
      id: "input.label.lunchTime",
      timeString: currentRoutine.data[1],
      time: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        currentRoutine?.data[1]?.split(":")[0],
        currentRoutine?.data[1]?.split(":")[1],
        0
      ),
    });
    setUpdateRoutineWorkoutTime({
      dayroutineID: 3,
      id: "input.label.workoutTime",
      timeString: currentRoutine.data[2],
      time: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        currentRoutine?.data[2]?.split(":")[0],
        currentRoutine?.data[2]?.split(":")[1],
        0
      ),
    });
    setUpdateRoutineBedTime({
      dayroutineID: 4,
      id: "input.label.bedTime",
      timeString: currentRoutine.data[3],
      time: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        currentRoutine?.data[3]?.split(":")[0],
        currentRoutine?.data[3]?.split(":")[1],
        0
      ),
    });

    const newData = {
      id: currentRoutine.id,
      heading: currentRoutine.heading,
      data: [
        {
          id: "input.label.getup",
          time: currentRoutine.data[0],
        },
        { id: "input.label.lunchTime", time: currentRoutine.data[1] },
        { id: "input.label.workoutTime", time: currentRoutine.data[2] },
        { id: "input.label.bedTime", time: currentRoutine.data[3] },
      ],
    };

    setSelectedRoutine({ ...newData });
    setUpdateDailyRoutineModal(true);
  };

  const handleUpdateTimeChange = (time, TimeId) => {
    const timeValue = moment(time).format("HH:mm");
    let routine = { ...selectedRoutine };
    setUpdateDailyRoutineModal(true);

    const obj = {
      dayroutineID: 1,
      id: "input.label.getup",
      timeString: timeValue,
      time: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        timeValue?.split(":")[0],
        timeValue?.split(":")[1],
        0
      ),
    };

    switch (TimeId) {
      case "input.label.getup":
        setUpdateRoutineGetup(obj);
        break;
      case "input.label.lunchTime":
        obj.id = "input.label.lunchTime";
        setUpdateRoutineLunchTime(obj);
        break;
      case "input.label.workoutTime":
        obj.id = "input.label.workoutTime";
        setUpdateRoutineWorkoutTime(obj);
        break;
      case "input.label.bedTime":
        obj.id = "input.label.bedTime";
        setUpdateRoutineBedTime(obj);
        break;
      default:
        return routine;
    }
  };

  const handleUpdateRoutine = (routine) => {
    const excludedRoutines = dailyRoutineData.filter(
      (item) => item?.id !== routine?.id
    );

    const updatedRoutines = [...excludedRoutines];

    let updateSingleRoutineAndGetListOfRoutines = updateObjectsValueInArray(
      routine?.data,
      updateRoutineGetup
    );
    updateSingleRoutineAndGetListOfRoutines = updateObjectsValueInArray(
      routine?.data,
      updateRoutineLunchTime
    );
    updateSingleRoutineAndGetListOfRoutines = updateObjectsValueInArray(
      routine?.data,
      updateRoutineWorkoutTime
    );
    updateSingleRoutineAndGetListOfRoutines = updateObjectsValueInArray(
      routine?.data,
      updateRoutineBedTime
    );

    const updatedRoutine = {
      id: routine?.id,
      heading: routine?.heading,
      data: updateSingleRoutineAndGetListOfRoutines,
    };

    updatedRoutines.push({ ...updatedRoutine });
    console.log("updatedRoutines", updatedRoutines);
    setUserRegistrationDataInLocalStorage({
      ...getUserRegistrationDataFromLocalStorage(),
      dailyRoutineData: updatedRoutines,
    });
    setDailyRoutineData(updatedRoutines);
    // dispatch(AuthActions.setDailyRoutineData({ updatedRoutines }));
    setUpdateDailyRoutineModal(false);
  };

  const updateObjectsValueInArray = (array, valueObj) => {
    if (!valueObj?.timeString) return;

    return array
      ?.map((item) => {
        if (item?.id === valueObj?.id) {
          item.time = valueObj?.timeString;
        }
        return item;
      })
      .map((item) => item?.time);
  };

  const handleDeleteItem = (routine) => {
    const excludedRoutines = dailyRoutineData.filter(
      (r) => r?.id !== routine?.id
    );
    setDailyRoutineData([...excludedRoutines]);

    setUserRegistrationDataInLocalStorage({
      ...getUserRegistrationDataFromLocalStorage(),
      dailyRoutineData: [...excludedRoutines],
    });
  };

  const handleNext = () => {
    if (dailyRoutineData && dailyRoutineData.length === 0) {
      SetIsValidation(true);
      return;
    }
    history.push("/onboarding/registration/activity-level");
  };

  const handleBack = () => {
    history.push("/onboarding/registration/body-details");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={2} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Anamnese
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Wir erstellen deinen Plan.
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="daily-routine-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium">
                  Dein Plan passt sich deinem Alltag an.
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Du kannst jederzeit in der App weitere Tagesabläufe anlegen
                  und der Wochenroutine hinzufügen.
                </p>
                <br />
                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-size-14-28 text-dark-1 font-rubik-medium my-0">
                    Lege deine Tagesabläufe fest
                  </p>
                  <button
                    className="btn-outline sm"
                    onClick={() => SetIsRoutineAddModalOpen(true)}
                  >
                    <img src={PlusCircleIcon} alt="plus" /> Neue Routine
                  </button>
                </div>
                <br />
                {IsValidation && (
                  <p className="text-size-14-18 text-right text-red font-rubik-regular mt-0">
                    Bitte füge mindestens eine Tagesroutine hinzu
                  </p>
                )}
                <div className="daily-routine-items">
                  {dailyRoutineData.map((routine, i) => (
                    <div className="daily-routine-item" key={routine?.id + i}>
                      <div className="daily-routine-item-header">
                        <div className="headings">
                          <h6 className="text-size-18-22 font-rubik-medium my-0 text-dark-1">
                            {routine.heading}
                          </h6>
                          <p className="text-size-14-20 font-rubik-regular mb-0 text-dark-2 mt-1">
                            Tagesroutine
                          </p>
                        </div>

                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="accordian-toggle"
                            onClick={() => handleDeleteItem(routine)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.906"
                              height="14.451"
                              viewBox="0 0 8.906 11.451"
                            >
                              <path
                                id="Icon_material-outline-delete-outline"
                                data-name="Icon material-outline-delete-outline"
                                d="M8.136,14.679a1.276,1.276,0,0,0,1.272,1.272H14.5a1.276,1.276,0,0,0,1.272-1.272V7.045H8.136ZM9.409,8.317H14.5v6.362H9.409ZM14.18,5.136,13.544,4.5H10.363l-.636.636H7.5V6.409h8.906V5.136Z"
                                transform="translate(-7.5 -4.5)"
                                fill="#44554c"
                              />
                            </svg>
                          </div>

                          <div
                            className={
                              SelectedRoutineAccordian?.id === routine?.id
                                ? "accordian-toggle active"
                                : "accordian-toggle"
                            }
                            onClick={() => {
                              if (SelectedRoutineAccordian) {
                                SetSelectedRoutineAccordian(null);
                              } else {
                                SetSelectedRoutineAccordian(routine);
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.982"
                              height="7"
                              viewBox="0 0 11.982 7.2"
                            >
                              <path
                                id="Icon_ionic-ios-arrow-back"
                                data-name="Icon ionic-ios-arrow-back"
                                d="M9.878,10.29l4.534-4.53A.856.856,0,1,0,13.2,4.551L8.062,9.684a.855.855,0,0,0-.025,1.181L13.2,16.033a.856.856,0,1,0,1.213-1.209Z"
                                transform="translate(-4.301 14.663) rotate(-90)"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {SelectedRoutineAccordian?.id === routine?.id && (
                        <div className="daily-routine-item-body mt-3">
                          {AccordionContentData.map((label, index) => (
                            <div
                              className="routine-time-item d-flex align-items-center justify-content-between"
                              key={label?.id + index}
                              onClick={() => updateRoutine(routine)}
                            >
                              <p className="text-size-16-19 text-dark-2 font-rubik-regular">
                                <TranslationMessage id={label?.id} />
                              </p>
                              <div className="time-item text-size-14-16 text-dark-3 font-rubik-regular cursor-pointer">
                                {routine?.data[index]} Uhr
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>

        <Modal
          width="550px"
          height="auto"
          show={IsRoutineAddModalOpen}
          setShow={() => null}
          heading={
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="my-0 text-left text-size-20-24 font-rubik-medium">
                Dein Tagesablauf
              </h4>

              <img
                className="cursor-pointer"
                src={CrossIcon}
                alt="CrossIcon"
                onClick={() => SetIsRoutineAddModalOpen(false)}
              />
            </div>
          }
        >
          <div className="change-routine-modal-inner">
            <div className="form-group">
              <label className="text-size-14-17 font-rubik-regular text-dark-1 mb-0">
                Name des Tagesablaufes:
              </label>
              <input
                placeholder="Arbeitstag"
                type="text"
                value={routineName}
                onChange={(e) => setRoutineName(e.target.value)}
              />
            </div>
            <br />

            {AddDailyRoutineValues?.map((value) => {
              return (
                <div
                  className="routine-time-item d-flex align-items-center justify-content-between"
                  key={value.id}
                >
                  <p className="text-size-16-19 text-dark-2 font-rubik-regular">
                    <TranslationMessage id={value.id} />
                  </p>
                  {/* <div className="time-item text-size-14-16 text-dark-3 font-rubik-regular cursor-pointer">
                    12:00 Uhr
                  </div> */}
                  <TimePickerCustom
                    className="time-item"
                    value={new Date(2007, 11, 5, value.hrs, 0, 17)}
                    onChange={(time) => {
                      handleTimeChange(time, value.id);
                      setAddDailyRoutineModal(true);
                    }}
                  />
                </div>
              );
            })}

            <Button
              color="rgba(248, 164, 146, 1)"
              gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
              margin="1.5rem auto 0"
              absolute={{ width: "fit-content", height: "2.8125rem" }}
              buttonStyle={{ padding: ".2625rem 1.875rem" }}
              onClick={handleAddRoutine}
              disabled={routineName.length === 0}
            >
              Tagesablauf hinzufügen
            </Button>
          </div>
        </Modal>

        <Modal
          width="450px"
          show={updateDailyRoutineModal}
          setShow={setUpdateDailyRoutineModal}
          heading={
            <TranslationMessage id="registration.dailyRoutine.popupHeading" />
          }
        >
          <h4>{selectedRoutine?.heading}</h4>

          <S.DailyRoutineInputContainer>
            <S.DailyRoutineInputLabel>
              <TranslationMessage id={"input.label.getup"} />
            </S.DailyRoutineInputLabel>
            <TimePicker
              clearable
              ampm={false}
              value={updateRoutineGetup?.time}
              onChange={(time) => {
                handleUpdateTimeChange(time, "input.label.getup");
              }}
            />
          </S.DailyRoutineInputContainer>
          <S.DailyRoutineInputContainer>
            <S.DailyRoutineInputLabel>
              <TranslationMessage id={"input.label.lunchTime"} />
            </S.DailyRoutineInputLabel>
            <TimePicker
              ampm={false}
              value={updateRoutineLunchTime?.time}
              onChange={(time) => {
                handleUpdateTimeChange(time, "input.label.lunchTime");
              }}
            />
          </S.DailyRoutineInputContainer>
          <S.DailyRoutineInputContainer>
            <S.DailyRoutineInputLabel>
              <TranslationMessage id={"input.label.workoutTime"} />
            </S.DailyRoutineInputLabel>
            <TimePicker
              ampm={false}
              value={updateRoutineWorkoutTime?.time}
              onChange={(time) => {
                handleUpdateTimeChange(time, "input.label.workoutTime");
              }}
            />
          </S.DailyRoutineInputContainer>
          <S.DailyRoutineInputContainer>
            <S.DailyRoutineInputLabel>
              <TranslationMessage id={"input.label.bedTime"} />
            </S.DailyRoutineInputLabel>
            <TimePicker
              ampm={false}
              value={updateRoutineBedTime?.time}
              onChange={(time) => {
                handleUpdateTimeChange(time, "input.label.bedTime");
              }}
            />
          </S.DailyRoutineInputContainer>
          <Button
            color="rgba(46, 207, 150, .2)"
            gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
            margin="30px auto 0px"
            style={{ fontSize: "16px" }}
            onClick={() => handleUpdateRoutine(selectedRoutine)}
          >
            <TranslationMessage id="button.updateDailyRoutine" />
          </Button>
        </Modal>
      </S.OnBoardingPage>
    </>
  );
};

export default DailyRoutine;
