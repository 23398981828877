import styled from "styled-components";
import {
  MainDashboardContainer,
  MainDashboardTitle,
  MainDashboardSubTitle,
} from "pages/MainDashboard/mainDashboard.styles";
import {
  ProfileDropdownOption,
  ProfileDropdownOptionContainer,
} from "components/profileDropIcon/profileDropIcon.styles";

export const MainCustomerContainer = styled(MainDashboardContainer)`
  .customer-table-wrap {
    table {
      table-layout: fixed;
      tr {
        th {
          &:first-child {
            /* width: 80px; */
          }
          &:nth-child(4) {
            /* width: 180px; */
          }
        }
      }
    }
  }
  table {
    table-layout: fixed;
    tr {
      th {
      }
      td {
        &:first-child {
          width: 80px;
        }
        &:nth-child(4) {
          width: 180px;
        }
      }
    }
  }
`;

export const ContainerBox = styled.div`
  border-radius: 8px;
  background: white;
  box-shadow: 0px 2px 5px #0000001a;
  /* width: calc(100% - 4rem); */
  margin: 0 auto;
`;

export const ContainerBoxWithPadding = styled.div`
  padding: 1.5rem;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;

export const Heading = styled.h4`
  margin: 0 0 0.8rem 0;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #142c1f;
  font-weight: 500;
`;

export const SubHeading = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #818d88;
  font-weight: 300;
`;

export const Header = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;
export const PaddingBox = styled.div`
  padding: 1.5rem;
`;

export const Container = styled.div`
  /* display: grid;
  grid-template-columns: 300px 1fr; */
  display: flex;
`;

export const CustomersListContainer = styled.div`
`;

export const MainCustomerHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const abc = styled.div``;
export const MainCustomerHeading = styled(MainDashboardTitle)``;

export const MainCustomerSubHeading = styled(MainDashboardSubTitle)``;

export const MainCustomerDropDownIcon = styled.span`
  font-size: 30px;
  line-height: 30px;
  margin: 0 10px;
  cursor: pointer;
  position: relative;
`;

export const MainCustomersDropdownOptionContainer = styled(
  ProfileDropdownOptionContainer
)`
  position: absolute;
  bottom: -45px;
  right: -10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 13px 80px #00000033;
  padding: 0 20px;
  border-radius: 8px;
  z-index: 500;
`;
export const MainCustomersDropdownOption = styled(ProfileDropdownOption)`
  width: 155px;
`;
export const MainContainerInner = styled.div`
  margin: 0 0 0 1.8rem;
`;

export const DotsBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;

  &.green {
    background: #2ecf96;
  }

  &.yellow {
    background: #d87845;
  }
`;
