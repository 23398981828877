import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { renderValueInGerman } from "configs/utils";

import Button from "components/formComponents/button/Button";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";
import TranslationMessage from "configs/translations/TranslationMessage";
import Input from "components/formComponents/input/Input";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import BasicIcon from "assets/images/Icon-foodiary-coaching-basic.svg";
import Check from "assets/images/check.svg";
import CheckCircle from "assets/images/checkcircle.svg";
import ArrowLeft from "assets/images/arrowLeft.svg";

import { SidebarSteps } from "../data";

import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as S from "../OnBoardingPartThree.styles";

const UserSubscriptions = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState("subscriptionPro");
  const [isClose, setIsClose] = useState(false);

  const [ proMonthlyPrice, setProMontlyPrice ] = useState(150)
  const [ plusMontlyPrice, setPlusMontlyPrice ] = useState(50)

  const [selectCardsData, setSelectCardsData] = useState([]);

  const Euro = new Intl.NumberFormat(
    'en-DE',
    { style: 'currency', currency: 'EUR' }
  )

  const [CardsData, setCardsData] = useState([
    {
      id: "subscriptionBasic",
      img: BasicIcon,
      title: "Foodiary App BASIC",
      description:
        "Fühlst du dich bereit, sodass du deine Ernährungsumstellung selbstständig starten kannst und dein Ziel erreichen?",
    },
  ]);
  const [proData, setproData] = useState([
    {
      img: Check,
      subheading: "3 Monate",
      description: "persönliche Ernährungsberatung",
    },

    {
      img: Check,
      subheading: "bis zu 150,00 €",
      description: "Kostenrückerstattung",
    },
    {
      img: Check,
      subheading: "3 Monate",
      description: "Smarte Ernährungsplaner App",
    },
    {
      img: Check,
      description: "Wöchentliche Gruppencalls und persönliche Betreuung",
    },
    {
      img: Check,
      subheading: "3 Monate",
      description: "Digitale Academy mit Online Kurs",
    },
    {
      img: Check,
      description: "Ebooks, Lernvideos, Bibliothek uvm.",
    },
  ]);
  const [plusData, setplusData] = useState([
    {
      img: Check,
      subheading: "3 Monate",
      description: "Smarte Ernährungsplaner App",
    },
    {
      img: Check,
      subheading: "3 Monate",
      description: "Digitale Academy mit Online Kurs",
    },
    {
      img: Check,
      subheading: "bis zu 100%",
      description: "Kostenrückerstattung",
    },
  ]);

  const [DiscountValue, setDiscountValue] = useState(150);

  const allRefunds = useSelector((state) => state.nutrition.getAllRefunds);

  const isAllRefundsLoading = useSelector(
    (state) => state.nutrition.isAllRefundsLoading
  );

  useEffect(() => {
    getAllRefunds();
  }, []);

  useEffect(() => {
    setSelectCardsData([
      {
        h1: "Foodiary Coaching PRO",
        id: "subscriptionPro",
        h2: `Ab ${Euro.format(proMonthlyPrice).replace('€', '')} € pro Monat`,
        p: "3 Monate Laufzeit",
        type: "pro",
      },
      {
        h1: "Foodiary Coaching PLUS",
        id: "subscriptionPlus",
        h2: `${Euro.format(plusMontlyPrice).replace('€', '')} € pro Monat`,
        p: "3 Monate Laufzeit",
        type: "plus",
      }
    ])

  }, [ proMonthlyPrice, plusMontlyPrice ])

  const getAllRefunds = () => {
    dispatch(
      NutritionActions.getAllRefundsRequest({
        pyload: null,
        callback: (res) => {},
      })
    );
  };

  const subscriptionCards = ({ card, index }) => {
    return card.type === "pro" ? (
      <>
        <S.SubscriptionCoachingProHeading>
          <h1 className="text-size-20-28 text-dark-1">{card.h1}</h1>
          <h2 className="text-size-16-24 text-green-light">{card.h2}</h2>
          <p className="text-size-14-24 text-dark-1">{card.p}</p>
        </S.SubscriptionCoachingProHeading>
        <S.SubscriptionCoachingContent>
          <ul>
            {(card.type === "pro" ? proData : plusData).map((itm, index2) => (
              <li className="items_data" key={index2}>
                <img src={itm.img} alt="" />
                <p className="text-size-16-24 font-rubik-light">
                  <span className="font-rubik-medium">{itm.subheading} </span>
                  {itm.description}
                </p>
              </li>
            ))}
          </ul>
          {/* <S.SubscriptionCoachingbuttons>
            <a href="#" className="button_pro">
              <img src={CheckCircle} alt="" />
              <span>Ausgewählt</span>
            </a>
          </S.SubscriptionCoachingbuttons> */}
        </S.SubscriptionCoachingContent>
      </>
    ) : (
      <>
        <S.SubscriptionCoachingPlusHeading>
          <h1 className="text-size-20-28 text-dark-1">{card.h1}</h1>
          <h2 className="text-size-16-24 text-dark-1">{card.h2}</h2>
          <p className="text-size-14-24 text-light-grey">{card.p}</p>
        </S.SubscriptionCoachingPlusHeading>
        <S.SubscriptionCoachingContent>
          <ul>
            {(card.type === "pro" ? proData : plusData).map((itm, index2) => (
              <li className="items_data" key={index2}>
                <img src={itm.img} alt="" />
                <p className="text-size-16-24 font-rubik-light">
                  <span className="font-rubik-medium">{itm.subheading} </span>
                  {itm.description}
                </p>
              </li>
            ))}
          </ul>
          {/* <S.SubscriptionCoachingbuttons>
            <a href="#" className="button_pro">
              <img src={CheckCircle} alt="" />
              <span>Auswählen</span>
            </a>
          </S.SubscriptionCoachingbuttons> */}
        </S.SubscriptionCoachingContent>
      </>
    );
  };

  const handleSelectCompany = (value) => {
    if (!value) return;
    let v = (+value * 150) / 100;
    setDiscountValue(v);
    localStorage.setItem("discount", v);
    setProMontlyPrice(((450 - (150 / 100 * value)) / 3).toFixed(2))
    setPlusMontlyPrice(((150 - (150 / 100 * value)) / 3).toFixed(2))
  };

  const handleBack = () => {
    history.push("/onboarding/healthscore/premium-coaching-features");
  };

  const handleNext = () => {
    let url =
      selectedCard === "subscriptionPro"
        ? "/onboarding/subscriptions/details?user=Pro"
        : selectedCard === "subscriptionPlus"
        ? "/onboarding/subscriptions/details?user=Plus"
        : "";
    history.push(url);
  };

  const handleClose = () => {
    const userData = JSON.parse(localStorage.getItem("RegistrationData"));

    dispatch(
      SupplementsActions.deleteMailChimpUserRequest({
        formValues: {
          sex: userData?.sex,
          mobile: userData?.mobile,
          birthday: userData?.birthday,
          email: userData?.email,
          prename: userData?.prename,
          surname: userData?.surname,
          type: "web",
        },
        func: (res) => {
          console.log("Close", res);
        },
      })
    );

    history.push("/dashboard/main");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={5}/>
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Start
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Gemeinsam gesunde Gewohnheiten aufbauen.
                  </h6>
                </div>
              </div>
              <div className="actions d-flex align-items-center gap-2">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>

                <button className="btn-close" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="health-score-user-subscriptions-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium mt-0">
                  Wie darf ich dich unterstützen?
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  3 Monate persönliche Unterstützung beim Aufbau deiner gesunden
                  Gewohnheiten.
                </p>

                <div className="form-group-">
                  <div className="form-group">
                    <label className="text-size-14-28 text-dark-1 font-rubik-medium mb-0">
                      Krankenkasse
                    </label>
                    {/* <Input
                      onChange={(e) => {}}
                      value=""
                      placeholder="Bitte auswählen"
                    /> */}
                    <S.SubscriptionProDrop>
                      <select
                        onChange={(e) => handleSelectCompany(e.target.value)}
                      >
                        <option value="" key={"00"}>Krankenkasse auswählen</option>
                        {allRefunds?.getAllRefunds.map((company, i) => (
                          <option value={company?.refund_amount} key={i}>
                            {company?.krankenkasse}
                          </option>
                        ))}
                      </select>
                    </S.SubscriptionProDrop>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-1">
                    <p className="text-size-16-24 text-dark-2 font-rubik-light my-0">
                      werden zurückerstattet
                    </p>

                    <h6 className="text-size-20-24 text-green my-0">
                      {renderValueInGerman(+DiscountValue)}
                    </h6>
                  </div>
                </div>

                <p className="text-size-14-28 text-dark-1 font-rubik-medium">
                  Beratungsgebühr:
                </p>

                <div className="user-subscriptions">
                  {isClose && (
                    <S.SubscriptionCardContainer>
                      {isLoading && (
                        <>
                          <br />
                          <br />
                          <br />
                          <br />
                          <div className="text-center">
                            <div className="loader mx-auto"></div>
                          </div>
                          <br />
                          <br />
                          <br />
                          <br />
                        </>
                      )}
                      {!isLoading && (
                        <>
                          {CardsData.map((card) => (
                            <S.SubscriptionSimpleCard
                              key={card.id + "cardd"}
                              onClick={() => setSelectedCard(card.id)}
                              style={{
                                border:
                                  card.id === selectedCard
                                    ? "3px solid #2ECF96"
                                    : "3px solid white",
                              }}
                            >
                              <img src={card.img} alt="" />
                              <h6>{card.title}</h6>
                              <p>{card.description}</p>
                            </S.SubscriptionSimpleCard>
                          ))}
                        </>
                      )}
                    </S.SubscriptionCardContainer>
                  )}
                  {!isClose && (
                    <S.SubscriptionCardContainer>
                      <S.SubscriptionCoachingContainer>
                        {selectCardsData.map((card, index) => (
                          <>
                            {card.type === "pro" ? (
                              <S.SubscriptionCoachingProContainer
                                key={index + "pro"}
                                onClick={() => setSelectedCard(card.id)}
                                style={{
                                  border:
                                    card.id === selectedCard
                                      ? "2px solid #2ECF96"
                                      : "2px solid #e2e4e9",
                                }}
                              >
                                {subscriptionCards({ card, index })}
                              </S.SubscriptionCoachingProContainer>
                            ) : (
                              <S.SubscriptionCoachingPlusContainer
                              key={index + "plus"}
                                onClick={() => setSelectedCard(card.id)}
                                style={{
                                  border:
                                    card.id === selectedCard
                                      ? "2px solid #2ECF96"
                                      : "2px solid #e2e4e9",
                                }}
                              >
                                {subscriptionCards({ card, index })}
                              </S.SubscriptionCoachingPlusContainer>
                            )}
                          </>
                        ))}
                      </S.SubscriptionCoachingContainer>
                    </S.SubscriptionCardContainer>
                  )}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
        <br />
        <br />
        <br />
      </S.OnBoardingPage>
    </>
  );
};

export default UserSubscriptions;
