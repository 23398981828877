import styled from "styled-components";
import {
  MainDashboardContainer,
  MainDashboardTitle,
  MainDashboardSubTitle,
} from "pages/MainDashboard/mainDashboard.styles";
import { ProfileDropIconImageContainer } from "components/profileDropIcon/profileDropIcon.styles";
import {
  ClientDetailsContentHeading,
  ClientDetailsContentSubHeading,
  ClientDetailsOverviewInputContainer,
  ClientDetailsOverviewInputLabel,
} from "components/clientDetails/clientDetails.styles";

export const TrainerProfileDetailsOverallContainer = styled.div`
  position: relative;

  padding: 1.5rem;
`;

export const PaddingBox = styled.div`
  padding: 1.5rem;
`;

export const TrainerProfileDetailsHeading = styled(MainDashboardTitle)``;

export const TrainerProfileDetailsSubHeading = styled(MainDashboardSubTitle)``;

export const TrainerProfileDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  /* background-color: #fff; */
  gap: 1rem;
`;

// Left Container

export const DataRow = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h6 {
    font-size: 1rem;
    font-weight: 400;
    color: #142c1f;
    margin: 0;
  }

  p {
    font-size: 1rem;
    font-weight: 300;
    color: #818d88;
    margin-bottom: 0;
    margin-top: 0.4rem;
  }
`;

export const TrainerProfileDetailsLeftContainer = styled.div`
  border-radius: 4px;
  background-color: #f5f5f5;
  position: relative;
`;

export const TrainerProfileDetailsImageContainer = styled(
  ProfileDropIconImageContainer
)`
  width: 168px;
  height: 168px;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    background: white;
    border-radius: 4px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 10px 5px 10px;
    cursor: pointer;
    font-size: 12px;
    max-width: 12ch;
    overflow-x: clip;
    text-overflow: ellipsis;
    position: absolute;
    top: -20px;
    left: 50%;
    box-shadow: 0px -2px 20px 0px #d6d6d6a6;
    transform: translateX(-50%);
  }
  &:not(:hover) {
    div {
      display: none;
    }
  }
  div {
    background: #0000008f;
    border-radius: 4px;
    padding: 6px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    width: 40px;
    box-shadow: 0px 0 20px 3px #d6d6d6a6;
    img {
      width: 22px;
      height: 22px;
    }
  }
`;

// Section 1
export const TrainerProfileDetailsRightSection1Container = styled.div`
  width: 100%;
  background-color: white;
  position: relative;
`;

export const TrainerProfileDetailsSectionHeading = styled(
  ClientDetailsContentHeading
)`
  margin: 0px;
  line-height: 24px;
`;

export const TrainerProfileDetailsSectionSubHeading = styled(
  ClientDetailsContentSubHeading
)`
  font-size: 1rem;
  color: #818d88;
  font-weight: 300;
  margin: 0.5rem 0 0;
  line-height: 19px;
`;

export const FormGrid = styled.div`

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  padding: 0;
  /* input, */
  .react-select__control {
    border: 1px solid #dddddd !important;
    border-radius: 8px;
    width: 100% !important;
    background-color: transparent !important;
  }
  .react-select__value-container {
    height: 40px;
  }
`;

export const TrainerProfileDetailsSection1InputContainer = styled.div`

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 10px;

  padding: 0;
  input,
  .react-select__control {
    border: 1px solid #dddddd !important;
    border-radius: 8px;
    width: 100% !important;
    background-color: transparent !important;
  }
  .react-select__value-container {
    height: 40px;
  }

  & > :first-child {
    grid-column: span 2;
    width: 70%;
  }

  & > :nth-child(2) {
    grid-column: span 2;
    width: 50%;
  }

  & > :nth-child(3), & > :nth-child(4) {
    grid-column: span 1;
    // width: 50%
  }

`;

export const SectionHeading = styled.h6`
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
`;

export const TrainerProfileDetailsSection1InputLabel = styled(
  ClientDetailsOverviewInputLabel
)`
  margin: 0 0 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  textarea {
    border: 1px solid #dddddd;
    border-radius: 8px;
    width: 100% !important;
    background-color: transparent;
  }

  img {
    position: relative;
  }
`;

// Section 2
export const TrainerProfileDetailsRightSection2Container = styled(
  TrainerProfileDetailsRightSection1Container
)`
  margin: 0;
`;

export const TrainerProfileDetailsInnerContainer = styled.div`
  padding: 1.5rem;
  border-radius: 10px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 10px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;

export const Header = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;

export const TrainerProfileDetailsSubtitlesBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const TrainerProfileDetailsSubtitle = styled.span`
  background-color: transparent;
  color: #a5b5af;
  border: 1px solid #e2e4e9;
  font-size: 1rem;
  border-radius: 14px;
  padding: 9.5px 12px;
  cursor: pointer;
  font-weight: 400;

  ${({ active }) =>
    active &&
    `
  background-color: #2ecf96;
  color: white;
  `}
`;

export const TrainerPracticalExperienceSubtitle = styled.span`
  width: 100px;
  background-color: transparent;
  color: #a5b5af;
  border: 1px solid #e2e4e9;
  font-size: 1rem;
  text-align: center;
  border-radius: 14px;
  padding: 9.5px 12px;
  cursor: pointer;
  font-family: "RubikRegular";

  ${({ active }) =>
    active &&
    `
    border: 1px solid #48D4A0;
  `}
`;

export const MediaUploadBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  flex-wrap: wrap;
  flex: 1;
  gap: 1rem;
`;

export const MediaUploadItem = styled.div`
  /* width: calc(50% - 5px); */
  height: 300px;
  position: relative;

  .cw_cp_picture_2-image {
    width: 100% !important;
    height: 100% !important;
  }
`;

export const MediaUploadItemHorizontal = styled.div`
  width: 100%;
`;

export const UploadHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  .star-ratings {
    min-width: 11.875rem;
  }
`;

export const MediaUploadLabel = styled(ClientDetailsOverviewInputLabel)`
  margin: 0 0 0.85rem;
`;

export const MediaUpload = styled.div`
  background: #f8f8f8;
  border-radius: 4px;
  padding: 6px 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  height: 150px;
  width: 100%;

  img {
    width: 32px;
    height: 32px;
  }

  span {
    margin-top: 23px;
    font-size: 14px;
    color: #a5b5af;
  }
`;
export const MediaUploadHorizontal = styled.div`
  background: transparent;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  width: 100%;
  position: relative;

  .upload-icon {
    width: 2.8125rem;
    height: 2.8125rem;
    box-shadow: 0px 1px 2px #e4e5e73d;
    border: 1px solid #e2e4e9;
    border-radius: 50%;

    img {
      width: 1.8125rem;
      height: 1.8125rem;
    }
  }

  .upload-box-content {
    p {
      color: #818d88;
      font-size: 0.9rem;
      font-weight: 300;
      margin: 0 0 0.25rem;
      strong {
        color: #2ecf96;
        font-weight: 400;
      }
    }
  }

  span {
    color: #818d88;
    font-weight: 300;
    font-size: 0.8rem;
  }

  .upload-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const MediaPlaceholder = styled.div`
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #e4e5e73d;
  /* border: 1px solid #e2e4e9; */
  border-radius: 15px;
  height: calc(100% - 2.375rem);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 12.5rem;

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 15px;
  }

  &.bordered {
    border: 1px solid #e2e4e9;
    cursor: pointer;
  }

  .placeholer-content {
    .upload-sq-icon {
      width: 2.6875rem;
      height: 2.375rem;
      box-shadow: 0px 1px 2px #5258660f;
      border: 1px solid #e2e4e9;
      border-radius: 6px;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 1rem;
        height: 1rem;
        object-fit: fill;
        border-radius: 0;
      }
    }
    p {
      color: #818d88;
      font-size: 0.9rem;
      font-weight: 300;
      margin: 0 0 0.25rem;
      strong {
        color: #2ecf96;
        font-weight: 400;
      }
    }
    span {
      color: #818d88;
      font-weight: 300;
      font-size: 0.8rem;
    }
  }

  .upload-icon {
    background: white;
    border-radius: 4px;
    width: fit-content;
    padding: 8px 10px 5px 10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    box-shadow: 0px 13px 20px #d6d6d659;
    img {
      width: 1rem;
      height: 1rem;
      border-radius: 0;
    }
  }
`;

export const FileName = styled.div`
  background: white;
  border-radius: 4px;
  width: fit-content;
  max-width: 190px;
  overflow-wrap: break-word;
  padding: 8px 10px 5px 10px;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 0.94rem;
  color: #44554c;
  font-weight: 400;
  border: 1px solid #e2e4e9;
`;

export const VideoPlaceholder = styled.div`
  position: relative;
  /* height: 15.625rem; */
  border-radius: 15px;
  height: calc(100% - 2.375rem);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 12.5rem;

  .player {
    width: 100% !important;
    height: 100% !important;

    video {
      object-fit: cover;
      border-radius: 15px;
    }
  }

  &.bordered {
    border: 1px solid #e2e4e9;
    cursor: pointer;
  }

  .placeholer-content {
    .upload-sq-icon {
      width: 43px;
      height: 38px;
      box-shadow: 0px 1px 2px #5258660f;
      border: 1px solid #e2e4e9;
      border-radius: 6px;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 1rem;
        height: 1rem;
        object-fit: fill;
        border-radius: 0;
      }
    }
    p {
      color: #818d88;
      font-size: 0.9rem;
      font-weight: 300;
      margin: 0 0 0.25rem;
      strong {
        color: #2ecf96;
        font-weight: 400;
      }
    }
    span {
      color: #818d88;
      font-weight: 300;
      font-size: 0.8rem;
    }
  }

  .upload {
    background: white;
    border-radius: 4px;
    width: fit-content;
    padding: 8px 10px 5px 10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;

    img {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const OrangeButton = styled.button`
  background: #2ecf96;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  width: 200px;
  height: 38px;
  color: white;
  font-size: 15px;

  &.disabled {
    background: transparent linear-gradient(99deg, #2ecf96 0%, #1c9d87 100%);
    color: #fff;
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

export const ToggleSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  /* background: #2ecf961a; */
  border-radius: 4px;
  height: 83px;
  padding: 0px 1.5rem;
  justify-content: space-between;
`;

export const ToggleSwitch = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2ecf96;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2ecf96;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2ecf96;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .disabled {
    .slider:before {
      background-color: #a3a3a3 !important;
    }

    input:checked + .slider {
      background-color: #e2e2e2 !important;
    }
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const ToggleSwitchLabelOne = styled.div`
  color: #111614;
  font-size: 18px;
  font-weight: 500;

  ${({ active }) =>
    active &&
    `
      color: #2ecf96;
      font-weight: 500;
    `}
`;

export const ToggleSwitchLabelTwo = styled.div`
  color: #a5b5af;
  font-size: 16px;
  display: flex;
  flex-direction: row-reverse;
  font-size: 16px;

  ${({ active }) =>
    active &&
    `
      color: #44554C;
      font-weight: 500;
      margin-right:.7rem;
    `}
`;

export const InlineControls = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const FormControl = styled.div`
  position: relative;

  img {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 12px;
    transform: translateY(-20px);
  }
`;

export const ActionsBar = styled.div`
  background: #f8f8f8;
  border: 1px solid #eeeeee;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 11;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 20px;
  box-sizing: border-box;
  gap: 1rem;
`;

export const CreateWebsiteActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 20px 0 0;
  box-sizing: border-box;
  gap: 1rem;
`;

export const DiscardButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  height: 45px;
  color: #adbcb6;
  cursor: pointer;
  font-size: 16px;
`;

export const ReadOnlyValue = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-family: "RubikRegular" !important;
  word-break: break-all;
  text-align: left;
  user-select: text;
  background-color: #44554c;
  padding: 4px 8px;
  border-radius: 4px;
`;
export const ValidationText = styled.p`
  color: red;
  font-size: 16px;
  font-weight: bold;
  font-weight: 400;
  word-break: break-word;
  text-align: left;
  user-select: text;
  background-color: white;
  padding: 0.25rem;
  border-radius: 0.1875rem;
  position: absolute;
  bottom: 0;
  left: 1rem;
  z-index: 2;
`;
export const StatusBox = styled.div`
  margin-bottom: 10px;
`;
export const StatusBoxHeading = styled.div`
  color: #44554c;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
`;
export const StatusChip = styled.div`
  background: #2ecf961a;
  border-radius: 18px;
  padding: 0.5rem 1rem;
  color: #2ecf96;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  width: max-content;

  span {
    width: 10px;
    height: 10px;
    background: #2ecf96;
    display: flex;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
`;

export const InvoicesCard = styled.div`
  background: #ffffff 0% 0%;
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 0.625rem;
  padding: 1.5rem;
`;

export const Title = styled.h4`
  color: #142c1f;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin: 0;
`;

export const InvoiceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.9rem 1.5rem;

  &.header {
    background: #f6f8fa;
    border-radius: 10px;
    span {
      color: #535865;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
  }
  &:not(.header) {
    span {
      color: #44554c;
      font-weight: 300;
      display: flex;
      align-items: center;
      gap: 0.4rem;
    }

    .date {
      font-size: 1.125rem;
      line-height: 1.375rem;
      max-width: 140px;
      width: 100%;
      color: #141414;
    }

    .pdf {
      font-size: 0.875rem;
      line-height: 1.0625rem;
      font-weight: 500;
      border-radius: 0.3125rem;
      cursor: pointer;
      color: #44554c;

      img {
        width: 1.8rem;
      }
    }

    .download {
      font-size: 1.0625rem;
      line-height: 1.25rem;
      cursor: pointer;
    }
  }
`;

export const TrainerProfileCoachCardQualificationContainer = styled.div`
  margin: 24px 0px;
  & > :nth-child(2) {
    margin-top: 16px !important;
  }
  & > :nth-child(3) {
    margin-top: 0px !important;
  }
`;

export const TrainerProfileCoachCardQualificationRow = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const TrainerProfileCoachCardQualificationYear = styled.div`
 width: 100px;
 & input {
  background-color: white !important;
  }
 ${({ active }) =>
  active &&
  `
    & input {
      background-color: white !important;
    }
  `}
`;

export const TrainerProfileCoachCardQualificationDegree = styled.div`
 flex: 1;
 & input {
  background-color: white !important;
  }
 ${({ active }) =>
  active &&
  `
    & input {
      background-color: white !important;
    }
  `}
  ${({ selected }) =>
    selected &&
    `
      & input {
        border-color: #2ecf96 !important;
      }
    `}
`;

export const TrainerProfileCoachCardQualificationButton = styled.div`
  
`;

export const TrainerProfileCoachCardRemoveButton = styled.div`
  height: 40px;
  padding: 10px 10px 2px;
  border-radius: 10px;
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
  border: solid 1px #E2E4E9;
  & svg {
    font-size: 18px;
    color: #A5B5AF;
}
`