import * as S from "./partnership.styles"
import InnerSideNav from "../components/InnerSideNav/InnerSide"
import { MENUS, EXPERTS_DATA } from "./data"

const PartnershipsExperts = () => {
  const renderedCards = EXPERTS_DATA && EXPERTS_DATA.length
    ? (
      <S.Grid>
        {EXPERTS_DATA.map(item => (
          <a key={item.id} className='link' href={item.link} target='_blank'>
            <S.PartnershipCard>
              <S.Image image={item.image} />
              <div className="content">
                <h6>{item.title}</h6>
                <p>{item.description}</p>
              </div>
            </S.PartnershipCard>
          </a>
        ))}
      </S.Grid>
    )
    : ( <p>No experts have been added yet.</p> )

  return (
    <>
      <div className="second-sidebar-wrapper">
        <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
      </div>
      <S.Wrapper className="second-sidebar-content">
        <S.MCard>
          <S.ClientDetailsContentHeading>
            Expertenpartner
          </S.ClientDetailsContentHeading>
          <S.ClientDetailsContentSubHeading>
            Sorgfältig ausgewählte Partner, die dir beim Wachstum helfen
          </S.ClientDetailsContentSubHeading>

          <br />
          <S.Divider />
          <br />

          {renderedCards}

        </S.MCard>
      </S.Wrapper>
    </>
  )
}

export default PartnershipsExperts
