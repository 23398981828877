import * as S from "./newDashboard.styles"
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import UserImg from "assets/images/new/Tim-Foodiary.jpg"
import Tick from "assets/images/tick.png"
import CopyIcon from "assets/images/copy-icon-black.svg"
import { ToastContainer, toast } from "react-toastify";
import { SidebarSocialIcons } from "../menu";

function RightFixed ({
  trainerProfileData,
  isTrainerNewsLoading,
  trainerNews,
  formatNewsDate
}) {

  const promoLink = trainerProfileData.referral_code

  const copyPromoLink = textToCopy => {
    navigator.clipboard.writeText(textToCopy)
    toast("📃 Erfolgreich kopiert.")
  }

  const sidebarQuickLinks = (
    SidebarSocialIcons.map((item, i) => (
      <a href={item.url} target="_blank" key={i}>
        <img src={item.img} alt="social-icon" />
      </a>
    ))
  )

  return (
    <>
      <ToastContainer />
        <S.DashboardWrapRight>
          <S.QuickLinks>
            <h3>Quick Links</h3>
            <div className="sidebar-quick-links">
            {sidebarQuickLinks}
            </div>
          </S.QuickLinks>
            <S.CardBox>
                <S.SelfCardHead>
                  <h3>Ansprechpartner</h3>
                  <S.SocialIcons>
                    <a
                      href="https://wa.me/15735992532"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WhatsAppIcon />
                    </a>
                    <a href="mailto:tim@foodiary.app">
                      <EmailOutlinedIcon />
                    </a>
                  </S.SocialIcons>
                </S.SelfCardHead>

                <S.ProfileBox>
                  <img alt="Profile" src={UserImg} />
                  <S.InnerMenuInfo>
                    <h5>Tim Vicariesmann</h5>
                    <p>Key Account Manager</p>
                  </S.InnerMenuInfo>
                </S.ProfileBox>
            </S.CardBox>

            <S.CardBox>
              <S.UrlBox>
                <h5>Wichtige URLs</h5>
                <S.UrlBoxWrap>
                  <S.URlBoxInner>
                    <span>WEBSITE - ERSTGESPRÄCH URL</span>
                    <div className="url-wrapper">
                      <a href={trainerProfileData?.first_meeting_url} target="_blank" rel="noreferrer">
                        {trainerProfileData?.first_meeting_url}
                      </a>
                      <span className="promo-copy-icon" onClick={() => copyPromoLink(trainerProfileData?.first_meeting_url)}>
                        <img src={CopyIcon} alt="" />
                      </span>
                    </div>
                  </S.URlBoxInner>

                  <S.URlBoxInner>
                    <span>Deine App URL</span>
                    <div className="url-wrapper">
                      <a href={trainerProfileData?.werbelink_foodiary_app} target="_blank" rel="noreferrer">
                        {trainerProfileData?.werbelink_foodiary_app}
                      </a>
                      <span className="promo-copy-icon" onClick={() => copyPromoLink(trainerProfileData?.werbelink_foodiary_app)}>
                        <img src={CopyIcon} alt="" />
                      </span>
                    </div>
                  </S.URlBoxInner>
                </S.UrlBoxWrap>
              </S.UrlBox>

              <S.Divider />

              <S.NewsWrap>
                <h5>News</h5>

                {isTrainerNewsLoading && (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="text-center">
                      <div className="loader mx-auto"></div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}

                {!isTrainerNewsLoading && (
                  <S.NewsWrapInner>
                    {trainerNews?.getAllTrainerNews?.data.map((n) => (
                      <S.NewsBox>
                        <a href={n?.url} target="_blank" rel="noreferrer">
                          {n?.title}
                        </a>
                        <p>{n?.text}</p>
                        <span>{formatNewsDate(n?.date)}</span>
                      </S.NewsBox>
                    ))}
                  </S.NewsWrapInner>
                )}
              </S.NewsWrap>
            </S.CardBox>
            <S.CardBox>
              <S.PromoSection>
                <h5>Empfehle Foodiary weiter</h5>
                <p>Bei der Weiterempfehlung von Foodiary investieren wir lieber in euch statt in Google und Facebook. Aus diesem Grund vergüten wir jede Weiterempfehlung mit 300€.</p>
                <S.PromoList>
                  <div>
                    <span><img src={Tick} /></span>
                    <span>Link kopieren</span>
                  </div>
                  <div>
                    <span><img src={Tick} /></span>
                    <span>In unser Webinar einladen</span>
                  </div>
                  <div>
                    <span><img src={Tick} /></span>
                    <span>300€ sichern</span>
                  </div>
                </S.PromoList>
                <S.PromoCopyLink>
                  <span className="url-placeholder">{promoLink}</span>
                  <span className="promo-copy-icon" onClick={() => copyPromoLink(promoLink)} >
                    <img src={CopyIcon} alt="" />
                  </span>
                </S.PromoCopyLink>
              </S.PromoSection>
            </S.CardBox>
        </S.DashboardWrapRight>
    </>
  )
}

export default RightFixed
