import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";

import ErrorMessageCustom from "components/formComponents/errorMessage/ErrorMessage";
import Modal from "components/modal/Modal";
import Input from "components/formComponents/input/Input";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";

import CrossIcon from "assets/images/cross.svg";
import PDFIcon from "assets/images/pdf.svg";
import FileIcon from "assets/images/file-icon.png";
import UploadIcon from "assets/images/upload.svg";
import CongratulationsHands from "assets/images/Loading-Success.png";
import Sanduhr from "assets/images/Loading-Sanduhr.png";

import { MENUS } from "./SideMenu";
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as S from "./MarketingPortal.styles";

const CreateNewPartner = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const contractFileInputRef = useRef(null);
  const createPartnerFormRef = useRef();

  const [CompanyLogo, SetCompanyLogo] = useState(null);
  const [ContractFile, SetContractFile] = useState(null);
  const [IsPartnerCreatedModalOpen, SetIsPartnerCreatedModalOpen] =
    useState(false);
  const [IsPartnerCreatedSuccessfully, SetIsPartnerCreatedSuccessfully] =
    useState(false);

  const [IsPhoneValid, SetIsPhoneValid] = useState(false);
  const [PhoneNumber, SetPhoneNumber] = useState("");
  const [IsCreatePartnerLoading, SetIsCreatePartnerLoading] = useState(false);

  const trainerData = useSelector((state) => state.auth.trainerData);

  const isMarketingPortalCreateAffiliateLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalCreateAffiliateLoading
  );
  const isMarketingPortalAddAffiliateProgramLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalAddAffiliateProgramLoading
  );

  const isQrCodeLoading = useSelector(
    (state) => state.nutrition.isQrCodeLoading
  );

  const isCreatePartnerLoading = useSelector(
    (state) => state.nutrition.isCreatePartnerLoading
  );

  const validateContractFile = (value) => {
    if (!value) {
      return false;
    }

    const fileName = value.toLowerCase();
    const extension = fileName.split(".").pop();
    const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];

    return allowedExtensions.includes(extension);
  };

  const validateCompanyLogo = (value) => {
    if (!value) {
      return false;
    }

    const fileName = value.toLowerCase();
    const extension = fileName.split(".").pop();
    const allowedExtensions = ["jpg", "jpeg", "png"];

    return allowedExtensions.includes(extension);
  };

  const initialValues = {
    companyName: "",
    houseNumber: "",
    street: "",
    zipcode: "",
    leutenbach: "",
    contactPersonFirstName: "",
    contactPersonLastName: "",
    email: "",
    // phone: "vdsvdsvdsvds",
    website: "",
  };

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Erforderlich"),
    houseNumber: Yup.string().required("Erforderlich"),
    street: Yup.string().required("Erforderlich"),
    zipcode: Yup.string().required("Erforderlich"),
    leutenbach: Yup.string().required("Erforderlich"),
    contactPersonFirstName: Yup.string()
      .required("Erforderlich")
      .matches(
        /^[a-zA-Z0-9äöüÄÖÜß\s]+$/,
        "Bitte verwende nur Buchstaben und keine Sonderzeichen."
      ),
    contactPersonLastName: Yup.string()
      .required("Erforderlich")
      .matches(
        /^[a-zA-Z0-9äöüÄÖÜß\s]+$/,
        "Bitte verwende nur Buchstaben und keine Sonderzeichen."
      ),
    email: Yup.string()
      .email("E-Mail muss eine gültige Adresse sein")
      .required("Erforderlich"),
    // phone: Yup.string(),
    website: Yup.string().required("Erforderlich"),
    companyLogo: Yup.mixed(),
    contractFile: Yup.mixed()
      .required("Erforderlich")
      .test("fileType", "Invalid file type", validateContractFile),
  });

  // File type validation for companyLogo
  const companyLogoFileTypeValidation = Yup.object().shape({
    companyLogo: Yup.mixed().test(
      "fileType",
      "Invalid file type",
      validateCompanyLogo
    ),
  });

  // Merge companyLogoFileTypeValidation into the main validation schema conditionally
  validationSchema.shape.companyLogo = Yup.lazy((value) => {
    return value && value.length > 0
      ? companyLogoFileTypeValidation
      : Yup.mixed();
  });

  const createPartner = () => {
    if (createPartnerFormRef.current) {
      createPartnerFormRef.current.handleSubmit();
    }
  };

  const onFormSubmit = (values, { resetForm }) => {
    if (PhoneNumber.length === 0 || PhoneNumber.length > 25) {
      SetIsPhoneValid(false);
      return;
    }

    SetIsPartnerCreatedModalOpen(true);

    const formData = new FormData();
    formData.append("connectedCoachId", trainerData?.trainerID);
    formData.append("companyName", values.companyName);
    formData.append("street", values.street);
    formData.append("zipcode", values.zipcode);
    formData.append("houseNumber", values.houseNumber);
    formData.append("city", values.leutenbach);
    formData.append(
      "contact",
      `${values.contactPersonFirstName} ${values.contactPersonLastName}`
    );
    formData.append("email", values.email);
    formData.append("phone", PhoneNumber);
    formData.append("website", values.website);
    formData.append("status", "Partnerschaft aktiv");
    formData.append("contractUrl", ContractFile);
    formData.append("companylogo", CompanyLogo);

    console.log("formData", formData);

    dispatch(
      NutritionActions.marketingPortalCreateAffiliateRequest({
        payload: {
          firstname: values?.contactPersonFirstName,
          lastname: values?.contactPersonLastName,
          email: values?.email,
          company: {
            name: values.companyName,
            description: "Kooperationspartner",
          },
          address: {
            address: `${values.houseNumber} ${values.street}, ${values.leutenbach} ${values.zipcode}`,
            postal_code: values.zipcode,
            city: values.leutenbach,
          },
        },
        callback: (res) => {
          if (res instanceof Error) {
            SetIsPartnerCreatedModalOpen(false);
            toast("❌ Dein Werbepartner konnte nicht angelegt werden");
            return;
          }

          dispatch(
            NutritionActions.marketingPortalAddAffiliateRequest({
              payload: {
                affiliateId: res?.body?.id,
                programId:
                  `${trainerData?.prename}-${trainerData?.surname}-foodiary-coach-${trainerData?.FranchiseID}`.toLowerCase(),
              },
              callback: (res) => {
                if (res instanceof Error) {
                  SetIsPartnerCreatedModalOpen(false);
                  toast("❌ Dein Werbepartner konnte nicht angelegt werden");
                  return;
                }

                formData.append("partnerUrl", res?.body?.referral_link?.link);

                const QrCodeFormData = new FormData();
                QrCodeFormData.append("qr_url", res?.body?.referral_link?.link);
                QrCodeFormData.append(
                  "aws_filepath_name",
                  `Marketingportal/QRCode/${
                    trainerData?.FranchiseID
                  }/${values.companyName.split(" ").join("")}_${
                    trainerData?.FranchiseID
                  }.png`
                );

                dispatch(
                  NutritionActions.createQrCodeRequest({
                    formData: QrCodeFormData,
                    callback: (res) => {
                      formData.append("qrCodeUrl", res.path);

                      if (res instanceof Error) {
                        SetIsPartnerCreatedModalOpen(false);
                        toast(
                          "❌ Dein Werbepartner konnte nicht angelegt werden"
                        );
                        return;
                      }

                      SetIsCreatePartnerLoading(true);
                      axios
                        .post(
                          "https://app.foodiary.app/api/marketingportal/partner/create",
                          formData,
                          {
                            headers: {
                              "x-api-key":
                                "uEbwWdT5hhgikMjeSfOf5UwxnK9fPZRMveP5t3lFEyuR7rlKlixSs53wlrU9Vj9zsbnVCrlkrbCzcIap7hmjgjcGFPXcFNzELRb0dG3LrL2TWGkuKJH88QrCtLtsg0fw",
                              "Content-Type": "multipart/form-data",
                              Authorization:
                                "Bearer " + localStorage.getItem("token"),
                            },
                          }
                        )
                        .then((response) => {
                          SetIsPartnerCreatedSuccessfully(true);
                          SetIsCreatePartnerLoading(false);
                          toast("✅ Dein Werbepartner wurde angelegt");
                          resetForm();
                          SetCompanyLogo(null);
                          SetContractFile(null);
                        })
                        .catch((err) => {
                          SetIsPartnerCreatedModalOpen(false);
                          toast(
                            "❌ Dein Werbepartner konnte nicht angelegt werden"
                          );
                          return err;
                        });
                      // dispatch(
                      //   NutritionActions.marketingPortalCreatePartnerRequest({
                      //     payload: formData,
                      //     callback: (res) => {
                      //       console.log(
                      //         "marketingPortalCreatePartnerRequest----REs",
                      //         res
                      //       );
                      //     },
                      //   })
                      // );
                    },
                  })
                );
              },
            })
          );
        },
      })
    );
  };

  return (
    <>
      <ToastContainer />
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.PrintDataDetails className="bg-white">
            <S.TrainerProfileDetailsInnerContainer className="p-0">
              <S.Header>
                <div>
                  <S.TrainerProfileDetailsSectionHeading>
                    Neuen Werbepartner anlegen
                  </S.TrainerProfileDetailsSectionHeading>
                  <S.TrainerProfileDetailsSectionSubHeading className="my-0">
                    Trage die Daten aus dem erhaltenen und unterzeichneten
                    Vertrag ein.
                  </S.TrainerProfileDetailsSectionSubHeading>
                </div>
                <button className="btn-dark" onClick={() => createPartner()}>
                  Werbepartner speichern
                </button>
              </S.Header>
              <S.Divider />
            </S.TrainerProfileDetailsInnerContainer>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onFormSubmit}
              innerRef={createPartnerFormRef}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isValid,
                dirty,
                isSubmitting,
              }) => (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <div className="create-form partner">
                    <div className="partner-form">
                      <S.FormControl>
                        <S.TrainerProfileDetailsSection1InputLabel>
                          Firmenname
                        </S.TrainerProfileDetailsSection1InputLabel>
                        <Input
                          className="input-custom"
                          style={{ width: "100%" }}
                          placeholder={"Foodiary GmbH"}
                          name="companyName"
                          onChange={handleChange}
                          value={values.companyName}
                        />
                        {touched.companyName && errors.companyName && (
                          <ErrorMessageCustom error={errors.companyName} />
                        )}
                      </S.FormControl>

                      <div className="two-inputs-grid">
                        <S.FormControl>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            Nr
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            className="input-custom"
                            style={{ width: "100%" }}
                            placeholder={"9"}
                            name="houseNumber"
                            onChange={handleChange}
                            value={values.houseNumber}
                          />
                          {touched.houseNumber && errors.houseNumber && (
                            <ErrorMessageCustom error={errors.houseNumber} />
                          )}
                        </S.FormControl>
                        <S.FormControl>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            Strasse
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            className="input-custom"
                            style={{ width: "100%" }}
                            placeholder={"Talstraße"}
                            name="street"
                            onChange={handleChange}
                            value={values.street}
                          />

                          {touched.street && errors.street && (
                            <ErrorMessageCustom error={errors.street} />
                          )}
                        </S.FormControl>
                      </div>

                      <div className="two-inputs-grid">
                        <S.FormControl>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            PLZ
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            className="input-custom"
                            style={{ width: "100%" }}
                            placeholder={"71397"}
                            name="zipcode"
                            onChange={handleChange}
                            value={values.zipcode}
                          />

                          {touched.zipcode && errors.zipcode && (
                            <ErrorMessageCustom error={errors.zipcode} />
                          )}
                        </S.FormControl>
                        <S.FormControl>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            ORT
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            className="input-custom"
                            style={{ width: "100%" }}
                            placeholder={"Leutenbach"}
                            name="leutenbach"
                            onChange={handleChange}
                            value={values.leutenbach}
                          />

                          {touched.leutenbach && errors.leutenbach && (
                            <ErrorMessageCustom error={errors.leutenbach} />
                          )}
                        </S.FormControl>
                      </div>
                      <S.InlineTwoControls>
                        <S.FormControl>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            Ansprechpartner
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            className="input-custom"
                            style={{ width: "100%" }}
                            placeholder={"Vorname"}
                            name="contactPersonFirstName"
                            onChange={handleChange}
                            value={values.contactPersonFirstName}
                          />

                          {touched.contactPersonFirstName &&
                            errors.contactPersonFirstName && (
                              <ErrorMessageCustom
                                error={errors.contactPersonFirstName}
                              />
                            )}
                        </S.FormControl>
                        <S.FormControl>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            <br />
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            className="input-custom"
                            style={{ width: "100%" }}
                            placeholder={"Nachname"}
                            name="contactPersonLastName"
                            onChange={handleChange}
                            value={values.contactPersonLastName}
                          />
                          {touched.contactPersonLastName &&
                            errors.contactPersonLastName && (
                              <ErrorMessageCustom
                                error={errors.contactPersonLastName}
                              />
                            )}
                        </S.FormControl>
                      </S.InlineTwoControls>
                      <S.FormControl>
                        <S.TrainerProfileDetailsSection1InputLabel>
                          E-Mail
                        </S.TrainerProfileDetailsSection1InputLabel>
                        <Input
                          className="input-custom"
                          style={{ width: "100%" }}
                          placeholder={"hello@foodiary.app"}
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                        />
                        {touched.email && errors.email && (
                          <ErrorMessageCustom error={errors.email} />
                        )}
                      </S.FormControl>
                      <S.FormControl>
                        <S.TrainerProfileDetailsSection1InputLabel>
                          Telefon
                        </S.TrainerProfileDetailsSection1InputLabel>
                        <PhoneInput
                          className="phone-input"
                          placeholder="+49 000 000 0000"
                          defaultCountry="DE"
                          international
                          countryCallingCodeEditable={false}
                          onChange={(value) => {
                            if (value?.length < 25) {
                              SetIsPhoneValid(true);
                              SetPhoneNumber(value);
                            } else {
                              SetIsPhoneValid(false);
                            }
                          }}
                          value={PhoneNumber}
                        />

                        {!IsPhoneValid && !!PhoneNumber && (
                          <ErrorMessageCustom
                            error={"Bitte beachte das internationale Format"}
                          />
                        )}
                      </S.FormControl>
                      <S.FormControl>
                        <S.TrainerProfileDetailsSection1InputLabel>
                          Website
                        </S.TrainerProfileDetailsSection1InputLabel>
                        <Input
                          className="input-custom"
                          style={{ width: "100%" }}
                          placeholder={"https://www.foodiary.app"}
                          name="website"
                          onChange={handleChange}
                          value={values.website}
                        />

                        {touched.website && errors.website && (
                          <ErrorMessageCustom error={errors.website} />
                        )}
                      </S.FormControl>
                    </div>
                    <div className="partner-upload">
                      <div className="upload-item upload-logo">
                        <h6>Firmenlogo hochladen</h6>
                        <p className="desc">Firmenlogo hier hochladen</p>
                        <div className="upload-box">
                          <label htmlFor="companyLogo">
                            <img src={UploadIcon} alt="" />
                            <div className="upload-text">
                              <p>
                                <span>Klicke</span> auf das Feld um das
                                Firmenlogo hochzuladenF hochzuladen
                              </p>
                              <p>Erlaubt sind JPG and und PNG Dateien</p>
                            </div>
                          </label>
                          <input
                            type="file"
                            id="companyLogo"
                            name="companyLogo"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              console.log("companyLogo", e.target.files[0]);
                              SetCompanyLogo(e.target.files[0]);
                              handleChange(e);
                            }}
                          />
                        </div>

                        {CompanyLogo && errors.companyLogo && (
                          <ErrorMessageCustom error={errors.companyLogo} />
                        )}
                      </div>
                      {CompanyLogo && (
                        <div className="document-item">
                          <div className="file">
                            <img src={FileIcon} alt="" />
                            <div>
                              <p>{CompanyLogo?.name}</p>
                              <small>
                                {(CompanyLogo?.size / 1048576).toFixed(2)} mb
                              </small>
                            </div>
                          </div>
                        </div>
                      )}
                      <br />

                      <div className="upload-item upload-file">
                        <h6>Datei auswählen</h6>
                        <p className="desc">
                          Laden Sie hier Ihren Vertrag hoch
                        </p>
                        <div className="upload-box">
                          <label htmlFor="contractFile">
                            <img src={PDFIcon} alt="" />
                            <div className="upload-text">
                              <p>
                                <span>Klicke</span> auf das Feld um den Vertrag
                                hochzuladen
                              </p>
                              <p>Erlaubt sind PDF Files bis 1.5mb</p>
                            </div>
                          </label>
                          <input
                            ref={contractFileInputRef}
                            type="file"
                            id="contractFile"
                            name="contractFile"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              console.log("contractFile", e.target.files[0]);
                              SetContractFile(e.target.files[0]);
                              handleChange(e);
                            }}
                          />
                        </div>

                        {errors.contractFile && (
                          <ErrorMessageCustom error={errors.contractFile} />
                        )}
                      </div>
                      {ContractFile && (
                        <div className="document-item">
                          <div className="file">
                            <img src={PDFIcon} alt="" />
                            <div>
                              <p>{ContractFile?.name}</p>
                              <small>
                                {(ContractFile?.size / 1048576).toFixed(2)} mb
                              </small>
                            </div>
                          </div>
                        </div>
                      )}
                      <br />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </S.PrintDataDetails>
        </S.Wrapper>
      </S.Container>
      <Modal
        width="450px"
        height="auto"
        show={IsPartnerCreatedModalOpen}
        setShow={() => null}
        heading={
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="my-0 text-left"> </h4>
            {IsPartnerCreatedSuccessfully && (
              <img
                className="cursor-pointer"
                src={CrossIcon}
                alt="CrossIcon"
                onClick={() => SetIsPartnerCreatedModalOpen(false)}
              />
            )}
          </div>
        }
      >
        <div className="congratulations-modal-body-inner">
          {!IsPartnerCreatedSuccessfully && <img src={Sanduhr} alt="" />}
          {IsPartnerCreatedSuccessfully && (
            <img src={CongratulationsHands} alt="" />
          )}
          <div>
            {isMarketingPortalCreateAffiliateLoading && (
              <>
                <h6>Dein Partner wird angelegt</h6>
                <p>Schließe das Fenster nicht</p>
              </>
            )}

            {isMarketingPortalAddAffiliateProgramLoading && (
              <>
                <h6>Wir erstellen den Partner-Link</h6>
                <p>Schließe das Fenster nicht</p>
              </>
            )}

            {isQrCodeLoading && (
              <>
                <h6>Der QR-Code wird generiert </h6>
                <p>Schließe das Fenster nicht</p>
              </>
            )}

            {IsCreatePartnerLoading && (
              <>
                <h6>Dein Partner wird angelegt</h6>
                <p>Schließe das Fenster nicht</p>
              </>
            )}

            {IsPartnerCreatedSuccessfully && (
              <>
                <h6>Glückwunsch!</h6>
                <p>
                  Dein neuer Partner wurde angelegt. <br /> Erstelle direkt
                  Werbemittel für ihn.
                </p>
              </>
            )}
          </div>

          {IsPartnerCreatedSuccessfully && (
            <>
              <div className="text-center">
                <button
                  className="btn-dark mx-auto"
                  onClick={() =>
                    history.push("/dashboard/marketing-portal/print-data")
                  }
                >
                  Werbemittel anlegen
                </button>
              </div>
            </>
          )}

          <br />
        </div>
      </Modal>
    </>
  );
};

export default CreateNewPartner;
