import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/de";
import {
  FILE_BASE_URL,
  FILE_BASE_URL_S3,
} from "configs/services/apiCollections";

export const setUserRegistrationDataInLocalStorage = (data) => {
  localStorage.setItem("UserRegistration", JSON.stringify(data));
};

export const getUserRegistrationDataFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("UserRegistration"));
};

export const setUserHealthScoreDataInLocalStorage = (data) => {
  localStorage.setItem("HealthScoreData", JSON.stringify(data));
};

export const getUserHealthScoreDataFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("HealthScoreData"));
};

export const GetTrainerToken = () => {
  const Authorization = useSelector((state) => state.auth.trainer_token);
  if (Authorization.length > 0) return Authorization;
  else return false;
};

export const GetUserToken = () => {
  const Authorization = useSelector((state) => state.auth.token);
  if (Authorization.length > 0) return Authorization;
  else return false;
};

export const getImageUrl = (imagePath, image, imageType = null) => {
  if (!imageType) return FILE_BASE_URL() + imagePath + "/" + image;
  return FILE_BASE_URL() + imagePath + "/" + image + "." + imageType;
};

export const getS3ImageUrl = (image, type = null) => {
  if (!image || image === "null") return null; // some images come from api as "null" which is truthy
  if (!type) return FILE_BASE_URL_S3() + image;
  return FILE_BASE_URL_S3() + image + "." + type;
};

export const getNutritionImageUrl = (path, image, type = null) => {
  const BASE_URL = "https://app.foodiary.app/";
  if (!type) return BASE_URL + path + image;
  return BASE_URL + path + "/" + image + "." + type;
};

export const findAge = (birthday) => {
  const a = moment();
  const b = moment(birthday);
  if (!birthday) return 0;
  return moment.duration(a.diff(b)).years();
};

export const getPercent = (position) => {
  if (position === 0) return 60;
  else if (position === 1) return 75;
  else if (position === 2) return 100;
  else return 60;
};

export const getTargetValue = (position) => {
  if (position === 0) return "1,50 €";
  else if (position === 1) return "4,50 €";
  else if (position === 2) return "8,00 €";
  else return "0,00 €";
};

export const getRandomValue = (endValue) =>
  Math.floor(Math.random() * endValue + 1);

export const getRandomValueInRange = (startValue, endValue) =>
  Math.floor(Math.random() * (startValue - endValue + 1) + endValue);

export const isFirefox = typeof InstallTrigger !== "undefined";

export const formatToGermanCurrency = (number) =>
  new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(
    number
  );

export const sliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const calculateLastDaysChartData = (days) => {
  if (!days) return;

  const listOfChunks = sliceIntoChunks(days, 7);

  const chartData = listOfChunks.map((list) => ({
    date: `${list.at(0).date.split("-")[2]}.${
      list.at(0).date.split("-")[1]
    } - ${list.at(-1).date.split("-")[2]}.${list.at(-1).date.split("-")[1]}`,
    value: list
      .map((item) => item?.numberOfActiveClients)
      .reduce((a, b) => a + b, 0),
  }));

  return {
    dates: chartData?.map((item) => item?.date || ""),
    values: chartData?.map((item) => item?.value || 0),
  };
};

export const calculateChartPercentage = (
  ChartDataPreviousMonth,
  ChartDataCurrentMonth
) => {
  const previousMonthCount = ChartDataPreviousMonth?.reduce((a, b) => a + b, 0);
  const currentMonthCount = ChartDataCurrentMonth?.reduce((a, b) => a + b, 0);

  const p = (
    100 *
    Math.abs(
      (previousMonthCount - currentMonthCount) /
        ((previousMonthCount + currentMonthCount) / 2)
    )
  ).toFixed();

  if (currentMonthCount - previousMonthCount >= 0) {
    return `+${p}`;
  } else {
    return `-${p}`;
  }
};

export const dateNumbersFormat = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  return `${day}.${month}.${year}`;
};

export const formatDate = (date) => {
  const formatedDate = date?.split("-")?.reverse()?.join(".");
  const dayIndex = new Date(date).getDay();
  const days = [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ];
  return { day: days[dayIndex], date: formatedDate };
};

export const formatNewsDate = (dateString) => {
  const date = moment(dateString, "YYYY-MM-DD").locale("de");
  return date.format("DD. MMMM YYYY");
};

export const removeDuplicates = (array) => {
  const uniqueArray = array.filter(
    (item, index, self) =>
      index === self.findIndex((i) => i.email === item.email)
  );
  return uniqueArray;
};

export const renderValueInGerman = (value) =>
  value.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });

export const areAllObjectsValuesNull = (obj) => {
  return Object.values(obj).every((value) => value === null);
};

export const areFewObjectsValuesNull = (obj) => {
  return Object.values(obj)?.some((value) => value === null);
};

export const removeSpaceAndToLowerCase = (value) => {
  return value?.split(" ")?.join("")?.toLowerCase();
};

export const removeDuplicatesUsingId = (array) => {
  const uniqueIds = new Set();
  return array.filter((item) => {
    if (uniqueIds.has(item.id)) {
      return false;
    } else {
      uniqueIds.add(item.id);
      return true;
    }
  });
};

export const dashboardNavTabs = [
  {
    id: "dashboard",
    value: "Dashboard",
    route: "/main/dashboard/home",
  },
  {
    id: "mainCustomer",
    value: "Meine Kunden",
    route: "/main/dashboard/customers",
  },
  //   {
  //     id: "calendar",
  //     value: "Kalender",
  //     redirect: "https://www.calendly.com",
  //   },
  {
    id: "weeklyCalls",
    value: "Weeklys",
    route: "/main/dashboard/weeklyCalls",
  },
  {
    id: "academy",
    value: "Academy",
    route: "/academy",
  },
];
