import styled from "styled-components";

export const Container = styled.div`
  /* display: grid;
  grid-template-columns: 300px 1fr; */
  display: flex;
`;
export const Wrapper = styled.div`
  padding: 1.5rem;
`;
export const WhitePaperWrap = styled.div``;

export const MCard = styled.div`
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 10px;
  padding: 1.875rem 1.56rem;
  background: #fff;
`;

export const WhitePaperTitle = styled.div`
  color: #44554c;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  margin: 2.5rem 0 1.5rem;
`;

export const WhitePaperInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const WhitePaperInnerCard = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 13.625rem 1fr;
  gap: 1.5rem;
  background-color: white;
`;

export const WhitePaperCardImage = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

export const WhitePaperCardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0.5rem;
  margin-block: 1rem;

  h3 {
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: 500;
    color: #142c1f;
    margin: 0;
  }
  p {
    color: #44554c;
    font-size: .75rem;
    line-height: 1.25rem;
    font-weight: 400;
    margin: 1rem 0;
  }

  a {
    background: #f5f5f5;
    border-radius: .3125rem;
    padding: 0.5rem;
    color: #44554c;
    text-transform: uppercase;
    font-size: .75rem;
    line-height: 1.0625rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    gap: 0.5rem;
    width: fit-content;
    text-align: left;
    text-decoration: none;

    &:hover {
      background: #eeeeee;
    }
  }
`;

export const ClientDetailsContentHeading = styled.h6`
  font-size: 1.25rem;
  color: #142c1f;
  margin-bottom: .3125rem;
  margin-top: 0;
`;

export const ClientDetailsContentSubHeading = styled.div`
  font-size: .9375rem;
  color: #818d88;
  margin-top: 1.25rem;
  font-weight: 400;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;
