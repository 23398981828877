import React from "react";
import { Redirect, Route } from "react-router-dom";

const CustomRoute = ({ component: Component, restricted, path, ...rest }) => {
  const authenticated = localStorage.getItem("trainer_token") !== null;
  const userAuthenticated = localStorage.getItem("token") !== null;

  // const loadChatScript = () => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://widget.superchat.de/snippet.js?applicationKey=WC3Xmk0Od84BvMppEAQJyejGKw";
  //   script.async = true;
  //   script.referrerPolicy = "no-referrer-when-downgrade";
  //   document.body.appendChild(script);
  // };

  // const removeChatScript = () => {
  //   const scripts = document.getElementsByTagName("script");
  //   console.log("scripts", scripts);
  //   for (let i = 0; i < scripts.length; i++) {
  //     if (
  //       scripts[i].src ===
  //         "https://widget.superchat.de/snippet.js?applicationKey=WC3Xmk0Od84BvMppEAQJyejGKw" ||
  //       scripts[i].src === "https://widget.superchat.de/sdk.js"
  //     ) {
  //       scripts[i].parentNode.removeChild(scripts[i]);
  //     }
  //   }

  //   const iframe = document.getElementById("superchat-widget");
  //   if (iframe) {
  //     iframe.parentNode.removeChild(iframe);
  //   }
  // };

  // if (authenticated || userAuthenticated) {
  //   loadChatScript();
  // }

  // if (!(authenticated || userAuthenticated)) {
  //   removeChatScript();
  // }

  return (
    <Route
      {...rest}
      render={(props) =>
        !restricted || ((authenticated || userAuthenticated) && restricted) ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <>
            <Redirect to="/login" />
          </>
        )
      }
    />
  );
};

export default CustomRoute;
