import { takeLatest, put, all, call } from "redux-saga/effects";

import ApiCollections from "configs/services/apiCollections";
import * as AuthActions from "redux/auth/auth.actions";
import NutritionActionTypes from "./nutrition.types";
import * as NutritionActions from "./nutrition.actions";

/*************************************************/

export function* getUserData({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getUserData);
    payload.callback(response);
    yield put(NutritionActions.getUserDataSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(NutritionActions.getUserDataFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getNutritionType({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getNutritionType, payload);
    yield put(NutritionActions.getNutritionTypeSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(NutritionActions.getNutritionTypeFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getAllNutritionTypes({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getAllNutritionTypes, payload);
    yield put(NutritionActions.getAllNutritionTypesSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(NutritionActions.getAllNutritionTypesFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* updateNutritionType({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.updateNutritionType,
      payload?.payload
    );
    yield put(NutritionActions.updateNutritionTypeSuccess(response.data));
    yield put(AuthActions.loadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(NutritionActions.updateNutritionTypeFailure(error));
    yield put(AuthActions.loadingStop());
    payload?.callback(error);
  }
}

/*************************************************/

export function* updateNutritionCalories({ payload }) {
  console.log("updateNutritionCalories", payload);
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.updateNutritionCalories,
      payload?.payload
    );
    yield put(NutritionActions.updateNutritionCaloriesSuccess(response.data));
    yield put(AuthActions.loadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(NutritionActions.updateNutritionCaloriesFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getNutritionAdvantages({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getNutritionAdvantages, payload);
    yield put(NutritionActions.getNutritionAdvantagesSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(NutritionActions.getNutritionAdvantagesFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getNutritionRecipes({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getNutritionRecipes, payload);
    yield put(NutritionActions.getNutritionRecipesSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(NutritionActions.getNutritionRecipesFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getWeekWiseCalories({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.getWeekWiseCaloriesData,
      payload.data
    );
    yield put(
      NutritionActions.getWeekWiseCaloriesSuccess({
        description:
          response["01_text"] ||
          response["02_text"] ||
          response["03_text"] ||
          response["04_text"],
        ...response.data,
      })
    );
    yield put(AuthActions.loadingStop());
    payload.funcSuccess();
  } catch (error) {
    yield put(NutritionActions.getWeekWiseCaloriesFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getAllCaloriesData({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.getAllCaloriesData,
      payload.data
    );
    yield put(NutritionActions.getAllCaloriesSuccess(response.data));
    yield put(AuthActions.loadingStop());
    payload.funcSuccess(response);
  } catch (error) {
    yield put(NutritionActions.getAllCaloriesFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getMacroNutrientsDonutData({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.getMacroNutrientsDonutData,
      payload
    );
    yield put(NutritionActions.getMacroNutrientsDonutSuccess(response));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(NutritionActions.getMacroNutrientsDonutFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getNutritionTypePercentageChartData({ payload }) {
  try {
    const response = yield call(
      ApiCollections.getNutritionTypePercentageChartData,
      payload.formData
    );
    yield put(
      NutritionActions.getNutritionTypePercentageChartDataSuccess(response)
    );
    payload?.callback(response);
  } catch (error) {
    yield put(
      NutritionActions.getNutritionTypePercentageChartDataFailure(error)
    );
  }
}

/*************************************************/

export function* resetPassword({ payload }) {
  let response = null;
  yield put(NutritionActions.resetPasswordLoadingStart());
  try {
    response = yield call(ApiCollections.resetPassword, payload.formValues);
    yield put(NutritionActions.resetPasswordSuccess(response));
    yield put(payload.func(response));
    yield put(NutritionActions.resetPasswordLoadingStop());
  } catch (error) {
    console.log("setPassword - Failure Dispatch");
    yield put(NutritionActions.resetPasswordLoadingStop());
    if (response?.success) return;
    yield put(NutritionActions.resetPasswordFailure(error));
  }
}

/*************************************************/

export function* setPassword({ payload }) {
  let response = null;
  yield put(NutritionActions.setPasswordLoadingStart());
  try {
    response = yield call(ApiCollections.setPassword, payload.formValues);
    yield put(NutritionActions.setPasswordSuccess(response));
    yield put(payload.func(response));
    yield put(NutritionActions.setPasswordLoadingStop());
  } catch (error) {
    console.log("setPassword - Failure Dispatch");
    yield put(NutritionActions.setPasswordLoadingStop());
    if (response?.success) return;
    yield put(NutritionActions.setPasswordFailure(error));
  }
}

/*************************************************/

export function* courseChangePassword({ payload }) {
  let response = null;
  yield put(NutritionActions.courseChangePasswordLoadingStart());
  try {
    response = yield call(
      ApiCollections.courseChangePassword,
      payload.formValues
    );
    yield put(NutritionActions.courseChangePasswordSuccess(response));
    yield put(payload.func(response));
    yield put(NutritionActions.courseChangePasswordLoadingStop());
  } catch (error) {
    console.log("courseChangePassword - Failure Dispatch");
    yield put(NutritionActions.courseChangePasswordLoadingStop());
    if (response?.success) return;
    yield put(NutritionActions.courseChangePasswordFailure(error));
  }
}

/*************************************************/

export function* getFaqQuestions({ payload }) {
  let response = null;
  yield put(NutritionActions.getFaqQuestionsLoadingStart());
  try {
    response = yield call(ApiCollections.getFaqQuestions, payload.formValues);
    yield put(NutritionActions.getFaqQuestionsSuccess(response));
    yield put(payload.func(response));
    yield put(NutritionActions.getFaqQuestionsLoadingStop());
  } catch (error) {
    yield put(NutritionActions.getFaqQuestionsLoadingStop());
    yield put(NutritionActions.getFaqQuestionsFailure(error));
  }
}

/*************************************************/

export function* getFaqQuestionsById({ payload }) {
  let response = null;
  yield put(NutritionActions.getFaqQuestionsLoadingStart());
  try {
    response = yield call(
      ApiCollections.getFaqQuestionsById,
      payload.formValues
    );
    yield put(NutritionActions.getFaqQuestionsByTopicIdSuccess(response));
    yield put(payload.func(response));
    yield put(NutritionActions.getFaqQuestionsLoadingStop());
  } catch (error) {
    yield put(NutritionActions.getFaqQuestionsLoadingStop());
    yield put(NutritionActions.getFaqQuestionsByTopicIdFailure(error));
  }
}

/*************************************************/

export function* getNutritionCourseDetailssById({ payload }) {
  let response = null;
  yield put(NutritionActions.getNutritionCourseDetailsByIdLoadingStart());
  try {
    response = yield call(
      ApiCollections.getNutritionCourseDetailssById,
      payload?.payload
    );
    yield put(NutritionActions.getNutritionCourseDetailsByIdSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.getNutritionCourseDetailsByIdLoadingStop());
  } catch (error) {
    yield put(NutritionActions.getNutritionCourseDetailsByIdLoadingStop());
    yield put(NutritionActions.getNutritionCourseDetailsByIdFailure(error));
  }
}

/*************************************************/

export function* updateNutritionCourseDetails({ payload }) {
  let response = null;
  yield put(NutritionActions.updateNutritionCourseDetailsLoadingStart());
  try {
    response = yield call(
      ApiCollections.updateNutritionCourseDetails,
      payload?.payload
    );
    yield put(NutritionActions.updateNutritionCourseDetailsSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.updateNutritionCourseDetailsLoadingStop());
  } catch (error) {
    yield put(NutritionActions.updateNutritionCourseDetailsLoadingStop());
    yield put(NutritionActions.updateNutritionCourseDetailsFailure(error));
  }
}

/*************************************************/

export function* updateOrDeleteNutritionCourseDetails({ payload }) {
  let response = null;
  yield put(NutritionActions.updateNutritionCourseDetailsLoadingStart());
  try {
    response = yield call(
      ApiCollections.updateOrDeleteNutritionCourseDetails,
      payload?.payload
    );
    yield put(NutritionActions.updateNutritionCourseDetailsSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.updateNutritionCourseDetailsLoadingStop());
  } catch (error) {
    yield put(NutritionActions.updateNutritionCourseDetailsLoadingStop());
    yield put(NutritionActions.updateNutritionCourseDetailsFailure(error));
  }
}

/*************************************************/

export function* checkUserIdEmail({ payload }) {
  let response = null;
  yield put(NutritionActions.checkUserIdEmailLoadingStart());
  try {
    response = yield call(ApiCollections.checkUserIdEmail, payload?.payload);
    yield put(NutritionActions.checkUserIdEmailSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.checkUserIdEmailLoadingStop());
  } catch (error) {
    yield put(NutritionActions.checkUserIdEmailLoadingStop());
    yield put(NutritionActions.checkUserIdEmailFailure(error));
  }
}

/*************************************************/

export function* getMarketingPortalAssets({ payload }) {
  let response = null;
  yield put(NutritionActions.getMarketingPortalAssetsLoadingStart());
  try {
    response = yield call(ApiCollections.getMarketingPortalAssets);
    yield put(NutritionActions.getMarketingPortalAssetsSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.getMarketingPortalAssetsLoadingStop());
  } catch (error) {
    yield put(NutritionActions.getMarketingPortalAssetsLoadingStop());
    yield put(NutritionActions.getMarketingPortalAssetsFailure(error));
  }
}

/*************************************************/

export function* getMarketingPortalPrints({ payload }) {
  let response = null;
  yield put(NutritionActions.getMarketingPortalPrintsLoadingStart());
  try {
    response = yield call(ApiCollections.getMarketingPortalPrints);
    yield put(NutritionActions.getMarketingPortalPrintsSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.getMarketingPortalPrintsLoadingStop());
  } catch (error) {
    yield put(NutritionActions.getMarketingPortalPrintsLoadingStop());
    yield put(NutritionActions.getMarketingPortalPrintsFailure(error));
  }
}

/*************************************************/

export function* getMarketingPortalPrintsDetails({ payload }) {
  let response = null;
  yield put(NutritionActions.getMarketingPortalPrintsDetailsLoadingStart());
  try {
    response = yield call(
      ApiCollections.getMarketingPortalPrintsDetails,
      payload?.payload
    );
    yield put(
      NutritionActions.getMarketingPortalPrintsDetailsSuccess(response)
    );
    yield put(payload?.callback(response));
    yield put(NutritionActions.getMarketingPortalPrintsDetailsLoadingStop());
  } catch (error) {
    yield put(NutritionActions.getMarketingPortalPrintsDetailsLoadingStop());
    yield put(NutritionActions.getMarketingPortalPrintsDetailsFailure(error));
  }
}

/*************************************************/

export function* getMarketingPortalPartners({ payload }) {
  let response = null;
  yield put(NutritionActions.getMarketingPortalPartnersLoadingStart());
  try {
    response = yield call(
      ApiCollections.getMarketingPortalPartners,
      payload?.payload
    );
    yield put(NutritionActions.getMarketingPortalPartnersSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.getMarketingPortalPartnersLoadingStop());
  } catch (error) {
    yield put(NutritionActions.getMarketingPortalPartnersLoadingStop());
    yield put(NutritionActions.getMarketingPortalPartnersFailure(error));
  }
}

/*************************************************/

export function* getMarketingPortalPartnerDetails({ payload }) {
  let response = null;
  yield put(NutritionActions.getMarketingPortalPartnerDetailsLoadingStart());
  try {
    response = yield call(
      ApiCollections.getMarketingPortalPartnerDetails,
      payload?.payload
    );
    yield put(
      NutritionActions.getMarketingPortalPartnerDetailsSuccess(response)
    );
    yield put(payload?.callback(response));
    yield put(NutritionActions.getMarketingPortalPartnerDetailsLoadingStop());
  } catch (error) {
    yield put(NutritionActions.getMarketingPortalPartnerDetailsLoadingStop());
    yield put(NutritionActions.getMarketingPortalPartnerDetailsFailure(error));
  }
}

/*************************************************/

export function* getMarketingPortalPartnerAndPrintDetails({ payload }) {
  let response = null;
  yield put(
    NutritionActions.getMarketingPortalPartnerAndPrintDetailsLoadingStart()
  );
  try {
    response = yield call(
      ApiCollections.getMarketingPortalPartnerAndPrintDetails,
      payload?.payload
    );
    yield put(
      NutritionActions.getMarketingPortalPartnerAndPrintDetailsSuccess(response)
    );
    yield put(payload?.callback(response));
    yield put(
      NutritionActions.getMarketingPortalPartnerAndPrintDetailsLoadingStop()
    );
  } catch (error) {
    yield put(
      NutritionActions.getMarketingPortalPartnerAndPrintDetailsLoadingStop()
    );
    yield put(
      NutritionActions.getMarketingPortalPartnerAndPrintDetailsFailure(error)
    );
  }
}

/*************************************************/

export function* marketingPortalCreateAffiliate({ payload }) {
  let response = null;
  yield put(NutritionActions.marketingPortalCreateAffiliateLoadingStart());
  try {
    response = yield call(
      ApiCollections.marketingPortalCreateAffiliate,
      payload?.payload
    );
    yield put(NutritionActions.marketingPortalCreateAffiliateSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.marketingPortalCreateAffiliateLoadingStop());
  } catch (error) {
    yield put(NutritionActions.marketingPortalCreateAffiliateLoadingStop());
    yield put(NutritionActions.marketingPortalCreateAffiliateFailure(error));
  }
}

/*************************************************/

export function* marketingPortalAddAffiliateProgram({ payload }) {
  let response = null;
  yield put(NutritionActions.marketingPortalAddAffiliateLoadingStart());
  try {
    response = yield call(
      ApiCollections.marketingPortalAddAffiliateProgram,
      payload?.payload
    );
    yield put(NutritionActions.marketingPortalAddAffiliateSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.marketingPortalAddAffiliateLoadingStop());
  } catch (error) {
    yield put(NutritionActions.marketingPortalAddAffiliateLoadingStop());
    yield put(NutritionActions.marketingPortalAddAffiliateFailure(error));
  }
}

/*************************************************/

export function* createQrCode({ payload }) {
  let response = null;
  yield put(NutritionActions.createQrCodeLoadingStart());
  try {
    response = yield call(ApiCollections.createQrCode, payload?.formData);
    yield put(NutritionActions.createQrCodeSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.createQrCodeLoadingStop());
  } catch (error) {
    yield put(NutritionActions.createQrCodeLoadingStop());
    yield put(NutritionActions.createQrCodeFailure(error));
  }
}

/*************************************************/

export function* marketingPortalCreatePartner({ payload }) {
  let response = null;
  yield put(NutritionActions.marketingPortalCreatePartnerLoadingStart());
  try {
    response = yield call(
      ApiCollections.marketingPortalCreatePartner,
      payload?.payload
    );
    yield put(NutritionActions.marketingPortalCreatePartnerSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.marketingPortalCreatePartnerLoadingStop());
  } catch (error) {
    yield put(NutritionActions.marketingPortalCreatePartnerLoadingStop());
    yield put(NutritionActions.marketingPortalCreatePartnerFailure(error));
  }
}

/*************************************************/

export function* marketingPortalCoachDetails({ payload }) {
  let response = null;
  yield put(NutritionActions.marketingPortalCoachDetailsLoadingStart());
  try {
    response = yield call(
      ApiCollections.marketingPortalCoachDetails,
      payload?.payload
    );
    yield put(NutritionActions.marketingPortalCoachDetailsSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.marketingPortalCoachDetailsLoadingStop());
  } catch (error) {
    yield put(NutritionActions.marketingPortalCoachDetailsLoadingStop());
    yield put(NutritionActions.marketingPortalCoachDetailsFailure(error));
  }
}

/*************************************************/

export function* marketingPortalPrintPic({ payload }) {
  let response = null;
  yield put(NutritionActions.marketingPortalPrintPicLoadingStart());
  try {
    response = yield call(
      ApiCollections.marketingPortalPrintPic,
      payload?.payload
    );
    yield put(NutritionActions.marketingPortalPrintPicSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.marketingPortalPrintPicLoadingStop());
  } catch (error) {
    yield put(NutritionActions.marketingPortalPrintPicLoadingStop());
    yield put(NutritionActions.marketingPortalPrintPicFailure(error));
  }
}

/*************************************************/

export function* marketingPortalRawUrlByPrintId({ payload }) {
  let response = null;
  yield put(NutritionActions.marketingPortalRawUrlByPrintIdLoadingStart());
  try {
    response = yield call(
      ApiCollections.marketingPortalRawUrlByPrintId,
      payload?.payload
    );
    yield put(NutritionActions.marketingPortalRawUrlByPrintIdSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.marketingPortalRawUrlByPrintIdLoadingStop());
  } catch (error) {
    yield put(NutritionActions.marketingPortalRawUrlByPrintIdLoadingStop());
    yield put(NutritionActions.marketingPortalRawUrlByPrintIdFailure(error));
  }
}

/*************************************************/

export function* marketingPortalPrintPlacementDetails({ payload }) {
  let response = null;
  yield put(
    NutritionActions.marketingPortalPrintPlacementDetailsLoadingStart()
  );
  try {
    response = yield call(
      ApiCollections.marketingPortalPrintPlacementDetails,
      payload?.payload
    );
    yield put(
      NutritionActions.marketingPortalPrintPlacementDetailsSuccess(response)
    );
    yield put(payload?.callback(response));
    yield put(
      NutritionActions.marketingPortalPrintPlacementDetailsLoadingStop()
    );
  } catch (error) {
    yield put(
      NutritionActions.marketingPortalPrintPlacementDetailsLoadingStop()
    );
    yield put(
      NutritionActions.marketingPortalPrintPlacementDetailsFailure(error)
    );
  }
}

/*************************************************/

export function* marketingPortalCreatePrint({ payload }) {
  let response = null;
  yield put(NutritionActions.marketingPortalCreatePrintLoadingStart());
  try {
    response = yield call(
      ApiCollections.marketingPortalCreatePrint,
      payload?.payload
    );
    yield put(NutritionActions.marketingPortalCreatePrintSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.marketingPortalCreatePrintLoadingStop());
  } catch (error) {
    yield put(NutritionActions.marketingPortalCreatePrintLoadingStop());
    yield put(NutritionActions.marketingPortalCreatePrintFailure(error));
  }
}

/*************************************************/

export function* marketingPortalCreatePartnerPrint({ payload }) {
  let response = null;
  yield put(NutritionActions.marketingPortalCreatePartnerPrintLoadingStart());
  try {
    response = yield call(
      ApiCollections.marketingPortalCreatePartnerPrint,
      payload?.payload
    );
    yield put(
      NutritionActions.marketingPortalCreatePartnerPrintSuccess(response)
    );
    yield put(payload?.callback(response));
    yield put(NutritionActions.marketingPortalCreatePartnerPrintLoadingStop());
  } catch (error) {
    yield put(NutritionActions.marketingPortalCreatePartnerPrintLoadingStop());
    yield put(NutritionActions.marketingPortalCreatePartnerPrintFailure(error));
  }
}

/*************************************************/

export function* getAllRefunds({ payload }) {
  let response = null;
  yield put(NutritionActions.getAllRefundsLoadingStart());
  try {
    response = yield call(ApiCollections.getAllRefunds);
    yield put(NutritionActions.getAllRefundsSuccess(response));
    yield put(payload?.callback(response));
    yield put(NutritionActions.getAllRefundsLoadingStop());
  } catch (error) {
    yield put(NutritionActions.getAllRefundsLoadingStop());
    yield put(NutritionActions.getAllRefundsFailure(error));
  }
}

/*************************************************/

export function* getMacroNutrientsPieChartData({ payload }) {
  let response = null;
  yield put(NutritionActions.getMacroNutrientsPieChartDataLoadingStart());
  try {
    response = yield call(
      ApiCollections.getMacroNutrientsPieChartData,
      payload?.payload
    );
    yield put(NutritionActions.getMacroNutrientsPieChartDataSuccess(response));
    payload?.callback(response);
    yield put(NutritionActions.getMacroNutrientsPieChartDataLoadingStop());
  } catch (error) {
    payload?.callback(error);
    yield put(NutritionActions.getMacroNutrientsPieChartDataLoadingStop());
    yield put(NutritionActions.getMacroNutrientsPieChartDataFailure(error));
  }
}

/*************************************************/


export function* updatePartnerClaim({ payload }) {
  let response = null;

  try {
    response = yield call(
      ApiCollections.updatePartnerClaim,
      payload?.payload
    );
    yield put(NutritionActions.updatePartnerClaimSuccess(response));
    yield put(payload?.callback(response));
  } catch (error) {
    yield put(NutritionActions.updatePartnerClaimFailure(error));
  }
}



/*************************************************/


export function* updateUserInformation({ payload }) {
  console.log("🚀 ~ function*updateUserInformation ~ payload:", payload)
  let response = null;
  yield put(NutritionActions.updateUserInformationRequestStart());
  try {
    response = yield call(
      ApiCollections.updateUserInformation,
      payload?.payload
    );
    yield put(NutritionActions.updateUserInformationSuccess(response));
    yield put(payload?.callback(response));
  } catch (error) {
    yield put(NutritionActions.updateUserInformationFailure(error));
  }
}



/*************************************************/


export function* nutritionSagas() {
  yield all([
    yield takeLatest(NutritionActionTypes.GET_USER_DATA_REQUEST, getUserData),
    yield takeLatest(
      NutritionActionTypes.GET_NUTRITION_TYPE_REQUEST,
      getNutritionType
    ),
    yield takeLatest(
      NutritionActionTypes.GET_ALL_NUTRITION_TYPES_REQUEST,
      getAllNutritionTypes
    ),
    yield takeLatest(
      NutritionActionTypes.UPDATE_NUTRITION_TYPE_REQUEST,
      updateNutritionType
    ),
    yield takeLatest(
      NutritionActionTypes.UPDATE_NUTRITION_CALORIES_REQUEST,
      updateNutritionCalories
    ),
    yield takeLatest(
      NutritionActionTypes.GET_NUTRITION_ADVANTAGES_REQUEST,
      getNutritionAdvantages
    ),
    yield takeLatest(
      NutritionActionTypes.GET_NUTRITION_RECIPES_REQUEST,
      getNutritionRecipes
    ),
    yield takeLatest(
      NutritionActionTypes.GET_WEEK_WISE_CALORIES_REQUEST,
      getWeekWiseCalories
    ),
    yield takeLatest(
      NutritionActionTypes.GET_ALL_CALORIES_REQUEST,
      getAllCaloriesData
    ),
    yield takeLatest(
      NutritionActionTypes.GET_MACRO_NUTRIENTS_DONUT_REQUEST,
      getMacroNutrientsDonutData
    ),

    yield takeLatest(
      NutritionActionTypes.GET_NUTRITION_TYPE_PERCENTAGE_CHART_DATA_REQUEST,
      getNutritionTypePercentageChartData
    ),

    yield takeLatest(NutritionActionTypes.SET_PASSWORD_REQUEST, setPassword),
    yield takeLatest(
      NutritionActionTypes.RESET_PASSWORD_REQUEST,
      resetPassword
    ),

    yield takeLatest(
      NutritionActionTypes.COURSE_CHANGE_PASSWORD_REQUEST,
      courseChangePassword
    ),

    yield takeLatest(
      NutritionActionTypes.GET_FAQ_QUESTIONS_REQUEST,
      getFaqQuestions
    ),

    yield takeLatest(
      NutritionActionTypes.GET_FAQ_QUESTIONS_BY_ID_REQUEST,
      getFaqQuestionsById
    ),

    yield takeLatest(
      NutritionActionTypes.GET_NUTRITION_COURSE_DETAILS_BY_ID_REQUEST,
      getNutritionCourseDetailssById
    ),

    yield takeLatest(
      NutritionActionTypes.CHECK_USERID_EMAIL_REQUEST,
      checkUserIdEmail
    ),

    yield takeLatest(
      NutritionActionTypes.UPDATE_NUTRITION_COURSE_DETAILS_REQUEST,
      updateNutritionCourseDetails
    ),

    yield takeLatest(
      NutritionActionTypes.UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_REQUEST,
      updateOrDeleteNutritionCourseDetails
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_ASSETS_REQUEST,
      getMarketingPortalAssets
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_PRINTS_REQUEST,
      getMarketingPortalPrints
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_PRINTS_DETAILS_REQUEST,
      getMarketingPortalPrintsDetails
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_PARTNERS_REQUEST,
      getMarketingPortalPartners
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_PARTNER_DETAILS_REQUEST,
      getMarketingPortalPartnerDetails
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_REQUEST,
      getMarketingPortalPartnerAndPrintDetails
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_CREATE_AFFILIATE_REQUEST,
      marketingPortalCreateAffiliate
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_REQUEST,
      marketingPortalAddAffiliateProgram
    ),

    yield takeLatest(NutritionActionTypes.CREATE_QR_CODE_REQUEST, createQrCode),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_REQUEST,
      marketingPortalCreatePartner
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_COACH_DETAILS_REQUEST,
      marketingPortalCoachDetails
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_PRINT_PIC_REQUEST,
      marketingPortalPrintPic
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_REQUEST,
      marketingPortalRawUrlByPrintId
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_REQUEST,
      marketingPortalPrintPlacementDetails
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_CREATE_PRINT_REQUEST,
      marketingPortalCreatePrint
    ),

    yield takeLatest(
      NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_PRINT_REQUEST,
      marketingPortalCreatePartnerPrint
    ),

    yield takeLatest(
      NutritionActionTypes.GET_ALL_REFUNDS_REQUEST,
      getAllRefunds
    ),

    yield takeLatest(
      NutritionActionTypes.GET_MACRO_NUTRIENTS_PIE_CHART_DATA_REQUEST,
      getMacroNutrientsPieChartData
    ),
    yield takeLatest(
      NutritionActionTypes.UPDATE_PARTNER_CLAIM_REQUEST,
      updatePartnerClaim
    ),
    yield takeLatest(
      NutritionActionTypes.UPDATE_USER_INFORMATION_REQUEST,
      updateUserInformation
    ),
  ]);
}

/*************************************************/
