import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getNutritionImageUrl } from "configs/utils";

import TranslationMessage from "configs/translations/TranslationMessage";
import LineChart from "components/lineChart/LineChart";
import Button from "components/formComponents/button/Button";
import Modal from "components/modal/Modal";
import CrossIcon from "assets/images/cross.svg";
// Images
import LeftIcon from "assets/images/leftIcon.svg";
import RightIcon from "assets/images/rightIcon.svg";
// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";

import ArrowLeft from "assets/images/arrowLeft.svg";

import * as S from "../OnBoardingPartTwo.styles";
import IncrementInputModern from "components/formComponents/IncrementInputModern/IncrementInputModern";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

const NutritionCalories = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [editCaloriesModal, setEditCaloriesModal] = useState(false);
  const [restDayCalories, setRestDayCalories] = useState(0);
  const [trainingDayCalories, setTrainingDayCalories] = useState(0);
  const [weekNumber, setWeekNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const caloriesData = useSelector(
    (state) => state.nutrition.caloriesLineChartData
  );

  useEffect(() => {
    hitCaloriesDataAPI();
  }, [dispatch]);

  const hitCaloriesDataAPI = () => {
    setIsLoading(true);
    dispatch(
      NutritionActions.getWeekWiseCaloriesRequest({
        data: {
          nutritiontypeID: getRegistrationData()?.nutritiontypeID,
          aimID: getRegistrationData()?.aimID,
          sex: getRegistrationData()?.sex,
          user_id: getRegistrationData()?.userID,
        },
        funcSuccess: () => {
          setIsLoading(false);
        },
      })
    );
  };

  const handleCaloriesUpdate = () => {
    setEditCaloriesModal(false);
    dispatch(
      NutritionActions.updateNutritionCaloriesRequest({
        payload: {
          nutritiontypeID: getRegistrationData()?.nutritiontypeID,
          nutritionconceptID: getRegistrationData()?.nutritionconceptID,
          aimID: getRegistrationData()?.aimID,
          sex: getRegistrationData()?.sex,
          week: weekNumber,
          caloriesData: [
            { daytypeID: 1, calories: restDayCalories },
            { daytypeID: 2, calories: trainingDayCalories },
          ],
        },
        callback: () => {
          hitCaloriesDataAPI();
        },
      })
    );
  };

  const data = [
    { label: "1", x: 1, y: caloriesData && caloriesData["1"] },
    { label: "2", x: 2, y: caloriesData && caloriesData["2"] },
    { label: "3", x: 3, y: caloriesData && caloriesData["3"] },
    { label: "4", x: 4, y: caloriesData && caloriesData["4"] },
    { label: "5", x: 5, y: caloriesData && caloriesData["5"] },
    { label: "6", x: 6, y: caloriesData && caloriesData["6"] },
    { label: "7", x: 7, y: caloriesData && caloriesData["7"] },
    { label: "8", x: 8, y: caloriesData && caloriesData["8"] },
    { label: "9", x: 9, y: caloriesData && caloriesData["9"] },
    { label: "10", x: 10, y: caloriesData && caloriesData["10"] },
    { label: "11", x: 11, y: caloriesData && caloriesData["11"] },
    { label: "12", x: 12, y: caloriesData && caloriesData["12"] },
  ];

  const lineChartStatus = caloriesData
    ? (caloriesData["12"] - caloriesData["1"]).toFixed(2) + " kcal"
    : "0 kcal";

  const handleRightNavigation = () => {
    if (weekNumber !== 12) setWeekNumber(weekNumber + 1);
  };
  const handleLeftNavigation = () => {
    if (weekNumber !== 1) setWeekNumber(weekNumber - 1);
  };

  const getRegistrationData = () => {
    const RD = JSON.parse(localStorage.getItem("RegistrationData"));
    return RD;
  };

  const handleBack = () => {
    history.push("/onboarding/nutrition/recipies");
  };

  const handleNext = () => {
    history.push("/onboarding/nutrition/macro-nutrients");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={3} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Auswertung
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Unsere Empfehlung für dich
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="nutrition-calories-content">
                {isLoading && (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="text-center">
                      <div className="loader mx-auto"></div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}

                {!isLoading && (
                  <>
                    <h3 className="text-size-20-28 text-dark-1 font-rubik-medium mt-0">
                      Dein persönlicher Tagesbedarf
                    </h3>

                    <div className="nutrition-calories-chart">
                      <S.NutritionFormCaloriesLineGraphContainer>
                        <LineChart
                          heading="Gesamtbedarf"
                          status={lineChartStatus}
                          description="in 12 Wochen"
                          fontSize={12}
                          precision={0}
                          padding={35}
                          width={447}
                          height={150}
                          data={data}
                          horizontalGuideLines={3}
                          yAxisBetweenRange
                        />
                      </S.NutritionFormCaloriesLineGraphContainer>
                    </div>
                    <div className="divider my-1-5-rem"></div>
                    <p className="text-size-16-24 text-dark-2 font-rubik-light">
                      <TranslationMessage id="nutrition.formCalories.description.p1" />
                      <br />
                      <br />
                      {caloriesData?.description}
                    </p>

                    <button
                      className="btn-outline mx-auto"
                      onClick={() => setEditCaloriesModal(true)}
                    >
                      Kalorien ändern
                    </button>
                  </>
                )}
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
      <Modal
        show={editCaloriesModal}
        setShow={setEditCaloriesModal}
        heading={
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="text-size-20-24 text-dark-1 text-left my-0">
              <TranslationMessage id="nutrition.formCalories.editCalories.heading" />
            </h5>
            <img
              className="cursor-pointer"
              src={CrossIcon}
              alt="CrossIcon"
              onClick={() => setEditCaloriesModal(false)}
            />
          </div>
        }
        width="500px"
        height="auto"
      >
        <S.EditCaloriesWeekHeadingContainer>
          <S.EditCaloriesNavigationIconContainer onClick={handleLeftNavigation}>
            <img src={LeftIcon} alt="left Icon" />
          </S.EditCaloriesNavigationIconContainer>
          <S.EditCaloriesWeekHeading>
            <TranslationMessage id="nutrition.formCalories.editCalories.week" />{" "}
            {weekNumber}
          </S.EditCaloriesWeekHeading>
          <S.EditCaloriesNavigationIconContainer
            onClick={handleRightNavigation}
          >
            <img src={RightIcon} alt="Right Icon" />
          </S.EditCaloriesNavigationIconContainer>
        </S.EditCaloriesWeekHeadingContainer>
        <S.EditCaloriesInputCard className="d-flex align-items-center justify-content-between">
          <h6 className="text-size-18-22 text-dark-1 my-0">
            <TranslationMessage id="input.label.restDay" />
          </h6>
          <IncrementInputModern
            width="200"
            placeholder="0 cm"
            id="cm"
            inputMeasure=""
            value={restDayCalories}
            setData={setRestDayCalories}
          />
        </S.EditCaloriesInputCard>
        <br />
        <S.EditCaloriesInputCard className="d-flex align-items-center justify-content-between">
          <h6 className="text-size-18-22 text-dark-1 my-0">
            <TranslationMessage id="input.label.trainingDay" />
          </h6>
          <IncrementInputModern
            width="200"
            placeholder="0 kg"
            id="kg"
            inputMeasure=""
            value={trainingDayCalories}
            setData={setTrainingDayCalories}
          />
        </S.EditCaloriesInputCard>
        <br />
        <Button
          color="rgba(46, 207, 150, .2)"
          onClick={handleCaloriesUpdate}
          gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
          disabled={
            parseInt(restDayCalories) === 0 ||
            parseInt(trainingDayCalories) === 0
          }
          margin="0 auto"
        >
          <TranslationMessage id="button.changeCalories" />
        </Button>
      </Modal>
    </>
  );
};

export default NutritionCalories;
