import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import Input from "components/formComponents/input/Input";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";

import CrossIcon from "assets/images/cross-icon-faq.svg";
import CopyIcon from "assets/images/copy-icon-black.svg";
import SearchIcon from "assets/images/search.svg";
import DownloadIcon from "assets/images/downloadBlack.svg";

import { MENUS } from "./SideMenu";
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as S from "./MarketingPortal.styles";

const TagOptions = [
  { value: 1, label: "PDF" },
  { value: 3, label: "Keynote" },
  { value: 4, label: "Powerpoint" },
];

const PresentationsAndBrochures = () => {
  const dispatch = useDispatch();
  const [SelectedTag, SetSelectedTag] = useState(null);
  const [SearchTerm, SetSearchTerm] = useState("");
  const [AllBrochures, SetAllBrochures] = useState([]);
  const [AllBrochuresClone, SetAllBrochuresClone] = useState([]);

  const marketingPortalAssets = useSelector(
    (state) => state.nutrition.marketingPortalAssets
  );

  const isMarketingPortalAssetsLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalAssetsLoading
  );

  useEffect(() => {
    getMarketingPortalAssets();
  }, [dispatch]);

  const getMarketingPortalAssets = () => {
    dispatch(
      NutritionActions.getMarketingPortalAssetsRequest({
        pyload: {},
        callback: (res) => {
          SetAllBrochures(res?.getAllMarketingPortalAssetsData);
          SetAllBrochuresClone(res?.getAllMarketingPortalAssetsData);
        },
      })
    );
  };

  const debouncedFilter = debounce((term) => filterMarketingAssets(term), 300);

  const onSearchInput = (e) => {
    SetSearchTerm(e.target.value);
    debouncedFilter(e.target.value);
  };

  const filterMarketingAssets = (term) => {
    if (!term) {
      SetAllBrochures(AllBrochuresClone);
      return;
    }

    const filteredBrochures = AllBrochures.filter((b) =>
      b?.title.toLowerCase().includes(term.toLowerCase())
    );
    console.log("filteredBrochures", filteredBrochures);
    SetAllBrochures(filteredBrochures);
  };

  const onResetSelectFilter = () => {
    SetSelectedTag(null);
    SetSearchTerm("");
    SetAllBrochures(AllBrochuresClone);
    // getMarketingPortalAssets();
  };

  const onSelectTagFilter = (b) => {
    console.log(b);
    SetSelectedTag(b);
    SetSearchTerm("");

    const brochures = AllBrochuresClone.filter(
      (item) => item?.tag?.toLowerCase() === b?.label?.toLowerCase()
    );

    SetAllBrochures(brochures);
  };

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <ToastContainer />
        <S.Wrapper className="second-sidebar-content">
          <S.TrainerProfileDetailsInnerContainer>
            <S.TrainerProfileDetailsSectionHeading>
              Präsentationen & Broschüren
            </S.TrainerProfileDetailsSectionHeading>
            <S.TrainerProfileDetailsSectionSubHeading>
              Lade dir Präsentationen, Broschüren und Flyer für dein Angebot als
              Foodiary Coach herunter.
            </S.TrainerProfileDetailsSectionSubHeading>
            <S.TrainerProfileDetailsSectionSubHeading>
              <div className="links">
                <strong>Links: </strong>
                <div
                  className="url-copy"
                  onClick={() => {
                    navigator.clipboard.writeText("https://lmy.de/KKyiK");
                    toast("📃 Erfolgreich kopiert.");
                  }}
                >
                  <img src={CopyIcon} alt="CopyIcon" />
                  <p>Werbevertrag PRO & PLUS</p>
                </div>
                <div
                  className="url-copy"
                  onClick={() => {
                    navigator.clipboard.writeText("https://lmy.de/UKdmz");
                    toast("📃 Erfolgreich kopiert.");
                  }}
                >
                  <img src={CopyIcon} alt="CopyIcon" />
                  <p>Werbevertrag PRO</p>
                </div>
              </div>
            </S.TrainerProfileDetailsSectionSubHeading>

            <S.Divider />
            <S.FiltersBox>
              <S.SelectedFiltersAndDropdown>
                <Select
                  options={TagOptions}
                  className="faq-select"
                  classNamePrefix="react-select"
                  onChange={onSelectTagFilter}
                  value={SelectedTag}
                  placeholder="Alle Kategorien"
                  isSearchable={false}
                />

                {SelectedTag && (
                  <S.SelectedFilter>
                    {SelectedTag?.label}
                    <img
                      src={CrossIcon}
                      alt="CrossIcon"
                      onClick={() => onResetSelectFilter()}
                    />
                  </S.SelectedFilter>
                )}
              </S.SelectedFiltersAndDropdown>
              <div>
                <S.FormControlSearch>
                  <Input
                    className="input-custom"
                    style={{ width: "100%", marginBottom: "0" }}
                    placeholder="Suche in der Bibliothek"
                    onChange={onSearchInput}
                    value={SearchTerm}
                  />
                  <img src={SearchIcon} alt="SearchIcon" />
                </S.FormControlSearch>
              </div>
            </S.FiltersBox>

            {isMarketingPortalAssetsLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}

            {!isMarketingPortalAssetsLoading && (
              <S.GridFourColumn>
                {AllBrochures?.map((b) => (
                  <S.BrochureCard>
                    <S.BrochureCardImage>
                      <img
                        className="card-img"
                        src={b?.preview_image}
                        alt="preview"
                      />
                      <img
                        className="download-icon"
                        src={DownloadIcon}
                        alt="DownloadIcon"
                        onClick={() => window.open(b?.download_file, "_blank")}
                      />
                      <div className="tag-label">{b?.tag}</div>
                    </S.BrochureCardImage>

                    <S.BrochureCardBody>
                      <div>
                        <h6>{b?.title}</h6>
                        <p>{b?.category}</p>
                      </div>
                    </S.BrochureCardBody>
                  </S.BrochureCard>
                ))}
              </S.GridFourColumn>
            )}
          </S.TrainerProfileDetailsInnerContainer>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default PresentationsAndBrochures;
