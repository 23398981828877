import One from "assets/images/one.svg";
import Two from "assets/images/two.svg";
import Three from "assets/images/three.svg";

import Slimming from "assets/images/slimming.svg";
import HealthDiet from "assets/images/healthDiet.svg";
import MuscleBuilding from "assets/images/muscleBuilding.svg";
import MuscleBuildingBulk from "assets/images/muscleBuildingBulk.svg";

import Vegetarisch from "assets/images/Onboarding/Screen6/Icon-Vegetarisch.png";
import Vegan from "assets/images/Onboarding/Screen6/Icon-Vegan.png";
import Meat from "assets/images/Onboarding/Screen6/Icon-meat.png";

import * as Data from "../registration/registration.utils";

export const SidebarSteps = [
  {
    id: 1,
    title: "Vorstellung",
    description: "Lerne Foodiary und meinen Hintergrund kennen.",
    isActive: true,
  },
  {
    id: 2,
    title: "Anamnese",
    description: "Wir erstellen deinen Ernährungsplan.",
    isActive: false,
  },
  {
    id: 3,
    title: "Auswertung",
    description: "Ich zeige dir die einzelnen Bestandteile.",
    isActive: false,
  },
  {
    id: 4,
    title: "Tipps & Tricks",
    description: "Wichtige Grundlagen für eine gesunde Ernährung.",
    isActive: false,
  },
  {
    id: 5,
    title: "Start",
    description: "Du Entscheidest, ob wir gemeinsam starten.",
    isActive: false,
  },
];

export const OnboardingInterviewSteps = [
  {
    id: 1,
    title: "Erstellen deines Ernährungsplanes",
    description: `Wir evaluieren deinen IST-Zustand und erstellen auf
    Grundlage deines Zieles, den täglichen Gewohnheiten und
    Wünschen dein Ernährungsplan.`,
    img: One,
  },
  {
    id: 2,
    title: "Vorstellen deines Ernährungsplanes",
    description: `Ich werde dir deinen Ernährungsplan erklären. Hierbei gehe ich auf deinen Kalorienbedarf, die Ernährungsform und die Rezeptauswahl ein.`,
    img: Two,
  },
  {
    id: 3,
    title: "Tipps & Tricks für die Ernährungsumstellung",
    description: `Du erhältst wichtige Informationen, wie du deinen Ernährungsplan gesund umsetzen kannst und welche Tipps & Tricks dir dabei helfen.`,
    img: Three,
  },
];

export const AppFunctions = [
  {
    id: 1,
    title: "Rezeptﬁlter mit künstlicher Intelligenz",
    description:
      "Dank künstlicher Intelligenz treffen wir eine Vorauswahl an Rezepten, welche zu deiner Ernährungsform passen und integrieren diese in deinen Tag.",
    badge: "",
  },
  {
    id: 2,
    title: "Plane deine Woche vor",
    description:
      "Deine Woche kannst du einfach vorplanen. So wählst du in deinem Ernährungsplan die Rezepte aus, welche du gerne zubereiten möchtest.",
    badge: "",
  },
  {
    id: 3,
    title: "Optimierung deines Ernährungsplans",
    description:
      "Mithilfe der Empfehlung für Nahrungsergänzungsmittel, welche dir zu den optimalen Zeiten angezeigt wird, kannst du deinen Ernährungsplan weiter optimieren und deine Ziele einfacher erreichen.",
    badge: "",
  },
  {
    id: 4,
    title: "Individuelle Tagesplanung",
    description:
      "Während des Ernährungsplanes hast du die Möglichkeit verschiedene Tagesabläufe auszuwählen. Diese legst du zu Beginn an und kannst diese täglich wechseln oder als wiederkehrende Routine speichern.",
    badge: "PRO",
  },
  {
    id: 5,
    title: "Intelligente Einkaufsliste",
    description:
      "Vorgeplante Rezepte werden automatisch in unsere intelligente Einkaufsliste gelegt. Diese kannst du bequem beim Einkauf abrufen und dir anzeigen lassen, welche Mengen an Lebensmitteln du für die geplante Woche benötigst.",
    badge: "",
  },
  {
    id: 6,
    title: "Dynamische Kalorienanpassung",
    description:
      "Durch die dynamische Kalorienanpassung gehen wir auf Veränderungen in deinem Stoffwechsel ein. Das ist für dich wichtig, um langfristig dein Ziel zu erreichen.",
    badge: "PRO",
  },
];

export const DashbaordFunctions = [
  {
    id: 1,
    title: "Kostenrückerstattung",
    description:
      "Die Krankenkasse übernimmt bis zu 100% der Kosten bei unserem digitalen Coaching PLUS.",
  },
  {
    id: 2,
    title: "12 Wochen Online Kurs",
    description:
      "In 8 Lektionen lernst du die Grundlagen einer gesunden Ernährung kennen. Interaktive Videos mit Handouts und Wissensquiz.",
  },
  {
    id: 3,
    title: "Lernacademy mit Weeklys",
    description:
      "In E-Books und Artikeln vertiefst du dein Wissen. Bei unseren Weeklys beantwortet dir ein Foodiary Coach alle Fragen.",
  },
];

export const Goals = [
  {
    id: 1,
    title: "Abnehmen",
    description: "Wir reduzieren deine Kalorien für ein Defizit.",
    img: Slimming,
  },
  {
    id: 2,
    title: "Gesunde Ernährung",
    description: "Deine Kalorien entsprechen dem Gesamtumsatz.",
    img: HealthDiet,
  },
  {
    id: 3,
    title: "Muskelaufbau",
    description: "Dein Kalorien befinden sich über dem Gesamtumsatz.",
    img: MuscleBuilding,
  },
  {
    id: 4,
    title: "Muskelaufbau pro",
    description: "Deine Kalorien werden deutlich erhöht.",
    img: MuscleBuildingBulk,
  },
];

export const DailyActivitiesTime = [
  {
    id: "registration.activityLevel.sleep",
    title: "Schlafen",
  },
  {
    id: "registration.activityLevel.lyingDown",
    title: "Liegen",
  },
  {
    id: "registration.activityLevel.standing",
    title: "Stehen",
  },
  {
    id: "registration.activityLevel.siting",
    title: "Sitzen",
  },
  {
    id: "registration.activityLevel.walking",
    title: "Gehen",
  },
  {
    id: "registration.activityLevel.exercising",
    title: "Trainieren",
  },
];

export const TrainingDaysData = [
  {
    id: 1,
    title: "Montag",
    isActive: false,
  },
  {
    id: 2,
    title: "Dienstag",
    isActive: false,
  },
  {
    id: 3,
    title: "Mittwoch",
    isActive: false,
  },
  {
    id: 4,
    title: "Donnerstag",
    isActive: false,
  },
  {
    id: 5,
    title: "Freitag",
    isActive: false,
  },
  {
    id: 6,
    title: "Samstag",
    isActive: false,
  },
  {
    id: 7,
    title: "Sonntag",
    isActive: false,
  },
];

export const DietCategoriesList = [
  {
    id: 1,
    title: "Vegetarisch",
    img: Vegetarisch,
  },
  {
    id: 2,
    title: "Vegan",
    img: Vegan,
  },
  {
    id: 3,
    title: "Fleischhaltig",
    img: Meat,
  },
];

export const DietItemsLists = {
  "vegan-1": Data.eatingHabits1Data,
  "vegan-2": Data.eatingHabits2veganData,
  "vegan-3": Data.eatingHabits3veganData,
  "vegan-4": Data.eatingHabits4veganData,
  "vegan-5": Data.eatingHabits5veganData,
  "vegetarisch-1": Data.eatingHabits1Data,
  "vegetarisch-2": Data.eatingHabits2vegetarianData,
  "vegetarisch-3": Data.eatingHabits3vegetarianData,
  "vegetarisch-4": Data.eatingHabits4vegetarianData,
  "vegetarisch-5": Data.eatingHabits5vegetarianData,
  "fleischhaltig-1": Data.eatingHabits1Data,
  "fleischhaltig-2": Data.eatingHabits2Data,
  "fleischhaltig-3": Data.eatingHabits3Data,
  "fleischhaltig-4": Data.eatingHabits4Data,
  "fleischhaltig-5": Data.eatingHabits5Data,
};

export const AddDailyRoutineValues = [
  { id: "input.label.getup", hrs: "07" },
  { id: "input.label.lunchTime", hrs: "12" },
  { id: "input.label.workoutTime", hrs: "17" },
  { id: "input.label.bedTime", hrs: "22" },
];

export const AccordionContentData = [
  { id: "registration.dailyRoutine.getup" },
  { id: "registration.dailyRoutine.lunch" },
  { id: "registration.dailyRoutine.workoutTime" },
  { id: "registration.dailyRoutine.bedTime" },
];
