import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "redux/store";
import { IntlProvider } from "react-intl";
import { Provider as AlertProvider, positions } from "react-alert";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";
import "assets/style.css";
import "assets/onboarding.scss";
import "assets/styles.scss";
import GlobalStyles from "./GlobalStyles";
import { messages, language } from "configs/translations";
import Alert from "components/formComponents/Alert/Alert";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ToastContainer } from "react-toastify";

const AlertTemplate = ({ style, options, message, close }) => (
  <div className="custom-alert" style={style}>
    <Alert
      isSuccess={options.type === "success"}
      isError={options.type === "error"}
      message={message}
      containerMargin="200px 0 0 0"
    />
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={language} messages={messages[language]}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <GlobalStyles />
          <AlertProvider
            template={AlertTemplate}
            position={positions.TOP_RIGHT}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </AlertProvider>
        </PersistGate>
      </Provider>
    </IntlProvider>
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
