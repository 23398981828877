import styled from "styled-components";
import {
  LoginHeading,
  LoginInnerContainer,
  LoginSubHeading,
} from "pages/login/login.styles";
import Tick from "assets/images/tick.png";

export const LoginContainer = styled.div`
 display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#bae3db, #6fc2b4);
  height: 100vh;

  @media screen and (max-width: 600px) {
    height: 100%;
    padding: 2rem 1rem;
  }

  @media screen and (min-height: 650px) {
    height: 100vh;
  }
`;

export const SliderContainer = styled.div`
  max-width: 50vw;
  width: 100%;
  background: ${(props) => `url(${props.imgUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vh - 2rem);
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;

  @media screen and (max-width: 992px) {
    display: none;
  }

  .slick-arrow {
    display: none !important;
  }

  .slide-item {
    img {
      border-radius: 18px;
      height: calc(100vh - 252px);
      width: 100%;
      border: 6px solid #ffffff47;
      object-fit: cover;
      object-position: left;
    }

    p,
    h4 {
      color: white;
      max-width: 550px;
      margin-inline: auto;
      text-align: center;
    }

    h4 {
      font-size: 24px;
      font-weight: 400;
    }

    p {
      font-size: 16px;
      font-weight: 300;
    }
  }
`;
export const ChangePasswordInnerContainer = styled.div`
  padding: 2.5rem 4.5rem;;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  .route-links {
    display: flex;
    flex-direction: column;
    gap: 16px;

    span {
      color: #525866;
    }
    a {
      color: #142c1f;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #2ecf96;
      }
    }
    span,
    a {
      font-size: 16px;
      line-height: 22px;
      font-weight: 300;
    }
  }
`;

export const ChangePasswordHeading = styled.h4`
  color: #142c1f;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  margin: 16px 0 0;
`;

export const ChangePasswordSubHeading = styled.h6`
  color: #44554c;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin: 0.7rem 0 20px;
`;

export const AlertSuccess = styled.div`
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  margin: 0 0 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0.5rem 1rem;
`;

export const AlertDanger = styled.div`
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  margin: 0 0 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0.5rem 1rem;
`;

export const SelfMadeCheckBoxRow = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: 1rem;
  span {
    cursor: pointer;
    color: #44554c;
    font-weight: 300;
  }
  .form-control {
    color: #142c1f;
    font-size: 1rem;
    line-height: 1.1875rem;
    font-weight: 300;
    display: grid;
    grid-template-columns: 28px 1fr;
    align-items: center;
    gap: 0.325rem;
  }

  .form-control + .form-control {
    margin-top: 1em;
  }

  .form-control--disabled {
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }

  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.25rem;
    height: 1.25rem;
    border: 0.125rem solid #a5b5af;
    border-radius: 5px;
    transform: translateY(-0.075em);
    background-color: #fff;

    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.7rem;
    height: 0.7rem;
    background-position: center;
    background-size: contain;
    background-image: url("${Tick}");
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }
  input[type="checkbox"]:checked {
    background-color: #2ecf96;
    border-color: #2ecf96;
  }
  input[type="checkbox"]:checked + span {
    text-decoration: line-through;
  }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  span {
    font-weight: 400;
  }

  &.line-through {
    text-decoration: line-through;
  }
`;
