import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "components/formComponents/button/Button";
import DonutChart from "components/donutChart/DonutChart";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

import ArrowLeft from "assets/images/arrowLeft.svg";

// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as S from "../OnBoardingPartTwo.styles";

const MacroNutrients = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const registrationData = useSelector((state) => state.auth.registrationData);
  const donutChartData = useSelector(
    (state) => state.nutrition.macroNutrientsDonutChartData
  );
  const macroNutrientsPieChartData = useSelector(
    (state) => state.nutrition.macroNutrientsPieChartData
  );

  useEffect(() => {
    dispatch(
      NutritionActions.getMacroNutrientsPieChartDataRequest({
        payload: {
          nutritiontypeID: getRegistrationData()?.nutritiontypeID,
        },
        callback: (res) => {},
      })
    );
  }, [dispatch, registrationData]);

  const values = macroNutrientsPieChartData && [
    {
      label: "Kohlenhydrate",
      value: macroNutrientsPieChartData?.pieChartData[0]?.carbohydratesData,
    },
    {
      label: "Fett",
      value: macroNutrientsPieChartData?.pieChartData[0]?.fatData,
    },
    {
      label: "Eiweiß",
      value: macroNutrientsPieChartData?.pieChartData[0]?.proteinData,
    },
  ];

  const values2 = macroNutrientsPieChartData && [
    {
      label: "Kohlenhydrate",
      value: macroNutrientsPieChartData?.pieChartData[1]?.carbohydratesData,
    },
    {
      label: "Fett",
      value: macroNutrientsPieChartData?.pieChartData[1]?.fatData,
    },
    {
      label: "Eiweiß",
      value: macroNutrientsPieChartData?.pieChartData[1]?.proteinData,
    },
  ];

  const getCurrentNutritionType = (id) => {
    const obj = {
      1: "Anabole Diät",
      2: "Metabole Diät",
      3: "High Carb",
      4: "Low Carb",
    };

    return obj[id];
  };

  const getRegistrationData = () => {
    const RD = JSON.parse(localStorage.getItem("RegistrationData"));
    return RD;
  };

  const handleBack = () => {
    history.push("/onboarding/nutrition/calories");
  };

  const handleNext = () => {
    history.push("/onboarding/nutrition/done");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={3} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Auswertung
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Unsere Empfehlung für dich
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="nutrition-macro-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium mt-0">
                  Deine persönliche Nährstoffverteilung
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Deine Makronährstoffe haben wir anhand deiner Ernährungsform
                  bestimmt.
                  <br />
                  <br />
                  Bei deiner Ernährungsform{" "}
                  {getCurrentNutritionType(
                    getRegistrationData()?.nutritiontypeID
                  )}{" "}
                  setzen sich deine Kalorien aus folgender Verteilung zusammen:
                </p>
                <br />
                <div className="macro-nutrients-charts">
                  {loading && (
                    <div className="w-100">
                      <br />
                      <br />
                      <br />
                      <br />
                      <div className="text-center">
                        <div className="loader mx-auto"></div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  )}
                  {!loading && (
                    <div className="macro-nutrients-charts-box gap-3">
                      {macroNutrientsPieChartData && (
                        <>
                          <S.DonutContainer className="chart-container">
                            <h6 className="text-size-16-28 text-dark-1 text-center mb-0 mt-3">
                              Trainingsfrei
                            </h6>
                            <div className="chart-box">
                              <DonutChart
                                series={values}
                                key={"chart-donut-1"}
                              />
                            </div>
                          </S.DonutContainer>
                          <S.DonutContainer className="chart-container">
                            <h6 className="text-size-16-28 text-dark-1 text-center mb-0 mt-3">
                              Trainingstag
                            </h6>
                            <div className="chart-box">
                              <DonutChart
                                series={values2}
                                key={"chart-donut-2"}
                              />
                            </div>
                          </S.DonutContainer>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default MacroNutrients;
