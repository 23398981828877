import AuthActionTypes from "redux/auth/auth.types";

/*************************************************/

const defaultRegistrationData = {
  sex: "male",
  aimID: 1,
  height: "0",
  weight: "0",
  nutritiontypeID: 2,
  birthday: "",
  email: "",
  prename: "",
  surname: "",
  grant_type: "password",
  isTraining: true,
  trainingdays: [],
  totalTrainingDays: 0,
  routines: {},
  dailyRoutineData: [],
  movementtypes: [],
  mobile: "",
  sleepValue: 0,
  lyingDownValue: 0,
  standingValue: 0,
  sittingValue: 0,
  walkingValue: 0,
  exercisingValue: 0,
  eatingHabit1: "registration.eatingHabit.yoghurtWithFruit",
  eatingHabit2: "registration.eatingHabit.apple",
  eatingHabit3: "registration.eatingHabit.Avocado",
  eatingHabit4: "registration.eatingHabit.Oatmeal",
  eatingHabit5: "registration.eatingHabit.chilliConCarne",
};

const INITIAL_STATE = {
  token: localStorage.getItem("token"),
  trainer_token: localStorage.getItem("trainer_token"),
  signInResponse: null,
  error: "",
  registrationData: defaultRegistrationData,
  loading: false,
  trainerSignUpData: {},
  trainerData: {},
  trainerDataRes: null,
  userData: {},
  invoiceData: {},
  userInvoice: {},
  influencers: null,
  isInfluencersLoading: false,
  influencerDetails: null,
  isInfluencerDetailsLoading: false,
  firstMeetingURLByTrainerID: null,
  weeklyCalls: null,
  isWeeklyCallsLoading: false,
  healthScoreData: {
    question1: false,
    question2: false,
    question3: false,
    question4: false,
    question5: false,
    button1: false,
    button2: false,
    button3: false,
    button4: false,
    button5: false,
    button6: false,
    button7: false,
    button8: false,
    button9: false,
    button10: false,
    sliderPosition: null,
    supplementsStatus: [],
    foodiaryPro: false,
    payment_type: "permanent",
    totalSupplementsCost: 0.0,
  },
  usersRelatedToTrainer: null,
  userDailyRoutineData: null,
  isUserDailyRoutineDataLoading: false,
  isUserDailyRoutineDataUpdating: false,
  isUserDailyRoutineDataAdding: false,
  userWeightData: null,
  createWebsite: null,
  isWebsiteCreating: null,
  feedback: null,
  isPasswordReseting: null,
  passwordReset: null,
  isWeekWiseImagesLoading: false,
  weekWiseImages: null,
  isBodyMeasurementsLoading: false,
  bodyMeasurements: null,
  isWeekWiseWeightLoading: false,
  weekWiseWeight: null,
  isUserWeightGoalDataLoading: false,
  userWeightGoalData: null,
  accountConnectedDetails: null,
  useraccountConnectedDetails: null,
  userAnamneses: null,
  createUserAnamnese: null,
  isLessonsCategoriesLoading: false,
  lessonsCategories: null,
  isLessonsByCategoryIdLoading: false,
  lessonsByCategoryId: null,
  isLessonDetailsLoading: false,
  lessonDetails: null,
  isDownloadableLessonsLoading: false,
  downloadableLessons: null,

  isUpdateTrainerDetailsLoading: false,
  isGetTrainerDetailsLoading: false,
  isGetCoachCardDetailsLoading: false,
  isUpdateCoachCardDetailsLoading: false,

  isAddCoachCardQualificationsLoading: false,
  isUpdateCoachCardQualificationsLoading: false,
  isRemoveCoachCardQualificationsLoading: false,
  isGetCoachCardQualificationsLoading: false,

  instagramPostsByCategory: null,
  isInstagramPostsByCategoryLoading: false,

  instagramSubCategoriesByCategory: null,
  isInstagramSubCategoriesByCategoryLoading: false,

  allInstagramCategories: null,
  isAllInstagramCategoriesLoading: false,

  allInstagramPosts: null,
  isAllInstagramPostsLoading: false,

  instagramPosts: [],

  addressDetails: null,
  isLoadingAddressDetails: false,

  updateAddressDetails: null,
  isAddressDetailsUpdating: false,

  isCoachPasswordReseting: false,
  resetCoachPassword: null,

  isUserPasswordSeting: false,
  setUserPassword: null,

  getClientDetails: null,
  isClientDetailsLoading: true,
};

/*************************************************/

const userReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case AuthActionTypes.SET_DAILY_ROUTINE_DATA:
      return {
        ...state,
        registrationData: {
          ...state.registrationData,
          dailyRoutineData: action.payload?.updatedRoutines,
        },
      };
    case AuthActionTypes.SET_REGISTRATION_DATA:
      return {
        ...state,
        registrationData: { ...state.registrationData, ...action.payload },
      };
    case AuthActionTypes.RESET_REGISTRATION_DATA:
      return {
        ...state,
        registrationData: defaultRegistrationData,
      };
    case AuthActionTypes.SET_HEALTH_SCORE_DATA:
      return {
        ...state,
        healthScoreData: { ...state.healthScoreData, ...action.payload },
      };
    case AuthActionTypes.REGISTRATION_PART1_SUCCESS:
      return {
        ...state,
        token: action.payload,
        error: "",
      };
    case AuthActionTypes.REGISTRATION_PART1_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case AuthActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        trainer_token: action.payload?.data?.token || null,
        signInResponse: action.payload,
      };
    case AuthActionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        trainer_token: null,
        signInResponse: action.payload,
      };
    case AuthActionTypes.SIGN_IN_NULL:
      return {
        ...state,
        trainer_token: null,
        signInResponse: null,
      };
    case AuthActionTypes.SIGN_IN_EXISTING_PLAN_SUCCESS:
      return {
        ...state,
        token: action.payload,
      };
    case AuthActionTypes.TRAINER_SIGN_UP_SUCCESS:
      return {
        ...state,
        trainer_token: action.payload,
      };
    case AuthActionTypes.UPDATE_TRAINER_DETAILS_SUCCESS:
      return {
        ...state,
        trainerDataRes: { ...action.payload },
      };
    case AuthActionTypes.TRAINER_DETAILS_EDIT_SUCCESS:
      return {
        ...state,
        trainerData: { ...state.trainerData, ...action.payload.data },
      };

    case AuthActionTypes.GET_USERS_BY_TRAINER_SUCCESS:
      return {
        ...state,
        usersRelatedToTrainer: action.payload,
      };
    case AuthActionTypes.GET_TRAINER_PROFILE_SUCCESS:
    case AuthActionTypes.GET_TRAINER_DETAILS_SUCCESS:
      return {
        ...state,
        trainerData: { ...state.trainerData, ...action.payload },
      };

    case AuthActionTypes.GET_COACH_CARD_WEBSITE_DETAILS_SUCCESS:
      return {
        ...state,
        trainerData: { ...state.trainerData, ...action.payload },
      };

    case AuthActionTypes.GET_COACH_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        trainerData: { ...state.trainerData, ...action.payload },
      };

    case AuthActionTypes.GET_USER_DETAILS_BY_TRAINER_SUCCESS:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
      };
    case AuthActionTypes.GET_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        getClientDetails: action.payload,
      };
    case AuthActionTypes.GET_CLIENT_DETAILS_LOADING_START:
      return {
        ...state,
        isClientDetailsLoading: true,
      };
    case AuthActionTypes.GET_CLIENT_DETAILS_LOADING_STOP:
      return {
        ...state,
        isClientDetailsLoading: false,
      };

    case AuthActionTypes.GET_INVOICES_BY_TRAINER_SUCCESS:
      return {
        ...state,
        invoiceData: { ...state.invoiceData, ...action.payload },
      };

    case AuthActionTypes.CLEAR_INVOICES_BY_TRAINER:
      return {
        ...state,
        invoiceData: {},
      };

    case AuthActionTypes.GET_INVOICES_OF_USER_BY_TRAINER_SUCCESS:
      return {
        ...state,
        userInvoice: { ...action.payload },
      };
    case AuthActionTypes.ADD_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_START:
      return {
        ...state,
        isUserDailyRoutineDataAdding: true,
      };
    case AuthActionTypes.ADD_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_STOP:
      return {
        ...state,
        isUserDailyRoutineDataAdding: false,
      };
    case AuthActionTypes.ADD_DAYS_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS:
    case AuthActionTypes.UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_START:
      return {
        ...state,
        isUserDailyRoutineDataUpdating: true,
      };
    case AuthActionTypes.UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_STOP:
      return {
        ...state,
        isUserDailyRoutineDataUpdating: false,
      };
    case AuthActionTypes.UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS:
    case AuthActionTypes.GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_START:
      return {
        ...state,
        isUserDailyRoutineDataLoading: true,
      };
    case AuthActionTypes.GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_STOP:
      return {
        ...state,
        isUserDailyRoutineDataLoading: false,
      };
    case AuthActionTypes.GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS:
      return {
        ...state,
        userDailyRoutineData: action.payload,
      };
    case AuthActionTypes.GET_WEIGHT_GRAPH_DETAILS_SUCCESS:
      return {
        ...state,
        userWeightData: action.payload,
      };
    case AuthActionTypes.SIGN_OUT_SUCCESS:
      return {
        trainer_token: null,
        token: null,
        ...INITIAL_STATE,
      };
    case AuthActionTypes.LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case AuthActionTypes.LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    case AuthActionTypes.CREATE_WEBSITE_LOADING_START:
      return {
        ...state,
        isWebsiteCreating: true,
      };
    case AuthActionTypes.CREATE_WEBSITE_LOADING_STOP:
      return {
        ...state,
        isWebsiteCreating: false,
      };
    case AuthActionTypes.GET_TRAINER_DETAILS_LOADING_START:
      return {
        ...state,
        isGetTrainerDetailsLoading: true,
      };
    case AuthActionTypes.GET_TRAINER_DETAILS_LOADING_STOP:
      return {
        ...state,
        isGetTrainerDetailsLoading: false,
      };
    case AuthActionTypes.UPDATE_TRAINER_DETAILS_LODING_START:
      return {
        ...state,
        isUpdateTrainerDetailsLoading: true,
      };
    case AuthActionTypes.UPDATE_TRAINER_DETAILS_LOADING_STOP:
      return {
        ...state,
        isUpdateTrainerDetailsLoading: false,
      };
    case AuthActionTypes.GET_COACH_CARD_DETAILS_LOADING_START:
      return {
        ...state,
        isGetCoachCardDetailsLoading: true,
      };
    case AuthActionTypes.GET_COACH_CARD_DETAILS_LOADING_STOP:
      return {
        ...state,
        isGetCoachCardDetailsLoading: false,
      };
    case AuthActionTypes.UPDATE_COACH_CARD_DETAILS_LOADING_START:
      return {
        ...state,
        isUpdateCoachCardDetailsLoading: true,
      };
    case AuthActionTypes.UPDATE_COACH_CARD_DETAILS_LOADING_STOP:
      return {
        ...state,
        isUpdateCoachCardDetailsLoading: false,
      };

    case AuthActionTypes.ADD_COACH_CARD_QUALIFICATION_LOADING_START:
      return {
        ...state,
        isAddCoachCardQualificationsLoading: true,
      };
    case AuthActionTypes.ADD_COACH_CARD_QUALIFICATION_LOADING_STOP:
      return {
        ...state,
        isAddCoachCardQualificationsLoading: false,
      };
    case AuthActionTypes.UPDATE_COACH_CARD_QUALIFICATION_LOADING_START:
      return {
        ...state,
        isUpdateCoachCardQualificationsLoading: true,
      };
    case AuthActionTypes.UPDATE_COACH_CARD_QUALIFICATION_LOADING_STOP:
      return {
        ...state,
        isUpdateCoachCardQualificationsLoading: false,
      };
    case AuthActionTypes.REMOVE_COACH_CARD_QUALIFICATION_LOADING_START:
      return {
        ...state,
        isRemoveCoachCardQualificationsLoading: true,
      };
    case AuthActionTypes.REMOVE_COACH_CARD_QUALIFICATION_LOADING_STOP:
      return {
        ...state,
        isRemoveCoachCardQualificationsLoading: false,
      };
    case AuthActionTypes.GET_COACH_CARD_QUALIFICATION_LOADING_START:
      return {
        ...state,
        isGetCoachCardQualificationsLoading: true,
      };
    case AuthActionTypes.GET_COACH_CARD_QUALIFICATION_LOADING_STOP:
      return {
        ...state,
        isGetCoachCardQualificationsLoading: false,
      };

    case AuthActionTypes.CREATE_WEBSITE_SUCCESS:
      return {
        ...state,
        createWebsite: action.payload,
      };
    case AuthActionTypes.CREATE_WEBSITE_FAILURE:
      return {
        ...state,
        createWebsite: action.payload,
      };
    case AuthActionTypes.TRAINER_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: action.payload,
      };
    case AuthActionTypes.TRAINER_FEEDBACK_FAILURE:
      return {
        ...state,
        feedback: action.payload,
      };
    case AuthActionTypes.PASSWORD_RESET_LOADING_START:
      return {
        ...state,
        isPasswordReseting: true,
      };
    case AuthActionTypes.PASSWORD_RESET_LOADING_STOP:
      return {
        ...state,
        isPasswordReseting: false,
      };
    case AuthActionTypes.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordReset: action.payload,
      };
    case AuthActionTypes.PASSWORD_RESET_FAILURE:
      return {
        ...state,
        passwordReset: action.payload,
      };
    case AuthActionTypes.GET_WEEK_WISE_IMAGES_LOADING_START:
      return {
        ...state,
        isWeekWiseImagesLoading: true,
      };
    case AuthActionTypes.GET_WEEK_WISE_IMAGES_LOADING_STOP:
      return {
        ...state,
        isWeekWiseImagesLoading: false,
      };
    case AuthActionTypes.GET_WEEK_WISE_IMAGES_SUCCESS:
      return {
        ...state,
        weekWiseImages: action.payload,
      };
    case AuthActionTypes.GET_BODY_MEASUREMENTS_LOADING_START:
      return {
        ...state,
        isBodyMeasurementsLoading: true,
      };
    case AuthActionTypes.GET_BODY_MEASUREMENTS_LOADING_STOP:
      return {
        ...state,
        isBodyMeasurementsLoading: false,
      };
    case AuthActionTypes.GET_BODY_MEASUREMENTS_SUCCESS:
      const weeks = Object.values(action.payload?.data || {});
      const lastWeek = weeks.at(-1) || [],
        secondLastWeek = weeks.at(-2) || [];
      let result = [];
      console.log("weeks", weeks);
      console.log("lastWeek", lastWeek);
      console.log("secondLastWeek", secondLastWeek);
      
      if (action.payload?.data) {
        for (let index = 0; index < lastWeek.length; index++) {
          result.push({
            ...lastWeek[index],
            valueDifference:
              lastWeek[index]?.value - secondLastWeek[index]?.value || 0,
            isIncreased:
              lastWeek[index]?.value > secondLastWeek[index]?.value || false,
            isEqual:
              lastWeek[index]?.value === secondLastWeek[index]?.value || false,
            isEmpty: false,
          });
        }
      } else {
        const date = new Date().toISOString().split("T")[0];
        result = [
          {
            name: "Brust",
            value: 0,
            date,
            valueDifference: 0,
            isIncreased: false,
            isEqual: false,
            isEmpty: true,
          },
          {
            name: "Hals",
            value: 0,
            date,
            valueDifference: 0,
            isIncreased: false,
            isEqual: false,
            isEmpty: true,
          },
          {
            name: "Oberarm",
            value: 0,
            date,
            valueDifference: 0,
            isIncreased: false,
            isEqual: false,
            isEmpty: true,
          },
          {
            name: "Oberschenkel",
            value: 0,
            date,
            valueDifference: 0,
            isIncreased: false,
            isEqual: false,
            isEmpty: true,
          },
          {
            name: "Taille",
            value: 0,
            date,
            valueDifference: 0,
            isIncreased: false,
            isEqual: false,
            isEmpty: true,
          },
        ];
      }

      return {
        ...state,
        bodyMeasurements: result,
      };
    case AuthActionTypes.GET_USER_WEIGHT_WEEK_WISE_LOADING_START:
      return {
        ...state,
        isWeekWiseWeightLoading: true,
      };
    case AuthActionTypes.GET_USER_WEIGHT_WEEK_WISE_LOADING_STOP:
      return {
        ...state,
        isWeekWiseWeightLoading: false,
      };
    case AuthActionTypes.GET_USER_WEIGHT_WEEK_WISE_SUCCESS:
      return {
        ...state,
        weekWiseWeight: action.payload,
      };
    case AuthActionTypes.GET_USER_WEIGHT_GOAL_DATA_LOADING_START:
      return {
        ...state,
        isUserWeightGoalDataLoading: true,
      };
    case AuthActionTypes.GET_USER_WEIGHT_GOAL_DATA_LOADING_STOP:
      return {
        ...state,
        isUserWeightGoalDataLoading: false,
      };
    case AuthActionTypes.GET_USER_WEIGHT_GOAL_DATA_SUCCESS:
      return {
        ...state,
        userWeightGoalData: action.payload,
      };
    case AuthActionTypes.GET_INFLUENCERS_LOADING_START:
      return {
        ...state,
        isInfluencersLoading: true,
      };
    case AuthActionTypes.GET_INFLUENCERS_LOADING_STOP:
      return {
        ...state,
        isInfluencersLoading: false,
      };
    case AuthActionTypes.GET_INFLUENCERS_SUCCESS:
      return {
        ...state,
        influencers: action.payload,
      };
    case AuthActionTypes.GET_INFLUENCER_DETAILS_LOADING_START:
      return {
        ...state,
        isInfluencerDetailsLoading: true,
      };
    case AuthActionTypes.GET_INFLUENCER_DETAILS_LOADING_STOP:
      return {
        ...state,
        isInfluencerDetailsLoading: false,
      };
    case AuthActionTypes.GET_INFLUENCER_DETAILS_SUCCESS:
      return {
        ...state,
        influencerDetails: action.payload,
      };
    case AuthActionTypes.GET_FIRST_MEETING_URL_BY_TRAINER_ID_SUCCESS:
      return {
        ...state,
        firstMeetingURLByTrainerID: action.payload,
      };

    case AuthActionTypes.GET_WEEKLY_CALLS_FOR_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        weeklyCalls: action.payload,
      };
    case AuthActionTypes.GET_WEEKLY_CALLS_FOR_SUGGESTIONS_LOADING_START:
      return {
        ...state,
        isWeeklyCallsLoading: true,
      };
    case AuthActionTypes.GET_WEEKLY_CALLS_FOR_SUGGESTIONS_LOADING_STOP:
      return {
        ...state,
        isWeeklyCallsLoading: false,
      };
    case AuthActionTypes.UPDATE_CONNECTED_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountConnectedDetails: action.payload?.data || null,
      };
    case AuthActionTypes.UPDATE_CONNECTED_ACCOUNT_CLEAR:
      return {
        ...state,
        accountConnectedDetails: null,
      };
    case AuthActionTypes.UPDATE_USER_CONNECTED_ACCOUNT_SUCCESS:
      return {
        ...state,
        useraccountConnectedDetails: action.payload?.data || null,
      };
    case AuthActionTypes.UPDATE_USER_CONNECTED_ACCOUNT_CLEAR:
      return {
        ...state,
        useraccountConnectedDetails: null,
      };
    case AuthActionTypes.GET_USER_ANAMNESE_SUCCESS:
      return {
        ...state,
        userAnamneses: action.payload,
      };
    case AuthActionTypes.CREATE_USER_ANAMNESE_SUCCESS:
      return {
        ...state,
        createUserAnamnese: action.payload,
      };

    case AuthActionTypes.GET_LESSONS_CATEGORIES_LOADING_START:
      return {
        ...state,
        isLessonsCategoriesLoading: true,
      };
    case AuthActionTypes.GET_LESSONS_CATEGORIES_LOADING_STOP:
      return {
        ...state,
        isLessonsCategoriesLoading: false,
      };
    case AuthActionTypes.GET_LESSONS_CATEGORIES_SUCCESS:
      return {
        ...state,
        lessonsCategories: action.payload,
      };

    case AuthActionTypes.GET_LESSONS_BY_CATEGORY_ID_LOADING_START:
      return {
        ...state,
        isLessonsByCategoryIdLoading: true,
      };
    case AuthActionTypes.GET_LESSONS_BY_CATEGORY_ID_LOADING_STOP:
      return {
        ...state,
        isLessonsByCategoryIdLoading: false,
      };
    case AuthActionTypes.GET_LESSONS_BY_CATEGORY_ID_SUCCESS:
      return {
        ...state,
        lessonsByCategoryId: action.payload,
      };

    case AuthActionTypes.GET_LESSON_DETAILS_LOADING_START:
      return {
        ...state,
        isLessonDetailsLoading: true,
      };
    case AuthActionTypes.GET_LESSON_DETAILS_LOADING_STOP:
      return {
        ...state,
        isLessonDetailsLoading: false,
      };
    case AuthActionTypes.GET_LESSON_DETAILS_SUCCESS:
      return {
        ...state,
        lessonDetails: action.payload,
      };

    case AuthActionTypes.GET_DOWNLOADABLE_LESSON_LOADING_START:
      return {
        ...state,
        isDownloadableLessonsLoading: true,
      };
    case AuthActionTypes.GET_DOWNLOADABLE_LESSON_LOADING_STOP:
      return {
        ...state,
        isDownloadableLessonsLoading: false,
      };
    case AuthActionTypes.GET_DOWNLOADABLE_LESSON_SUCCESS:
      return {
        ...state,
        downloadableLessons: action.payload,
      };

    case AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_SUCCESS:
      return {
        ...state,
        allInstagramPosts: action.payload?.getAllPosts,
        instagramPosts: action.payload?.getAllPosts,
      };
    case AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_LOADING_START:
      return {
        ...state,
        isAllInstagramPostsLoading: true,
      };
    case AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_LOADING_STOP:
      return {
        ...state,
        isAllInstagramPostsLoading: false,
      };

    case AuthActionTypes.GET_ALL_INSTAGRAM_CATEGORIES_SUCCESS:
      return {
        ...state,
        allInstagramCategories: action.payload?.getAllCategories,
      };

    case AuthActionTypes.GET_ALL_INSTAGRAM_CATEGORIES_LOADING_START:
      return {
        ...state,
        isAllInstagramCategoriesLoading: true,
      };
    case AuthActionTypes.GET_ALL_INSTAGRAM_CATEGORIES_LOADING_STOP:
      return {
        ...state,
        isAllInstagramCategoriesLoading: false,
      };

    case AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_SUCCESS:
      return {
        ...state,
        instagramPostsByCategory: action.payload?.getAllPostsByCategory,
        instagramPosts: action.payload?.getAllPostsByCategory,
      };
    case AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_LOADING_START:
      return {
        ...state,
        isInstagramPostsByCategoryLoading: true,
      };
    case AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_LOADING_STOP:
      return {
        ...state,
        isInstagramPostsByCategoryLoading: false,
      };

    case AuthActionTypes.GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_SUCCESS:
      return {
        ...state,
        instagramSubCategoriesByCategory:
          action.payload?.getSubCategoriesByParent,
      };
    case AuthActionTypes.GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_START:
      return {
        ...state,
        isInstagramSubCategoriesByCategoryLoading: true,
      };
    case AuthActionTypes.GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_STOP:
      return {
        ...state,
        isInstagramSubCategoriesByCategoryLoading: false,
      };

    case AuthActionTypes.GET_ADDRESS_DETAILS_SUCCESS:
      return {
        ...state,
        addressDetails: action.payload,
      };
    case AuthActionTypes.GET_ADDRESS_DETAILS_LOADING_START:
      return {
        ...state,
        isLoadingAddressDetails: true,
      };
    case AuthActionTypes.GET_ADDRESS_DETAILS_LOADING_STOP:
      return {
        ...state,
        isLoadingAddressDetails: false,
      };

    case AuthActionTypes.UPDATE_ADDRESS_DETAILS_SUCCESS:
      return {
        ...state,
        updateAddressDetails: action.payload,
      };
    case AuthActionTypes.UPDATE_ADDRESS_DETAILS_LOADING_START:
      return {
        ...state,
        isAddressDetailsUpdating: true,
      };
    case AuthActionTypes.UPDATE_ADDRESS_DETAILS_LOADING_STOP:
      return {
        ...state,
        isAddressDetailsUpdating: false,
      };
    default:
      return state;
  }
};

/*************************************************/

export default userReducer;

/*************************************************/
