import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import BackArrowIcon from 'assets/images/icon-back-arrow.svg'


import { MENUS } from "./SideMenu";
import * as S from "./MarketingPortal.styles";
import * as SupplementsActions from "redux/supplements/supplements.actions";

const FoodiaryLeadDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { leadId } = useParams();

  const isFoodiaryLeadsLoading = useSelector(
    (state) => state.supplements.isFoodiaryLeadsLoading
  );
  const foodiaryLeads = useSelector((state) => state.supplements.foodiaryLeads);

  const isLeadUtilizing = useSelector(
    (state) => state.supplements.isLeadUtilizing
  );
  const leadUtilization = useSelector(
    (state) => state.supplements.leadUtilization
  );

  const isFoodiaryLeadClaiming = useSelector(
    (state) => state.supplements.isFoodiaryLeadClaiming
  );
  const claimFoodiaryLeadData = useSelector(
    (state) => state.supplements.claimFoodiaryLead
  );

  const trainerData = useSelector((state) => state.auth.trainerData);

  useEffect(() => {

    dispatch(
      SupplementsActions.getFoodiaryLeadDetailsRequest({
        payload: {
          lead_id: leadId,
        },
        callback: (res) => { },
      })
    );
  }, [dispatch]);

  const leadUtlization = () => {
    dispatch(
      SupplementsActions.leadUtilizationRequest({
        payload: {
          // date: moment().format("YYYY-MM-DD"),
          // connected_trainer: trainerData?.trainerID,
          trainer_id: trainerData?.trainerID,
          lead_id: leadId,
        },
        callback: (res) => {
          // if there is already toast message then remove it
          toast.dismiss()
          if (res?.success) {
            toast("✅ Lead wurde in dein Profil übertragen.");
            goBack();
          } else {
            toast("❌ " + res.response?.data?.error);
          }
        },
      })
    );
  };
  const goBack = () => history.goBack()

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.TrainerProfileDetailsInnerContainer>
            <S.Header className="p-0">
              <div className="heading-with-back-icon">
                <span onClick={goBack}><img style={{ marginTop: 5 }} src={BackArrowIcon} alt="" /></span>
                <div>
                  <S.TrainerProfileDetailsSectionHeading>
                    Leads von Foodiary
                  </S.TrainerProfileDetailsSectionHeading>
                  <S.TrainerProfileDetailsSectionSubHeading>
                    Schalte Leads von Foodiary frei und vereinbare direkt ein
                    Erstgespräch.
                  </S.TrainerProfileDetailsSectionSubHeading>
                </div>
              </div>
              <div>
                <button
                  className="btn-dark"
                  onClick={() => {
                    leadUtlization();
                  }}
                  disabled={isLeadUtilizing || isFoodiaryLeadClaiming}
                >
                  {isLeadUtilizing || isFoodiaryLeadClaiming
                    ? "Lead wird übertragen..."
                    : "Lead beanspruchen"}
                </button>
              </div>
            </S.Header>

            <S.Divider />
            <br />
            {isFoodiaryLeadsLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            {!isFoodiaryLeadsLoading && (
              <div className="lead-details">
                <h3 className="name text-size-28-38 text-dark-1 font-rubik-medium mt-3">
                  {foodiaryLeads?.foodiaryleadsdetails?.prename}{" "}
                  {foodiaryLeads?.foodiaryleadsdetails?.surename}
                </h3>

                <div className="general-info-box">
                  <h6 className="title text-size-20-24 text-dark-1 font-rubik-medium my-0">
                    Allgemeines
                  </h6>
                  <div className="dates-row">
                    <div className="d-flex">
                      <p className="received-date-title text-size-16-19 font-rubik-regular text-dark-1 mb-0">
                        Lead erhalten am:
                      </p>
                      <p className="received-date text-size-16-19 font-rubik-regular text-light-grey-1 mb-0 ml-3">
                        {foodiaryLeads?.foodiaryleadsdetails?.lead_received && (
                          <>
                            {moment(
                              new Date(
                                foodiaryLeads?.foodiaryleadsdetails?.lead_received
                              )
                            ).format("DD.MM.YYYY")}
                          </>
                        )}
                      </p>
                    </div>
                    {/* <div className="bg-red-500" /> */}
                    <div className="d-flex">
                      <p className="received-date-title text-size-16-19 font-rubik-regular text-dark-1  mb-0">
                        Leadquelle:
                      </p>
                      <p className="received-date text-size-16-19 font-rubik-regular text-light-grey-1 mb-0 ml-3">
                        {foodiaryLeads?.foodiaryleadsdetails?.lead_source}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="personal-data mt-3">
                  <h6 className="title text-size-20-24 text-dark-1 font-rubik-medium my-0">
                    Persönliche Daten
                  </h6>

                  <div className="personal-data-info">
                    <div>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1">
                        <span className="text-dark-1">Vorname:</span>{" "}
                        {foodiaryLeads?.foodiaryleadsdetails?.prename}
                      </p>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0">
                        <span className="text-dark-1">Telefonnummer:</span>{" "}
                        *********
                      </p>
                    </div>

                    <div>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1">
                        <span className="text-dark-1">Nachname:</span>{" "}
                        {foodiaryLeads?.foodiaryleadsdetails?.surename}
                      </p>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0">
                        <span className="text-dark-1"> E-Mail:</span> *********
                      </p>
                    </div>
                  </div>
                </div>

                <div className="lead-details mt-3">
                  <h6 className="title text-size-20-24 text-dark-1 font-rubik-medium my-0">
                    Erhaltene Informationen
                  </h6>
                  <div className="lead-details-info">
                    <div className="mb-3">
                      <p className="text-size-16-19 font-rubik-regular text-dark-1">
                        Welches Ziel verfolgst du?
                      </p>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1">
                        {foodiaryLeads?.foodiaryleadsdetails?.lead_goal}
                      </p>
                    </div>

                    <div className="mb-3">
                      <p className="text-size-16-19 font-rubik-regular text-dark-1">
                        Wie identifizierst du dich?
                      </p>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1">
                        {foodiaryLeads?.foodiaryleadsdetails?.lead_gender}
                      </p>
                    </div>

                    <div className="mb-3">
                      <p className="text-size-16-19 font-rubik-regular text-dark-1">
                        Wie alt bist du?
                      </p>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0">
                        {foodiaryLeads?.foodiaryleadsdetails?.lead_age}
                      </p>
                    </div>

                    <div className="mb-3">
                      <p className="text-size-16-19 font-rubik-regular text-dark-1">
                        Was möchtest du erreichen?
                      </p>
                      <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0">
                        {foodiaryLeads?.foodiaryleadsdetails?.lead_informations}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="lead-actions mt-3">
                  <h6 className="title text-size-20-24 text-dark-1 font-rubik-medium my-0">
                    Aktionen
                  </h6>
                  <div className="lead-actions-list">
                    <div className="lead-actions-list-item">
                      <div>
                        <h5 className="text-size-16-19 font-rubik-regular mb-0">
                          Kontaktdetails freischalten
                        </h5>
                        <p className="text-size-16-19 font-rubik-regular text-light-grey-1 mb-0 mt-2">
                          Füge diesen Interessenten deinen eigenen Leads hinzu
                          und kontaktiere diesen direkt.
                        </p>
                      </div>
                      <button
                        className="btn-outline"
                        onClick={() => {
                          leadUtlization();
                        }}
                        disabled={isLeadUtilizing || isFoodiaryLeadClaiming}
                      >
                        {isLeadUtilizing || isFoodiaryLeadClaiming
                          ? "Lead wird übertragen..."
                          : "Lead beanspruchen"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <br />
          </S.TrainerProfileDetailsInnerContainer>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default FoodiaryLeadDetails;
