import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { object, string } from "yup";
import { getS3ImageUrl } from "configs/utils";
import { ToastContainer, toast } from "react-toastify";

// Components
import Input from "components/formComponents/input/Input";
// Images
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import PasswordResetModal from "components/PasswordResetModal/PasswordResetModal";
// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as S from "./trainerProfileDetails.styles";
import { MENUS } from "./data";

const TrainerProfileBillingSection = () => {
  const dispatch = useDispatch();
  const profileFormRef = useRef(null);

  const [passwordResetModal, setPasswordResetModal] = useState(false);
  const [isAddressSending, setIsAddressSending] = useState(false);

  const loading = useSelector((state) => state.auth.loading);
  // const saveCreatedSiteInDBRes = useSelector((state) => state.auth.createWebsite)
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const addressDetails = useSelector((state) => state.auth.addressDetails);
  // const registrationData = useSelector((state) => state.auth.registrationData)

  useEffect(() => {
    getTrainerProfileDetails();
  }, []);

  const getTrainerProfileDetails = () => {
    dispatch(
      AuthActions.getTrainerDetailsRequest({
        FranchiseID: trainerProfileData?.FranchiseID,
        trainerID: trainerProfileData?.id,
      })
    );

    dispatch(
      AuthActions.getAddressDetailsRequest({
        payload: {
          trainerID: trainerProfileData?.id,
        },
      })
    );
  };

  const initialValuesAddress = {
    street: addressDetails?.getTrainerAddrDetails?.street || "",
    house_number: addressDetails?.getTrainerAddrDetails?.house_number || "",
    postal_code: addressDetails?.getTrainerAddrDetails?.postal_code || "",
    place_of_residence:
      addressDetails?.getTrainerAddrDetails?.place_of_residence || "",
    tax_number: addressDetails?.getTrainerAddrDetails?.tax_number || "",
    sales_tax_id: addressDetails?.getTrainerAddrDetails?.sales_tax_id || "",
  };

  const validationSchemaAddress = object().shape({
    street: string().required(),
    house_number: string().required(),
    postal_code: string().required(),
    place_of_residence: string().required(),
    tax_number: string(),
    sales_tax_id: string(),
  });

  const onAddressFormSubmit = (values, { resetForm }) => {
    setIsAddressSending(true);
    dispatch(
      AuthActions.updateAddressDetailsRequest({
        payload: {
          ...values,
          trainerID: trainerProfileData.trainerID,
        },
        callback: () => {
          setIsAddressSending(false);
          dispatch(
            AuthActions.getAddressDetailsRequest({
              payload: {
                trainerID: trainerProfileData.id,
              },
              callback: () => {},
            })
          );
        },
      })
    );
    resetForm();
  };

  const onProfileUpdate = () => {
    if (profileFormRef.current) {
      profileFormRef.current.handleSubmit();
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="second-sidebar-wrapper">
        <InnerSideNav
          menus={MENUS}
          userProfileInfo={{
            profilePic: getS3ImageUrl(trainerProfileData.profilePic),
            franchiseId: trainerProfileData?.FranchiseID,
            stripeId: trainerProfileData?.["Stripe Connect ID"] || "",
            prename: trainerProfileData.prename,
            surname: trainerProfileData.surname,
            connected_user_account_id:
              trainerProfileData?.connected_user_account_id,
          }}
          onPasswordLink={() => setPasswordResetModal(true)}
          idsSection
        ></InnerSideNav>
      </div>
      <PasswordResetModal
        show={passwordResetModal}
        setShow={setPasswordResetModal}
      />
      <div className="second-sidebar-content">
        <S.TrainerProfileDetailsOverallContainer>
          <Formik
            initialValues={initialValuesAddress}
            validationSchema={validationSchemaAddress}
            onSubmit={onAddressFormSubmit}
            innerRef={profileFormRef}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <S.TrainerProfileDetailsInnerContainer>
                  <S.Header className="p-0">
                    <div>
                      <S.TrainerProfileDetailsSectionHeading>
                        Rechnungsdaten
                      </S.TrainerProfileDetailsSectionHeading>
                      <S.TrainerProfileDetailsSectionSubHeading>
                        Deine Unternehmensinformationen für deine monatlichen
                        Gutschriften.
                      </S.TrainerProfileDetailsSectionSubHeading>
                    </div>
                    <div>
                      <button
                        className="btn-dark"
                        onClick={onProfileUpdate}
                        disabled={isAddressSending}
                      >
                        Speichern
                      </button>
                    </div>
                  </S.Header>
                  <br />
                  <S.Divider />
                  <br />

                  {loading && (
                    <>
                      <br />
                      <br />
                      <br />
                      <br />
                      <div className="text-center">
                        <div className="loader mx-auto"></div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                    </>
                  )}

                  {!loading && (
                    <S.FormGrid>
                      <div>
                        <S.TrainerProfileDetailsSection1InputLabel>
                          STRAßE
                        </S.TrainerProfileDetailsSection1InputLabel>
                        <Input
                          filled
                          style={{ marginBottom: "10px" }}
                          placeholder="STRAßE"
                          name="street"
                          onChange={handleChange}
                          value={values.street}
                        />
                      </div>
                      <div>
                        <S.TrainerProfileDetailsSection1InputLabel>
                          HAUSNUMMER
                        </S.TrainerProfileDetailsSection1InputLabel>
                        <Input
                          filled
                          style={{ marginBottom: "10px" }}
                          placeholder="HAUSNUMMER"
                          name="house_number"
                          onChange={handleChange}
                          value={values.house_number}
                        />
                      </div>
                      <div>
                        <S.TrainerProfileDetailsSection1InputLabel>
                          POSTLEITZAHL
                        </S.TrainerProfileDetailsSection1InputLabel>
                        <Input
                          filled
                          style={{ marginBottom: "10px" }}
                          placeholder="POSTLEITZAHL"
                          name="postal_code"
                          onChange={handleChange}
                          value={values.postal_code}
                        />
                      </div>
                      <div>
                        <S.TrainerProfileDetailsSection1InputLabel>
                          WOHNORT
                        </S.TrainerProfileDetailsSection1InputLabel>
                        <Input
                          filled
                          style={{ marginBottom: "10px" }}
                          placeholder="WOHNORT"
                          name="place_of_residence"
                          onChange={handleChange}
                          value={values.place_of_residence}
                        />
                      </div>
                      <div>
                        <S.TrainerProfileDetailsSection1InputLabel>
                          STEUERNUMMER
                        </S.TrainerProfileDetailsSection1InputLabel>
                        <Input
                          filled
                          style={{ marginBottom: "10px" }}
                          placeholder="STEUERNUMMER"
                          name="tax_number"
                          onChange={handleChange}
                          value={values.tax_number}
                        />
                      </div>
                      <div>
                        <S.TrainerProfileDetailsSection1InputLabel>
                          UMSATZSTEUER ID
                        </S.TrainerProfileDetailsSection1InputLabel>
                        <Input
                          filled
                          style={{ marginBottom: "10px" }}
                          placeholder="UMSATZSTEUER ID"
                          name="sales_tax_id"
                          onChange={handleChange}
                          value={values.sales_tax_id}
                        />
                      </div>
                    </S.FormGrid>
                  )}
                </S.TrainerProfileDetailsInnerContainer>
                <br />
              </form>
            )}
          </Formik>
        </S.TrainerProfileDetailsOverallContainer>
      </div>
    </>
  );
};

export default TrainerProfileBillingSection;
