import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import Arrow from "assets/images/arrow-white.svg";
import ArrowActive from "assets/images/arrow-green.svg";
import EditLightGrey from "assets/images/edit-light-grey.svg";

import Input from "components/formComponents/input/Input";
import ClientDetailsSidebar from "./ClientDetailsSidebar";

import * as AuthActions from "redux/auth/auth.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "./ClientDetails.styles";
import "./clientDetails.scss";

const Objective = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams();

  const trainerData = useSelector((state) => state.auth.trainerData);

  const userWeightGoalData = useSelector(
    (state) => state.auth.userWeightGoalData
  );

  const isUserWeightGoalDataLoading = useSelector(
    (state) => state.auth.isUserWeightGoalDataLoading
  );

  const isGoalWeightUpdating = useSelector(
    (state) => state.dashboard.isGoalWeightUpdating
  );

  const [GoalWeightValue, SetGoalWeightValue] = useState(0);
  const [isEdit, setIsEdit] = useState(true);

  const Goals = [
    {
      id: 1,
      title: "Muskelaufbau",
      description: "Deine Kalorien werden deutlich erhöht.",
      aimIDs: [2, 3],
    },
    {
      id: 2,
      title: "Abnehmen",
      description: "Die Kalorien im Ernährungskonzept werden gesenkt.",
      aimIDs: [1],
    },
    {
      id: 3,
      title: "Gesunde Ernährung",
      description: "Die Kalorien entsprechen dem Gesamtumsatz.",
      aimIDs: [4],
    },
  ];

  useEffect(() => {
    dispatch(
      DashboardActions.getTrainerProfileTempDetailsRequest({
        payload: { trainerID: trainerData?.trainerID },
        callback: () => null,
      })
    );

    dispatch(
      AuthActions.getUserWeightGoalDataRequest({
        formData: {
          userID: userId,
        },
        callback: (res) => {
          SetGoalWeightValue(res?.data?.goal_weight || 0);
        },
      })
    );
  }, [dispatch]);

  const updateGoalWeight = () => {
    console.log("updateGoalWeight", GoalWeightValue);
    dispatch(
      DashboardActions.updateGoalWeightRequest({
        payload: {
          userID: userId,
          goal_weight: GoalWeightValue,
        },
        callback: () => {
          dispatch(
            AuthActions.getUserWeightGoalDataRequest({
              formData: {
                userID: userId,
              },
              callback: () => null,
            })
          );
        },
      })
    );
  };

  const getActiveGoalItem = (id) => {
    return Goals.find((goal) => goal?.aimIDs.includes(id));
  };

  return (
    <>
      <S.ClientDetailsOverallContainer>
        <S.ClientDetailsContainer>
          <div className="second-sidebar-wrapper">
            <ClientDetailsSidebar />
          </div>
          <div className="second-sidebar-content" style={{ padding: "1.5rem" }}>
            <S.ContainerBox>
              <S.Header>
                <div>
                  <S.Heading>Zielsetzung</S.Heading>
                  <S.SubHeading>
                    Für dieses Ziel hat sich dein Klient während der Betreuung
                    entschieden.
                  </S.SubHeading>
                </div>
                <div>
                  <button
                    className="btn-dark"
                    onClick={() => updateGoalWeight()}
                    disabled={isGoalWeightUpdating}
                  >
                    {isGoalWeightUpdating ? "Loading..." : "Speichern"}
                  </button>
                </div>
              </S.Header>

              <S.Divider></S.Divider>
              {isUserWeightGoalDataLoading && (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="text-center">
                    <div className="loader mx-auto"></div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              )}
              {!isUserWeightGoalDataLoading && (
                <>
                  <S.PaddingBox>
                    <div className="container-70rem">
                      <S.GoalsHeading>
                        Das festgelegte Ziel deines Klienten
                      </S.GoalsHeading>
                      <br />
                      <S.ClientDetailsDailyRoutineContainer>
                        <S.ClientDetailsDailyRoutineTabContainer>
                          {Goals.map((item) => (
                            <S.ClientDetailsDailyRoutineTab
                              isActive={item?.aimIDs.includes(
                                userWeightGoalData?.data?.aimID
                              )}
                              key={item.id}
                            >
                              <div>
                                <S.ClientDetailsDailyRoutineTabHeading>
                                  {item.title}
                                </S.ClientDetailsDailyRoutineTabHeading>
                                <S.ClientDetailsDailyRoutineTabDescription>
                                  {item.description}
                                </S.ClientDetailsDailyRoutineTabDescription>
                              </div>
                              {!item?.aimIDs.includes(
                                userWeightGoalData?.data?.aimID
                              ) && (
                                <S.ClientDetailsDailyRoutineTabRightArrowIcon
                                  alt="right-icon-tab"
                                  src={Arrow}
                                />
                              )}
                              {item?.aimIDs.includes(
                                userWeightGoalData?.data?.aimID
                              ) && (
                                <S.ClientDetailsDailyRoutineTabRightArrowIcon
                                  className="active"
                                  alt="right-icon-tab"
                                  src={ArrowActive}
                                />
                              )}
                            </S.ClientDetailsDailyRoutineTab>
                          ))}
                        </S.ClientDetailsDailyRoutineTabContainer>
                        <S.ClientDetailsDailyRoutineTabDetailsContainer className="h-auto">
                          <S.ClientDetailsDailyRoutineTabDetailsInnerContainer>
                            <div className="daily-routine-details-header">
                              <div>
                                <h6 className="mb-1">
                                  {
                                    getActiveGoalItem(
                                      userWeightGoalData?.data?.aimID
                                    )?.title
                                  }
                                </h6>
                                <p>
                                  {userWeightGoalData?.data?.de_description}
                                </p>
                              </div>
                            </div>
                          </S.ClientDetailsDailyRoutineTabDetailsInnerContainer>
                        </S.ClientDetailsDailyRoutineTabDetailsContainer>
                      </S.ClientDetailsDailyRoutineContainer>

                      {/* <S.Goals>
                        <S.Goal
                          isActive={[2, 3].includes(
                            userWeightGoalData?.data?.aimID
                          )}
                        >
                          <img src={LeafIcon} alt="left Icon" />
                          <p>Muskelaufbau</p>
                        </S.Goal>
                        <S.Goal
                          isActive={[1].includes(
                            userWeightGoalData?.data?.aimID
                          )}
                        >
                          <img src={LeafIcon} alt="left Icon" />
                          <p>Abnehmen</p>
                        </S.Goal>
                        <S.Goal
                          isActive={[4].includes(
                            userWeightGoalData?.data?.aimID
                          )}
                        >
                          <img src={LeafIcon} alt="left Icon" />
                          <p>Gesunde Ernährung</p>
                        </S.Goal>
                      </S.Goals> */}
                    </div>
                  </S.PaddingBox>
                  <S.Divider></S.Divider>
                  <S.PaddingBox>
                    <div className="container-70rem">
                      <S.GoalsHeading>
                        Bearbeite das Zielgewicht deines Klienten
                      </S.GoalsHeading>
                      <br />
                      <S.FormWrap>
                        <S.FormControl>
                          <S.ClientDetailsOverviewInputLabel>
                            Startgewicht <small>(Kg)</small>
                          </S.ClientDetailsOverviewInputLabel>
                          <Input
                            style={{ width: "100%" }}
                            type="text"
                            placeholder={`${userWeightGoalData?.data?.weight}`}
                            filled
                            disabled
                          />
                        </S.FormControl>
                        <S.FormControl>
                          <S.ClientDetailsOverviewInputLabel>
                            Zielgewicht <small>(Kg)</small>
                          </S.ClientDetailsOverviewInputLabel>
                          <Input
                            type="text"
                            placeholder={`50`}
                            value={`${GoalWeightValue}`}
                            onChange={(e) => SetGoalWeightValue(e.target.value)}
                            filled={isEdit}
                            disabled={isEdit}
                          />
                          <img
                            src={EditLightGrey}
                            alt="edit"
                            onClick={() => setIsEdit(false)}
                          />
                        </S.FormControl>
                      </S.FormWrap>
                    </div>
                  </S.PaddingBox>
                </>
              )}
            </S.ContainerBox>
          </div>
        </S.ClientDetailsContainer>
      </S.ClientDetailsOverallContainer>
    </>
  );
};

export default Objective;
