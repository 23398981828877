import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, dateNumbersFormat } from "configs/utils";

import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import { MENUS } from "../data";

import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "./expertsCalls.styles";
import Select from "react-select";

const ExpertsCalls = () => {
  const dispatch = useDispatch();

  const [Experts, SetExperts] = useState([]);
  const [
    selectedDateFilter,
    setSelectedDateFilter
  ] = useState({ label: 'Datumsfilter' })

  const expertsCalls = useSelector((state) => state.dashboard.expertsCalls);

  const isExpertsLoading = useSelector(
    (state) => state.dashboard.isExpertsLoading
  );

  useEffect(() => {
    dispatch(
      DashboardActions.getExpertsCallsRequest({
        callback: () => {
          SetExperts(expertsCalls?.data);
        },
      })
    );

    getWeekRanges();
  }, []);

  const onDateRangeFilter = (e) => {
    let date = getWeekRanges().find((range) => +e.target.value === range.id);

    let filteredList = filterListByDateRange(
      expertsCalls?.data,
      date.startDate,
      date.endDate
    );

    SetExperts(filteredList);
  };

  function filterListByDateRange(list, startDate, endDate) {
    return list.filter((item) => {
      const itemDate = new Date(item.Datum);
      return itemDate >= startDate && itemDate <= endDate;
    });
  }

  function getWeekRanges() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const today = currentDate.getDate();

    const totalDays = 28;

    const weekRanges = [];

    let startDay = today;
    let endDay = startDay + 6;
    let id = 1;

    while (startDay <= today + totalDays - 1) {
      if (endDay > today + totalDays - 1) {
        endDay = today + totalDays - 1;
      }

      const startOfWeek = new Date(currentYear, currentMonth, startDay);
      const endOfWeek = new Date(currentYear, currentMonth, endDay);

      // requested date display format : DD.MM.YYYY - DD.MM.YYYY
      const formattedStart = dateNumbersFormat(startOfWeek)
      const formattedEnd = dateNumbersFormat(endOfWeek)
      const displayRange = `${formattedStart} - ${formattedEnd}`

      // Add the range as an object to the array
      weekRanges.push({
        startDate: startOfWeek,
        endDate: endOfWeek,
        displayRange,
        id: id,
      });

      // Update start and end days for the next iteration
      startDay = endDay + 1;
      endDay += 7;
      id++;
    }
    return weekRanges;
  }

  const onReset = () => {
    SetExperts(expertsCalls?.data);
  };

  const onExpert = (expert) => {
    window.open(expert?.calendly_link, "_blank");
  };

  return (
    <>
      <S.Container className="whit-paper-page">
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.MCard>
            <S.Header>
              <div>
                <S.ClientDetailsContentHeading>
                  Expertencalls
                </S.ClientDetailsContentHeading>
                <S.ClientDetailsContentSubHeading>
                Lerne von Experten mehr über die einzelnen Bereiche der
                Selbstständigkeit und wie du dein Coaching aufbaust.
                </S.ClientDetailsContentSubHeading>
              </div>

              <div className="right-sec">
                <Select
                  options={getWeekRanges().map(({ id, displayRange }) => ({ id, label: displayRange, value: id }))}
                  name="sex"
                  className="select-width"
                  classNamePrefix="react-select"
                  onChange={(item) => {
                    onDateRangeFilter({ target: { value: item.id } })
                    setSelectedDateFilter(item)
                  }}
                  placeholder="Datumsfilter"
                  isSearchable={false}
                  value={selectedDateFilter}
                />
                <button onClick={onReset} className="btn-dark">Alle zeigen</button>
              </div>
            </S.Header>

            <br />
            <S.Divider />
            <br />

            <S.WhitePaperWrap>
              {isExpertsLoading && (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="text-center">
                    <div className="loader mx-auto"></div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              )}
              <S.WhitePaperInner>
                {!isExpertsLoading &&
                  Experts?.map((expert) => (
                    <S.Expert onClick={() => onExpert(expert)}>
                      <S.ExpertImage>
                        <img src={expert?.Bild} alt="expert" />
                      </S.ExpertImage>
                      <S.ExpertContent>
                        {expert?.["Freie_Plätze"] === 0 && (
                          <div className="label danger">Ausgebucht</div>
                        )}
                        {expert?.["Freie_Plätze"] > 0 &&
                          expert?.["Freie_Plätze"] < 6 && (
                            <div className="label warning">
                              {expert?.["Freie_Plätze"]} Plätze verfügbar
                            </div>
                          )}
                        {expert?.["Freie_Plätze"] > 5 && (
                          <div className="label success">
                            {expert?.["Freie_Plätze"]} Plätze verfügbar
                          </div>
                        )}
                        <h4>{expert?.Referent}</h4>
                        <p>{expert?.Beschreibung}</p>
                        <div className="date-time">
                          <span>
                            {`${formatDate(expert?.Datum).day}, ${
                              formatDate(expert?.Datum).date
                            }`}
                          </span>
                          <span>{expert?.Uhrzeit}</span>
                        </div>
                      </S.ExpertContent>
                    </S.Expert>
                  ))}
              </S.WhitePaperInner>
            </S.WhitePaperWrap>
          </S.MCard>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default ExpertsCalls;
