import React, { useState, useEffect } from "react";
import { Route, NavLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { number, object, string, boolean } from "yup";
import * as AuthActions from "redux/auth/auth.actions";

import Input from "components/formComponents/input/Input";
import TextArea from "components/formComponents/textArea/TextArea";
import TranslationMessage from "configs/translations/TranslationMessage";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import Button from "components/formComponents/button/Button";
import {
  trainerConnectedAccount,
  trainerConnectedAccountClear,
} from "redux/auth/auth.actions";

import * as S from "./settings.styles";
import { Divider } from "@material-ui/core";

const MENUS = [
  {
    id: 1,
    title: "E-Mail Account",
    path: "/dashboard/settings/email-account",
  },
  {
    id: 2,
    title: "Temporäre Zugangsdaten",
    path: "/dashboard/settings/access-data",
  },
  {
    id: 3,
    title: "Zahlungsanbieter aktivieren",
    path: "/dashboard/settings/payment-provider",
  },
];

const PaymentProvider = () => {
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const [disableAfterClick, setDisableAfterClick] = useState("");
  const [disableConnectedAccount, setDisableConnectedAccount] = useState("");
  const [loader, setLoader] = useState(false);
  const [userLoader, setUserLoader] = useState(false);

  const accountConnectedDetails = useSelector(
    (state) => state.auth.accountConnectedDetails
  );
  const useraccountConnectedDetails = useSelector(
    (state) => state.auth.useraccountConnectedDetails
  );

  useEffect(() => {
    dispatch(
      AuthActions.getTrainerProfileRequest({
        FranchiseID: trainerProfileData.FranchiseID,
      })
    );
  }, [])

  useEffect(() => {
    if (accountConnectedDetails?.url) {
      dispatch(AuthActions.trainerConnectedAccountClear());
      window.location.href = accountConnectedDetails?.url;
    }
    if (useraccountConnectedDetails?.url) {
      dispatch(AuthActions.userConnectedAccountClear());
      window.location.href = useraccountConnectedDetails?.url;
    }
  }, [accountConnectedDetails, useraccountConnectedDetails]);

  const dispatch = useDispatch()

  const handleUpdateAccount = (id) => {
    setUserLoader(true);
    setDisableConnectedAccount("disabled");
    dispatch(AuthActions.trainerConnectedAccount(id));
  }

  const handleUpdateUserConnectedAccount = (id) => {
    setLoader(true);
    setDisableAfterClick("disabled");
    dispatch(AuthActions.userConnectedAccount(id));
  }

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.TrainerProfileDetailsInnerContainer>
            <S.TrainerProfileDetailsSectionHeading>
              Zahlungsanbieter aktivieren
            </S.TrainerProfileDetailsSectionHeading>
            <S.TrainerProfileDetailsSectionSubHeading>
              Bitte aktiviere vor deinem ersten Erstgespräch deinen Zahlungsdienstleister für Coaching und App.
            </S.TrainerProfileDetailsSectionSubHeading>

            <S.Divider />

            <S.ToggleSwitchWrapper
              className={
                userLoader
                  ? "nonClickable"
                  : trainerProfileData?.connected_user_account_status ===
                    "completed"
                  ? "disabled"
                  : disableAfterClick
              }
            >
              {loader && (
                <div className="loader_main">
                  <span className="loader-settings"></span>
                </div>
              )}
              <S.ToggleSwitchLabelOne active={false}>
                <S.Button
                  disabled={
                    trainerProfileData?.connected_user_account_status ===
                    "pending"
                      ? false
                      : true
                  }
                >
                  Stripe Account für den App-Verkauf
                </S.Button>
                <span>Aktiviere Stripe für den Erhalte der Zahlungen für dein Coaching.</span>
              </S.ToggleSwitchLabelOne>
              <S.ToggleSwitch
                onClick={() =>
                  handleUpdateUserConnectedAccount(trainerProfileData.id)
                }
                className={
                  trainerProfileData?.connected_user_account_status ===
                  "pending"
                    ? "red"
                    : "green"
                }
              >
                <label className={"switch"}>
                  <input type="checkbox" name="customerCare" checked />
                  <span className="slider round"></span>
                </label>
                <S.ToggleSwitchLabelTwo active />
              </S.ToggleSwitch>
            </S.ToggleSwitchWrapper>

            <S.Divider />

            <S.ToggleSwitchWrapper
              className={
                loader
                  ? "nonClickable"
                  : trainerProfileData?.connected_account_status ===
                    "completed"
                  ? "disabled"
                  : disableConnectedAccount
              }
            >
              {userLoader && (
                <div className="loader_main">
                  <span className="loader-settings"></span>
                </div>
              )}
              <S.ToggleSwitchLabelOne active={false}>
                <S.Button
                  disabled={
                    trainerProfileData?.connected_account_status ===
                    "pending"
                      ? false
                      : true
                  }
                >
                  Stripe Account für dein Coaching
                </S.Button>
                <span>Aktiviere Stripe für den Erhalt der Zahlungen für die App.</span>
              </S.ToggleSwitchLabelOne>
              <S.ToggleSwitch
                onClick={() => handleUpdateAccount(trainerProfileData.id)}
                className={
                  trainerProfileData?.connected_account_status ===
                  "pending"
                    ? "red"
                    : "green"
                }
              >
                <label className={"switch"}>
                  <input type="checkbox" name="customerCare" checked />
                  <span className="slider round"></span>
                </label>
                <S.ToggleSwitchLabelTwo active />
              </S.ToggleSwitch>
            </S.ToggleSwitchWrapper>
            <S.Divider />
          </S.TrainerProfileDetailsInnerContainer>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default PaymentProvider;
