import styled from "styled-components";
import {
  RegistrationContainer,
  RegistrationHeading,
  RegistrationSubHeading,
  EatingHabitsCardContainer,
  EatingHabitsImageContainer,
  EatingHabitsCardInnerContainer,
} from "pages/registration/registration.styles";

export const OnBoardingPage = styled.div`
  background: transparent linear-gradient(125deg, #e5f5f2 0%, #edfbf6 100%);
  width: 100%;
  min-height: 100vh;
`;

export const OnBoardingContainer = styled.div`
  width: 100%;
  max-width: calc(100% - 5rem);
  height: 100%;
  margin-inline: auto;
  padding-block: 3.75rem;
  display: flex;
  align-items: stretch;
  gap: 1rem;
  min-height: calc(100vh - 3.75rem);
`;

export const OnBoardingSidebar = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 1.25rem;
  width: 18.75rem;
  padding: 2rem;
  max-height: calc(100vh - 3.75rem - 3.75rem);
  overflow-y: auto;
  position: fixed;
  z-index: 1;
  height: 100%;

  .sidebar-title {
    letter-spacing: 0.4px;

    font-size: 1.25rem;
    margin: 1.875rem 0;
  }

  .steps-title {
    font-size: 0.875rem;
  }

  .sidebar-title,
  .steps-title {
    font-weight: 500;
    color: #142c1f;
  }

  .steps {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.1875rem;

    .step {
      display: flex;
      gap: 0.9375rem;

      .step-bar {
        display: flex;
        gap: 3px;
        flex-direction: column;
        align-items: center;

        .round {
          width: 1.625rem;
          height: 1.625rem;
          border: 2px solid #eaeaea;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .small-round {
            border-radius: 50%;
            width: 0.75rem;
            height: 0.75rem;
            background: #e4e4e4;
          }

          &.active {
            background: #2ecf96;

            .small-round {
              background: #fff;
            }
          }
        }

        .wall {
          width: 0.1875rem;
          height: 4rem;
          background: #eaeaea;

          &.active {
            background: #2ecf96;
          }
        }
      }

      .step-content {
        .step-title,
        .step-description {
          margin: 0;
        }

        .step-title {
          letter-spacing: 0.32px;
          color: #142c1f;
          font-size: 1rem;
          font-weight: 500;
        }

        .step-description {
          letter-spacing: 0.28px;
          color: #818d88;
          font-size: 0.875rem;
          font-weight: 400;
          margin-top: 0.3125rem;
        }
      }
    }
  }
`;

export const OnBoardingContent = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 1.25rem;
  width: calc(100% - 18.75rem);
  margin-left: 19.75rem;

  .starter-content {
    .h1 {
      margin: 1rem 0;
    }

    .companies {
      margin-top: 0.8rem;
    }
  }
`;

export const OnBoardingHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.25rem 2.1875rem;
  border-bottom: 1px solid #e2e4e9;

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .header-title {
    color: #142c1f;
    font-size: 1.375rem;
  }
`;

export const OnBoardingBody = styled.div`
  min-height: calc(100vh - 3.75rem - 3.75rem - 5.6rem);
  padding: 1.75rem 2.1875rem;
`;

export const ChecklistElementContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 0.9375rem 0;
`;

export const ChecklistElementGreenTick = styled.img`
  padding-right: 0.625rem;
`;

export const TwoColsGridView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const Button = styled.button`
  padding: 12px 9px;
  font-size: 16px;
  line-height: 19px;
  border: none;
  background: #f8a492;
  border-radius: 8px;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: RubikMedium;
  transition: all 0.3s;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-property: transform;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;

  &:not(:disabled):hover {
    transform: scale(0.9);
  }
`;

export const Tab = styled.div`
  height: 18px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: ${(props) => (props.active ? "#1C9D87" : "#142C1F15")};
  color: ${(props) => (props.active ? "white" : "#44554C")};
  padding: 11px 15px;
  font-size: 14px;
`;

// Add Daily Routine Popup
export const DailyRoutineInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
`;

export const DailyRoutineInputLabel = styled.div`
  line-height: 35px;
`;

export const DailyRoutineAddButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

// -----------------------------------------------------------------------

export const NutritionBackdropImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 1.25rem);
  height: 100%;
  object-fit: cover;
  margin-left: -1.25rem;
`;

export const NutritionCreationTextContainer = styled.div`
  /* position: absolute;
  top: 26.25rem;
  left: 14%; */
  z-index: 200;
`;

export const NutritionCreationVideo = styled.div`
  /* position: absolute; */
  border-radius: 15px;
  top: calc((100vh - 568px) / 2);
  right: 10%;
  width: 318px;
  height: 568px;
  -webkit-box-shadow: 0 8px 15px 8px #e0e0e0;
  -moz-box-shadow: 0 8px 15px 8px #e0e0e0;
  box-shadow: 0 8px 15px 8px #e0e0e0;

  video {
    width: 100%;
    height: 100%;
  }
`;

export const NutritionFormContainer = styled(RegistrationContainer)``;

export const NutritionFormHeading = styled(RegistrationHeading)``;

export const NutritionFormSubHeading = styled(RegistrationSubHeading)``;

export const NutritionFormDescription = styled.div`
  font-size: 16px;
  color: rgba(68, 68, 68, 0.6);
  line-height: 23px;
  text-align: left;
`;

export const NutritionFormCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.justify ? props.justify : "space-between"};
`;

export const NutritionFormCard = styled.div`
  width: 172px;
  height: 151px;
  cursor: pointer;
  background: ${(props) => {
    if (props.backgroundColor) return props.backgroundColor;
    else if (props.selected) return "#F3FFFD";
    else return "#FFF";
  }};
  box-shadow: 0px 1px 2px #1018280d;
  border: 2px solid #efefef;
  border-radius: 10px;
  text-align: center;
  border-radius: 10px;
  color: #142c1f;
  border: ${(props) => {
    if (props.selected) return "2px solid #2ECF96";
    else if (props.backgroundColor) return "2px solid white";
    else return "2px solid #F5F5F5";
  }};
`;

export const NutritionCardImage = styled.img`
  margin: 33px 67px 19px;
`;

// ------------------

export const NutritionFormRecipesContainer = styled(RegistrationContainer)``;

export const NutritionFormRecipesHeading = styled(RegistrationHeading)``;

export const NutritionFormRecipesSubHeading = styled(RegistrationSubHeading)``;

export const NutritionFormRecipesButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px auto;
  position: relative;
`;

export const ArrowButton = styled.div`
  position: absolute;
  border: ${(props) =>
    props.active ? "solid #2ECF96" : "solid rgba(165, 181, 175, .6)"};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  border-radius: 2px;
  top: 13px;
  right: ${(props) => (props.left ? "15px" : "13px")};
  transform: ${(props) => (props.left ? "rotate(135deg)" : "rotate(-45deg)")};
  -webkit-transform: ${(props) =>
    props.left ? "rotate(135deg)" : "rotate(-45deg)"};
`;

export const StraightLine = styled.div`
  position: absolute;
  border-bottom: ${(props) =>
    props.active ? "2px solid #2ECF96" : "2px solid rgba(165, 181, 175, .6)"};
  width: 18px;
  bottom: 17px;
  left: 8px;
`;

export const NutritionFormRecipesButtonLeft = styled.div`
  position: absolute;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  /* border: ${(props) =>
    props.active
      ? "2px solid #2ECF96"
      : "2px solid rgba(165, 181, 175, .6)"}; */

  top: -41px;
  right: 16px;
  cursor: ${(props) => (props.active ? "pointer" : "not-allowed")};

  svg {
    stroke: #a5b5af;
  }

  &.active {
    svg {
      stroke: #2ecf96;
    }
  }
`;

export const NutritionFormRecipesButtonRight = styled(
  NutritionFormRecipesButtonLeft
)`
  left: auto;
  right: -16px;
  top: -41px;
`;

export const NutritionFormRecipesCardContainer = styled(
  NutritionFormRecipesButtonContainer
)`
  flex-direction: column;
`;

export const NutritionFormRecipesCard = styled.div`
  /* margin: 20px auto 0px; */
  background: #ffffff;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  padding: 1rem;
  gap: 0.6rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const NutritionFormRecipesCardImageContainer = styled.div`
  img {
    width: 3.625rem;
    height: 3.75rem;
    padding: 0.25rem;
    background: #ffffff;
    box-shadow: 0px 1px 2px #1018280d;
    border: 1.5px solid #efefef;
    border-radius: 10px;
    object-fit: cover;
  }
`;

export const NutritionFormRecipesCardImage = styled.div``;

export const NutritionFormRecipesCardInnerContainer = styled.div``;

export const NutritionFormRecipesCardInnerCalories = styled.div``;

export const NutritionFormCaloriesLineGraphContainer = styled.div`
  width: 100%;
  /* height: 289px; */
  background: #ffffff;
  box-shadow: 0px 13px 20px #d6d6d629;
  border: 2px solid #e2e4e9;
  border-radius: 15px;
`;

export const EditCaloriesWeekHeadingContainer = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const EditCaloriesWeekHeading = styled.span`
  line-height: 50px;
`;

export const EditCaloriesNavigationIconContainer = styled.span`
  margin: 10px 30px;
  cursor: pointer;
`;

export const EditCaloriesInputCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  padding: 1rem;
`;

export const DonutContainer = styled.div`
  /* width: fit-content; */
  /* margin: 10px auto 40px; */
  background: #ffffff;
  box-shadow: 0px 13px 20px #d6d6d629;
  border: 2px solid #e2e4e9;
  border-radius: 15px;
`;
