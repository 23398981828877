import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserRegistrationDataFromLocalStorage } from "configs/utils";

import Button from "components/formComponents/button/Button";
import Modal from "components/modal/Modal";
import Loader from "components/loader/Loader";
import TranslationMessage from "configs/translations/TranslationMessage";

import LowCarbImage from "assets/images/lowCarb.svg";
import LowCarbNotSelectedImage from "assets/images/lowCarbNotSelected.svg";
import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import ArrowLeft from "assets/images/arrowLeft.svg";
import { SidebarSteps } from "../data";
// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as S from "../OnBoardingPartTwo.styles";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

const NutritionTypeUpdate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  // const userData = useSelector((state) => state.nutrition.userData);
  const registrationData = useSelector((state) => state.auth.registrationData);
  const activeNutritionType = useSelector(
    (state) => state.nutrition.activeNutritionType
  );

  const allNutritionTypes = useSelector(
    (state) => state.nutrition.nutritionTypes
  );

  const [selectedNutritionForm, setSelectedNutritionForm] = useState({});
  const [editNutritionFormModal, setEditNutritionFormModal] = useState(false);

  const advantagesData = useSelector(
    (state) => state.nutrition.nutritionAdvantages
  );

  const [userData, setUserData] = useState(null);

  const advantages = [
    {
      key: "advantages1",
      id: "1",
      advantage: advantagesData && advantagesData["01_advantage"],
    },
    {
      key: "advantages2",
      id: "2",
      advantage: advantagesData && advantagesData["02_advantage"],
    },
    {
      key: "advantages3",
      id: "3",
      advantage: advantagesData && advantagesData["03_advantage"],
    },
  ];

  useEffect(() => {
    dispatch(
      NutritionActions.getNutritionAdvantagesRequest({
        nutritiontypeID: getRegistrationData()?.nutritiontypeID,
        aimID: getRegistrationData()?.aimID,
        sex: getRegistrationData()?.sex,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const anamnese = JSON.parse(localStorage.getItem("Anamnese"));

    // const anamnesePayload = {
    //   stress_level: anamnese?.StressLevel,
    //   body_fat: anamnese?.BodyShape,
    //   sport_activity: anamnese?.SportActivity,
    //   daily_activity: anamnese?.DailyActivity,
    //   smoking: anamnese?.Smoking,
    //   alcohol: anamnese?.Alcohol,
    //   fruits_vegitables: anamnese?.FruitVegetable,
    //   meal_frequency: anamnese?.MealFrequency,
    //   drinking: anamnese?.Drink,
    //   cravings: anamnese?.Cravings,
    //   mood_swings: anamnese?.MoodSwings,
    //   weekness_feeling: anamnese?.FeelingWeak,
    // };

    const anamnesePayload = {
      stress_level: anamnese?.StressLevel.images.find((item) => item.isActive)
        ?.value,
      body_fat: anamnese?.BodyShape.images.find((item) => item.isActive)?.value,
      sport_activity: anamnese?.SportActivity.numbers.find(
        (item) => item.isActive
      )?.value,
      daily_activity: anamnese?.DailyActivity.numbers.find(
        (item) => item.isActive
      )?.value,
      smoking: anamnese?.Smoking.numbers.find((item) => item.isActive)?.value,
      alcohol: anamnese?.Alcohol.numbers.find((item) => item.isActive)?.value,
      fruits_vegitables: anamnese?.FruitVegetable.numbers.find(
        (item) => item.isActive
      )?.value,
      meal_frequency: anamnese?.MealFrequency.numbers.find(
        (item) => item.isActive
      )?.value,
      drinking: anamnese?.Drink.numbers.find((item) => item.isActive)?.value,
      cravings: anamnese?.Cravings.numbers.find((item) => item.isActive)?.value,
      mood_swings: anamnese?.MoodSwings.numbers.find((item) => item.isActive)
        ?.value,
      weekness_feeling: anamnese?.FeelingWeak.numbers.find(
        (item) => item.isActive
      )?.value,
    };

    const registeredUser = JSON.parse(
      localStorage.getItem("RegisteredUserDetails")
    );
    setUserData(registeredUser?.["$nutritionconcept"]);
    const userID = registeredUser?.["$nutritionconcept"]?.userID;

    localStorage.setItem("AnamneseUserId", userID);

    if (!anamnese) return;

    dispatch(
      AuthActions.createUserAnamneseRequest({
        formData: {
          userID: userID,
          weekID: 1,
          ...anamnesePayload,
        },
        callback: () => {
          localStorage.removeItem("Anamnese");
          localStorage.removeItem("foodCategory");
        },
      })
    );

    // dispatch(
    //   NutritionActions.getUserDataRequest({
    //     // payload: {
    //     //   userID,
    //     // },
    //     callback: (response) => {
    //       console.log("NutritionActions.getUserDataRequest", response);

    //     },
    //   })
    // );
  }, [dispatch]);

  useEffect(() => {
    const IDs = JSON.parse(localStorage.getItem("NutrtionIds"));
    getCurrentNutritionType(
      IDs?.nutritionTypeID || getRegistrationData()?.nutritiontypeID
    );
    // getCurrentNutritionType(registrationData.nutritiontypeID);
    getAllNutritionTypes();
  }, []);

  const getCurrentNutritionType = (id) =>
    dispatch(
      NutritionActions.getNutritionTypeRequest({
        nutritiontypeID: id,
        aimID: getRegistrationData()?.aimID,
        sex: getRegistrationData()?.sex,
      })
    );

  const getAllNutritionTypes = () =>
    dispatch(
      NutritionActions.getAllNutritionTypesRequest({
        aimID: getRegistrationData()?.aimID,
        sex: getRegistrationData()?.sex,
      })
    );

  const handleChangeNutritionTypePopup = () => {
    const newNutritionType = allNutritionTypes.filter(
      (data) => data?.de_name === activeNutritionType?.de_name
    )[0];
    setSelectedNutritionForm(newNutritionType);
    setEditNutritionFormModal(true);
  };

  const handleNutritionTypeChange = () => {
    dispatch(
      NutritionActions.updateNutritionTypeRequest({
        payload: {
          nutritiontypeID: selectedNutritionForm?.nutritiontypeID,
          aimID: getRegistrationData()?.aimID,
          sex: getRegistrationData()?.sex,
          nutritionconceptID: userData?.fitnessdata?.nutritionconceptID,
        },
        callback: (res) => {
          if (res instanceof Error || !res) return;

          const IDs = JSON.parse(localStorage.getItem("NutrtionIds"));

          localStorage.setItem(
            "NutrtionIds",
            JSON.stringify({
              nutritionTypeID: res?.data?.nutritiontypeID,
              aimID: IDs?.aimID,
            })
          );

          getCurrentNutritionType(res?.data?.nutritiontypeID);
          dispatch(
            NutritionActions.getNutritionAdvantagesRequest({
              nutritiontypeID: res?.data?.nutritiontypeID,
              aimID: getRegistrationData()?.aimID,
              sex: getRegistrationData()?.sex,
            })
          );
        },
      })
    );
    // dispatch(
    //   AuthActions.setRegistrationData({
    //     ...registrationData,
    //     nutritiontypeID: selectedNutritionForm?.nutritiontypeID,
    //     userID: userData.userID,
    //   })
    // );

    localStorage.setItem(
      "RegistrationData",
      JSON.stringify({
        ...getRegistrationData(),
        nutritiontypeID: selectedNutritionForm?.nutritiontypeID,
        userID: userData?.userID,
        nutritionconceptID: userData?.fitnessdata?.nutritionconceptID,
      })
    );

    setEditNutritionFormModal(false);
  };

  const getRegistrationData = () => {
    const RD = JSON.parse(localStorage.getItem("RegistrationData"));
    return RD;
  };

  const handleBack = () => {
    history.push("/onboarding/nutrition/creation-video");
  };

  const handleNext = () => {
    // dispatch(
    //   AuthActions.setRegistrationData({
    //     ...registrationData,
    //     userID: userData.userID,
    //   })
    // );

    localStorage.setItem(
      "RegistrationData",
      JSON.stringify({
        ...getRegistrationData(),
        userID: userData?.userID,
        nutritionconceptID: userData?.fitnessdata?.nutritionconceptID,
      })
    );

    history.push("/onboarding/nutrition/recipies");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={3} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Auswertung
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Unsere Empfehlung für dich
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="nutrition-type-update-content mt-3">
                {loading && (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="text-center">
                      <div className="loader mx-auto"></div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}
                <>
                  {false ? (
                    <div style={{ textAlign: "center", marginTop: "50px" }}>
                      {/* <TranslationMessage id="trainingCompleted" /> */}
                    </div>
                  ) : (
                    <>
                      {!loading && (
                        <>
                          <div className="nutrition-type">
                            <div className="nutrition-type-item">
                              <img
                                src={LowCarbImage}
                                alt={
                                  activeNutritionType &&
                                  activeNutritionType.de_name
                                }
                              />
                              <p className="text-size-16-19 text-dark-1 mb-0 mt-1">
                                {activeNutritionType &&
                                  activeNutritionType.de_name}
                              </p>
                            </div>
                            <p className="text-size-16-24 text-dark-2 font-rubik-light">
                              {activeNutritionType?.["01_text"]}
                            </p>
                            <div>
                              <button
                                className="btn-outline"
                                onClick={handleChangeNutritionTypePopup}
                              >
                                <TranslationMessage id="button.editNutritionForm" />
                              </button>
                            </div>
                          </div>

                          <div className="divider my-1-5-rem"></div>
                          <h6 className="text-size-20-28 text-dark-1 my-1-rem">
                            Deine Vorteile
                          </h6>
                          <div className="nutrition-advantages">
                            {advantagesData &&
                              advantages.map((value) => {
                                return (
                                  <div
                                    className="nutrition-advantages-item d-flex gap-3"
                                    key={value.key}
                                  >
                                    <div className="index d-flex align-items-center justify-content-center">
                                      {value.id}
                                    </div>
                                    <div>
                                      {/* <h6 className="text-size-18-22 text-dark-1 my-0">
                                        Heading
                                      </h6> */}
                                      <p className="text-size-16-20 text-dark-2 mb-0 mt-1 font-rubik-light">
                                        {value.advantage}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </>
                      )}
                      <Modal
                        show={editNutritionFormModal}
                        setShow={setEditNutritionFormModal}
                        heading={
                          <h6 className="text-size-20-24 text-dark-1 text-left my-0">
                            <TranslationMessage id="nutrition.form.editNutritionFormHeading" />
                          </h6>
                        }
                        width="848px"
                        height="auto"
                      >
                        {<Loader loading={loading} top="200px" />}
                        {!loading && (
                          <>
                            <S.NutritionFormCardContainer>
                              {allNutritionTypes &&
                                allNutritionTypes.map((value) => {
                                  return (
                                    <S.NutritionFormCard
                                      key={value.nutritiontypeID}
                                      onClick={() => {
                                        setSelectedNutritionForm(value);
                                      }}
                                      selected={
                                        value?.nutritiontypeID ===
                                        selectedNutritionForm?.nutritiontypeID
                                      }
                                    >
                                      <S.NutritionCardImage
                                        src={LowCarbImage}
                                        alt={value.nutritiontypeID}
                                      />
                                      {value["de_name"]}
                                    </S.NutritionFormCard>
                                  );
                                })}
                            </S.NutritionFormCardContainer>
                            <br />
                            <p className="text-size-16-24 text-dark-2 text-left font-rubik-light">
                              {selectedNutritionForm["01_text"]}
                            </p>
                            {/* <S.NutritionFormDescription
                              dangerouslySetInnerHTML={{
                                __html: selectedNutritionForm["01_text"]
                                  ? selectedNutritionForm["01_text"].replace(
                                      /\n/g,
                                      "<br />"
                                    )
                                  : "",
                              }}
                            /> */}
                            <Button
                              color="rgba(46, 207, 150, .2)"
                              gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                              margin="30px auto 0px"
                              onClick={handleNutritionTypeChange}
                            >
                              <TranslationMessage id="button.saveChanges" />
                            </Button>
                          </>
                        )}
                      </Modal>
                    </>
                  )}
                </>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
        <br />
        <br />
        <br />
      </S.OnBoardingPage>
    </>
  );
};

export default NutritionTypeUpdate;
