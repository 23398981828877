import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";

import Input from "components/formComponents/input/Input";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import LeadIcon from "assets/images/Lead-Icon.svg";
import SearchIcon from "assets/images/search.svg";
import { MENUS } from "./SideMenu";
import * as S from "./MarketingPortal.styles";
import * as SupplementsActions from "redux/supplements/supplements.actions";
import MainLeadModal from "components/MainLeadModal/MainLeadModal";
import { DetailsDropDownObj } from "./data";

const initialData = {
  columns: {
    "column-1": {
      id: "column-1",
      title: "Erhaltene Leads",
      taskIds: [],
      statusIndicator: "taskColorOne",
    },
    "column-2": {
      id: "column-2",
      title: "Erster Kontaktversuch",
      taskIds: [],
      statusIndicator: "taskColorTwo",
    },
    "column-3": {
      id: "column-3",
      title: "Zweiter Kontaktversuch",
      taskIds: [],
      statusIndicator: "taskColorThree",
    },
    "column-4": {
      id: "column-4",
      title: "Erstgespräch vereinbart",
      taskIds: [],
      statusIndicator: "taskColorFour",
    },
    "column-5": {
      id: "column-5",
      title: "Kein Interesse",
      taskIds: [],
      statusIndicator: "taskColorFive",
    },
    "column-6": {
      id: "column-6",
      title: "No Show",
      taskIds: [],
      statusIndicator: "taskColorSix",
    },
    "column-7": {
      id: "column-7",
      title: "In Betreuung",
      taskIds: [],
      statusIndicator: "taskColorSeven",
    },
    "column-8": {
      id: "column-8",
      title: "Storniert",
      taskIds: [],
      statusIndicator: "taskColorEight",
    },

  },
  tasks: {
    // "task-1": { id: "task-1", content: "Take out the garbage" },
    // "task-2": { id: "task-2", content: "Watch my favorite show" },
    // "task-3": { id: "task-3", content: "Charge my phone" },
    // "task-4": { id: "task-4", content: "Cook dinner" },
  },
  columnOrder: ["column-1", "column-2", "column-3", "column-4"],
};

const MainLeads = () => {
  const dispatch = useDispatch();
  const [showLeadModal, setShowLeadModal] = useState(false);

  const [ColumnsSchema, SetColumnsSchema] = useState(initialData);
  const [cacheColumnsSchema, SetCacheColumnsSchema] = useState(initialData);
  const [SelectedColumnToShow, SetSelectedColumnToShow] = useState("All");
  const [searchTerm, SetSearchTerm] = useState("");

  const isTrainerLeadsLoading = useSelector(
    (state) => state.supplements.isTrainerLeadsLoading
  );

  const trainerData = useSelector((state) => state.auth.trainerData);

  let menus = MENUS

  useEffect(() => {
    getLeads();
  }, [dispatch]);

  useEffect(() => {
    if (searchTerm === '') {
      SetColumnsSchema(cacheColumnsSchema);
    }
    if (searchTerm) {
      const filteredColumns = {};
      const filteredTasks = {};
      const filteredColumnOrder = [];
      Object.keys(cacheColumnsSchema.tasks).forEach((taskId) => {
        const task = cacheColumnsSchema.tasks[taskId];
        if (task?.prename?.toLowerCase().includes(searchTerm) || task?.surename?.toLowerCase().includes(searchTerm)) {
          filteredTasks[taskId] = task;
        }
      })
      Object.keys(cacheColumnsSchema.columns).forEach((columnId) => {
        const column = cacheColumnsSchema.columns[columnId];

        const taskIds = column.taskIds.filter((taskId) => filteredTasks[taskId]);
        if (taskIds.length) {
          filteredColumns[columnId] = {
            ...column,
            taskIds,
          };
          filteredColumnOrder.push(columnId);
        }
      })
      SetColumnsSchema({
        ...ColumnsSchema,
        columns: filteredColumns,
        columnOrder: filteredColumnOrder,
      });
    }
  }, [searchTerm])

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    // Determine if the move is allowed based on the source and destination
    const isValidMove =
      (source.droppableId === "column-1" && ["column-2", "column-3", "column-4"].includes(destination.droppableId)) ||
      (source.droppableId === "column-2" && ["column-1", "column-3", "column-4"].includes(destination.droppableId)) ||
      (source.droppableId === "column-3" && ["column-1", "column-2", "column-4"].includes(destination.droppableId)) ||
      (source.droppableId === "column-4" && ["column-1", "column-2", "column-3"].includes(destination.droppableId));

    if (!isValidMove) {
      return;
    }

    // Prepare payload based on the destination column
    let payload = {
      connected_trainer: trainerData?.trainerID,
      lead_id: +draggableId,
    };

    if (destination.droppableId === "column-2") {
      payload = {
        ...payload,
        lead_status: "first contact",
        lead_contacted: moment().format("DD/MM/YYYY"),
      };
    } else if (destination.droppableId === "column-3") {
      payload = {
        ...payload,
        lead_status: "second contact",
      };
    } else if (destination.droppableId === "column-4") {
      payload = {
        ...payload,
        lead_status: "appointment",
      };
    }

    updateLead(payload);

    // If the item is dropped in the same location, no need to update state
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const sourceColumn = ColumnsSchema.columns[source.droppableId];
    const destinationColumn = ColumnsSchema.columns[destination.droppableId];

    if (sourceColumn === destinationColumn) {
      // Moving within the same column
      const newTaskIds = Array.from(sourceColumn.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, `task-${draggableId}`);

      const newColumn = {
        ...sourceColumn,
        taskIds: newTaskIds,
      };

      const newState = {
        ...ColumnsSchema,
        columns: {
          ...ColumnsSchema.columns,
          [newColumn.id]: newColumn,
        },
      };

      SetColumnsSchema(newState);
    } else {
      // Moving between columns
      const startTaskIds = Array.from(sourceColumn.taskIds);
      startTaskIds.splice(source.index, 1);

      const newStart = {
        ...sourceColumn,
        taskIds: startTaskIds,
      };

      const finishTaskIds = Array.from(destinationColumn.taskIds);
      finishTaskIds.splice(destination.index, 0, `task-${draggableId}`);

      const newFinish = {
        ...destinationColumn,
        taskIds: finishTaskIds,
      };

      const newState = {
        ...ColumnsSchema,
        columns: {
          ...ColumnsSchema.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      };

      SetColumnsSchema(newState);
    }
  };


  const getLeads = () => {
    dispatch(
      SupplementsActions.getTrainerLeadsRequest({
        payload: {
          connected_trainer: trainerData?.trainerID,
        },
        callback: (res) => {
          let leads = res?.getTrainerLeadsData || [];
          leads = leads.map((lead, i) => ({
            ...lead,
            id: i.toString(),
            lead_id: lead?.lead_id?.toString(),
          }));

          const tasks = {};
          const columnOneLeads = [],
            columnTwoLeads = [],
            columnThreeLeads = [],
            columnFourLeads = [],
            columnFiveLeads = [],
            columnSixLeads = [],
            columnSevenLeads = [],
            columnEightLeads = [];

          leads.forEach((element, i) => {
            const taskId = "task-" + element?.lead_id;

            tasks[taskId] = element;

            if (element?.lead_status === 'received') {
              columnOneLeads.push(taskId);
            }
            if (element?.lead_status === "first contact") {
              columnTwoLeads.push(taskId);
            }
            if (element?.lead_status === "second contact") {
              columnThreeLeads.push(taskId);
            }
            if (element?.lead_status === "appointment") {
              columnFourLeads.push(taskId);
            }

            if (element?.lead_status === "notinterested") {
              columnFiveLeads.push(taskId);
            }
            if (element?.lead_status === 'no show') {
              columnSixLeads.push(taskId);
            }
            if (element?.lead_status === 'in consultation') {
              columnSevenLeads.push(taskId);
            }
            if (element?.lead_status === 'storno') {
              columnEightLeads.push(taskId);
            }

          });


          const updatedState = {
            ...ColumnsSchema,
            columns: {
              ...ColumnsSchema.columns,
              "column-1": {
                ...ColumnsSchema.columns["column-1"],
                taskIds: columnOneLeads,
              },
              "column-2": {
                ...ColumnsSchema.columns["column-2"],
                taskIds: columnTwoLeads,
              },
              "column-3": {
                ...ColumnsSchema.columns["column-3"],
                taskIds: columnThreeLeads,
              },
              "column-4": {
                ...ColumnsSchema.columns["column-4"],
                taskIds: columnFourLeads,
              },
              "column-5": {
                ...ColumnsSchema.columns["column-5"],
                taskIds: columnFiveLeads,
              },
              "column-6": {
                ...ColumnsSchema.columns["column-6"],
                taskIds: columnSixLeads,
              },
              "column-7": {
                ...ColumnsSchema.columns["column-7"],
                taskIds: columnSevenLeads,
              },
              "column-8": {
                ...ColumnsSchema.columns["column-8"],
                taskIds: columnEightLeads,
              }

            },
            tasks,
          };

          SetColumnsSchema(updatedState);
          SetCacheColumnsSchema(updatedState);

        },
      })
    );
  };

  const updateLead = (payload) => {
    dispatch(
      SupplementsActions.updateTrainerLeadRequest({
        payload,
        callback: (res) => {
          getLeads();
        },
      })
    );
  };

  const handleColumnFilters = (t) => {
    SetSelectedColumnToShow(t);

    // Define default columns to always show
    const defaultColumnOrder = ["column-1", "column-2", "column-3", "column-4"];
    let updatedColumnOrder = [...defaultColumnOrder];

    // Add selected columns to the order
    if (t === "KeinInteresse") {
      updatedColumnOrder.push("column-5");
    } else if (t === "NoShow") {
      updatedColumnOrder.push("column-6");
    } else if (t === "InBetreuung") {
      updatedColumnOrder.push("column-7");
    } else if (t === "Storniert") {
      updatedColumnOrder.push("column-8");
    }

    SetColumnsSchema(prevSchema => ({
      ...prevSchema,
      columnOrder: updatedColumnOrder
    }));
  };

  const onSearchInput = (e) => {
    let valueToSearch = e.target.value;
    valueToSearch = valueToSearch.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
    SetSearchTerm(valueToSearch);
  }
  const MemoizedModal = React.useMemo(() => {
    return <MainLeadModal show={showLeadModal} setShow={setShowLeadModal} callLeads={getLeads} />;
  }
    , [showLeadModal]);
  const handleBtnClick = () => setShowLeadModal(true);

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={menus} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.TrainerProfileDetailsInnerContainer>
            <S.TrainerProfileDetailsInnerChildContainer>
              <S.Header className="p-0">

                <div>
                  <S.TrainerProfileDetailsSectionHeading>
                    Deine Leadliste
                  </S.TrainerProfileDetailsSectionHeading>
                  <S.TrainerProfileDetailsSectionSubHeading>
                    Hier findest du eine Übersicht deiner Leads.
                  </S.TrainerProfileDetailsSectionSubHeading>
                </div>
                <div>{/* <button className="btn-dark">Speichern</button> */}</div>
              </S.Header>
              <button onClick={handleBtnClick} className="btn-dark">
                Eigenen Lead anlegen
              </button>
            </S.TrainerProfileDetailsInnerChildContainer>
            <S.Divider />
            <br />
            <div className="categories-buttons-container">
              <div className="categories-buttons">
                {[
                  { title: "Im Flow", value: "All" },
                  // { title: "Erhalten", value: "Erhalten" },
                  // { title: "Kontaktiert", value: "Kontaktiert" },
                  // { title: "Erstgespräch", value: "Erstgesprach" },
                  // { title: "Verloren", value: "Verloren" },
                  { title: "Kein Interesse", value: "KeinInteresse" },
                  { title: 'No Show', value: 'NoShow' },
                  { title: 'In Betreuung', value: 'InBetreuung' },
                  { title: 'Storniert', value: 'Storniert' }

                ].map((t) => (
                  <div
                    className={
                      SelectedColumnToShow === t.value
                        ? "item text-size-14-17 text-dark-2 active"
                        : "item text-size-14-17 text-dark-2"
                    }
                    key={t.value}
                    onClick={() => handleColumnFilters(t.value)}
                  >
                    {t.title}
                  </div>
                ))}
              </div>
              <div>
                <S.FormControlSearch >
                  <Input
                    className="input-custom"
                    style={{ width: "100%", marginBottom: "0", }}
                    placeholder="Suche nach Leads"
                    onChange={onSearchInput}
                  // value={SearchTerm}
                  />
                  <img src={SearchIcon} alt="SearchIcon" />
                </S.FormControlSearch>
              </div>
            </div>
            <br />

            {isTrainerLeadsLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}

            {!isTrainerLeadsLoading && (
              <DragDropContext onDragEnd={onDragEnd}>
                <div className={"drag-drop-context " + SelectedColumnToShow}>
                  {ColumnsSchema.columnOrder.map((columnId) => {

                    const column = ColumnsSchema.columns[columnId];
                    if (!column) return null;
                    const tasks = column?.taskIds.map(
                      (taskId) => ColumnsSchema.tasks[taskId]
                    );
                    return (
                      <Column
                        key={column.id + column?.title}
                        column={column}
                        tasks={tasks}
                      />
                    );
                  })}
                </div>
              </DragDropContext>
            )}
          </S.TrainerProfileDetailsInnerContainer>

        </S.Wrapper>
        {MemoizedModal}
      </S.Container>
    </>
  );
};

export default MainLeads;

const Column = ({ column, tasks }) => {
  return (
    <div className="taskWrap">
      <div className="taskWrapHead">
        <div className="taskWrapHeadLeft">
          <span className={column?.statusIndicator}></span>
          <h3>{column?.title}</h3>
        </div>
        <div className="taskWrapHeadRight">
          <span>{column?.taskIds?.length}</span>
        </div>
      </div>
      <Droppable droppableId={column.id}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="taskItemsWrap"
          >
            {tasks.map((task, index) => (
              <Task
                key={task?.lead_id + task?.prename}
                task={task}
                index={index}
              />
            ))}
            {provided?.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

const Task = ({ task, index }) => {

  const history = useHistory();

  return (
    <Draggable draggableId={task?.lead_id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="taskItems"
        >
          <div className="taskItemsUpper">
            <div className="taskItemsTop">
              <h4>
                {task?.prename} {task?.surename}
              </h4>
              <span>
                <img src={LeadIcon} alt="Image" />
              </span>
            </div>

            <div className="taskItemsMid">
              <div className="taskItemsMidRow">
                <span>Lead erhalten am:</span>
                <span>
                  {task?.lead_received && (
                    <>
                      {moment(new Date(task?.lead_received)).format(
                        "DD.MM.YYYY"
                      )}
                    </>
                  )}
                </span>
              </div>
              <div className="taskItemsMidRow">
                <span>Lead von:</span>
                <span>{task?.lead_source}</span>
              </div>
            </div>
          </div>

          <div className="taskItemsLast">
            <div className="taskItemsLastName">
              <span className={DetailsDropDownObj[task.lead_status].className}></span>
              {DetailsDropDownObj[task.lead_status].label}
            </div>

            <button
              onClick={() => {
                history.push(`/dashboard/leads/all/details/${task?.lead_id}`);
              }}
            >
              Details
            </button>
          </div>
        </div>
      )}
    </Draggable>
  );
};
