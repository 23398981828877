import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { getS3ImageUrl } from "configs/utils";
import { ToastContainer } from "react-toastify";

import LinkIcon from "assets/images/link.svg";
import PDFIcon from "assets/images/pdf.svg";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import DownloadIcon from "assets/images/downloadBlack.svg";
import ArrowRightAngleIcon from "assets/images/arrowRightAngle.svg";
import BackArrowIcon from 'assets/images/icon-back-arrow.svg'

import Loader from "react-spinners/CircleLoader";
import { MENUS } from "./data";
import PasswordResetModal from "components/PasswordResetModal/PasswordResetModal";

import * as AuthActions from "redux/auth/auth.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "../../components/clientDetails/clientDetails.styles";
import * as C from "./trainerProfileDetails.styles";
import "../../components/clientDetails/clientDetails.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CreditsDetails = () => {
  const history = useHistory()
  const [showLoader, setShowLoader] = useState(false);
  const [passwordResetModal, setPasswordResetModal] = useState(false);

  const dispatch = useDispatch();
  const alert = useAlert();
  const data = useParams();
  const query = useQuery();
  const { userId } = useParams(); // This will get the userId if it's a part of the URL path
  console.log("data", data);
  const invoiceId = query.get("invoiceId");
  const queryUserId = query.get("userId");
  console.log("invoiceId", invoiceId);
  console.log("queryUserId", queryUserId);
  console.log("userId", userId);

  const trainerData = useSelector((state) => state.auth.trainerData);
  const usersRelatedToTrainer = useSelector(
    (state) => state.auth.usersRelatedToTrainer
  );

  const userInvoice = useSelector((state) => state.auth.userInvoice);
  const invoiceData = useSelector((state) => state.auth.invoiceData);

  const details = usersRelatedToTrainer?.data?.find(
    (data) => data.userID === +userId
  );

  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    if (invoiceId) {
      dispatch(
        AuthActions.getInvoicesByTrainerRequest({
          formData: {
            trainerID: trainerData.trainerID,
          },
          callback: (res) => {
            Object.entries(res.data).map(([key, value]) => {
              if (value.id == invoiceId) {
                setSelectedInvoice(value);
              }
            });
          },
        })
      );
    }
  }, [invoiceId]);

  const renderStatusBadge = (status) => {
    switch (status) {
      case "Completed":
        return (
          <div className="status-badge text-center green">Erfolgreich</div>
        );

      case "Incomplete":
        return (
          <div className="status-badge text-center orange-yellow">
            Unvollständig
          </div>
        );

      case "Processing":
        return (
          <div className="status-badge text-center orange-yellow">
            Wird bearbeitet
          </div>
        );

      case "Canceled":
        return <div className="status-badge text-center red">Storniert</div>;

      case "Pending":
        return <div className="status-badge text-center grey">Ausstehend</div>;

      case "Refunded":
        return (
          <div className="status-badge text-center red">Zurückerstattet</div>
        );

      default:
        return null;
    }
  };

  const goToMainLeadsAll = () => history.push('/dashboard/trainer-credits')

  console.log("selectedInvoice", selectedInvoice);

  return (
    <>
      <ToastContainer />
      <div className="second-sidebar-wrapper">
        <InnerSideNav
          menus={MENUS}
          userProfileInfo={{
            profilePic: getS3ImageUrl(trainerData.profilePic),
            franchiseId: trainerData?.FranchiseID,
            stripeId: trainerData?.["Stripe Connect ID"] || "",
            prename: trainerData.prename,
            surname: trainerData.surname,
            connected_user_account_id: trainerData?.connected_user_account_id,
          }}
          onPasswordLink={() => setPasswordResetModal(true)}
          idsSection
        ></InnerSideNav>
      </div>

      <PasswordResetModal
        show={passwordResetModal}
        setShow={setPasswordResetModal}
      />
      <div className="second-sidebar-content">
        <C.TrainerProfileDetailsOverallContainer>
          {selectedInvoice && (
            <S.ContainerBox>
              {showLoader ? (
                <div className="d-flex justify-content-center align-items-center">
                  <FadeLoader />
                </div>
              ) : (
                <>
                  <S.Header>
                    <div className="heading-with-back-icon">
                      <span onClick={goToMainLeadsAll}><img src={BackArrowIcon} alt="" /></span>
                      <div>
                        <S.InvoiceDetailsSectionHeading>
                          Abrechnungsdetails
                        </S.InvoiceDetailsSectionHeading>
                        <S.InvoiceDetailsSectionSubHeading>
                          Details für die Abrechnung des Klienten.
                        </S.InvoiceDetailsSectionSubHeading>
                      </div>
                    </div>
                  </S.Header>

                  <S.Divider />

                  <S.InvoiceDetailsContainer>
                    <br />

                    <S.InvoiceDetailsHeader>
                      <h6>{`${trainerData?.prename} ${trainerData?.surname}`}</h6>

                      <div className="d-flex align-items-center gap-3">
                        <span>Transaktionsstatus</span>

                        {renderStatusBadge(selectedInvoice.payment_status)}
                      </div>
                    </S.InvoiceDetailsHeader>

                    <br />

                    <S.Divider></S.Divider>

                    <S.DataSection>
                      <h6>Timestamps</h6>

                      <div className="data-rows">
                        <div className="data-row">
                          <p>Rechnung erstellt am:</p>

                          <p>
                            {selectedInvoice.invoice_created_at
                              .split("-")
                              .reverse()
                              .join("/")}
                          </p>
                        </div>

                        <div className="data-row">
                          <p>Bezahlt am:</p>

                          <p>
                            {selectedInvoice.invoice_paid_at
                              ? selectedInvoice.invoice_paid_at
                                  .split("-")
                                  .reverse()
                                  .join("/")
                              : "noch nicht verfügbar"}
                          </p>
                        </div>

                        <div className="data-row">
                          <p>Geldeingang am:</p>

                          <p>
                            {selectedInvoice.invoice_money_arrived_at
                              ? selectedInvoice.invoice_money_arrived_at
                                  .split("-")
                                  .reverse()
                                  .join("/")
                              : "noch nicht verfügbar"}
                          </p>
                        </div>
                      </div>
                    </S.DataSection>

                    <S.Divider></S.Divider>

                    <S.DataSection>
                      <h6>Bezahldetails</h6>

                      <div className="data-rows">
                        <div className="data-row">
                          <p>Gesamtbetrag</p>

                          <p>{selectedInvoice.invoice_amount} €</p>
                        </div>

                        <div className="data-row">
                          <p>Transaktionsgebühr</p>

                          <p>{selectedInvoice.transaction_fee} €</p>
                        </div>

                        <div className="data-row">
                          <p>Höhe der Betreuungsgutschrift</p>

                          <p>{selectedInvoice.amount_transferred} €</p>
                        </div>
                      </div>

                      <span className="note">
                        *Werbepartnerschaften werden gesondert abgerechnet
                      </span>
                    </S.DataSection>

                    <S.Divider></S.Divider>

                    <S.DataSection>
                      <h6>Bezahlmethode</h6>

                      <div className="data-rows">
                        <div className="data-row">
                          <p>ID der Rechnung</p>

                          <p>{selectedInvoice.invoice_id}</p>
                        </div>

                        <div className="data-row">
                          <p>Quellentransaktion</p>

                          <p>
                            {selectedInvoice.source_transaction_id
                              ? selectedInvoice.source_transaction_id
                              : "noch nicht verfügbar"}
                          </p>
                        </div>

                        <div className="data-row">
                          <p>ID des Transfers</p>

                          <p>
                            {selectedInvoice.transfers_id
                              ? selectedInvoice.transfers_id
                              : "noch nicht verfügbar"}
                          </p>
                        </div>

                        <div className="data-row">
                          <p>ID der Auszahlung</p>

                          <p>
                            {selectedInvoice.payout_id
                              ? selectedInvoice.payout_id
                              : "noch nicht verfügbar"}
                          </p>
                        </div>
                      </div>
                    </S.DataSection>

                    <S.Divider></S.Divider>

                    <S.DataSection>
                      <div className="d-flex align-items-center justify-content-between">
                        <h6>Bezahllink</h6>

                        <div className="link-box">
                          <img src={LinkIcon} alt="" />

                          <a
                            href={selectedInvoice.payment_link_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {selectedInvoice.payment_link_url}
                          </a>
                        </div>
                      </div>

                      <div className="d-flex align-items-center justify-content-between">
                        <h6>Transaktionsbeleg</h6>

                        {selectedInvoice &&
                        selectedInvoice.transaction_pdf_url ? (
                          <div className="file-item d-flex align-items-center">
                            <img width={30} src={PDFIcon} alt="" />
                            <a
                              href={selectedInvoice.transaction_pdf_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {selectedInvoice.transfers_id.substring(0, 8)}
                            </a>
                          </div>
                        ) : (
                          <div className="file-item">
                            <span>noch nicht verfügbar</span>
                          </div>
                        )}
                      </div>
                    </S.DataSection>

                    <br />
                  </S.InvoiceDetailsContainer>
                </>
              )}
            </S.ContainerBox>
          )}
        </C.TrainerProfileDetailsOverallContainer>
      </div>
    </>
  );
};

export default CreditsDetails;
