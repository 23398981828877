import { useEffect, useState } from "react";
import Select from "react-select";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import Tap from "@tapfiliate/tapfiliate-js";
import { toast } from "react-toastify"

import Modal from 'components/modal/Modal'
import * as MainLead from "./MainLead.styles"
import CrossIcon from "assets/images/cross.svg";
import { FieldErrorMessages, LeadAgeOptions, LeadGenderOptions, LeadGoalOptions, stateFieldError } from "./data";
import TextArea from "components/formComponents/textArea/TextArea";
import Input from "components/formComponents/input/Input";
import DatePicker from "components/formComponents/datePicker/DatePicker";
import * as SupplementsActions from "redux/supplements/supplements.actions";
import Loader from "components/loader/Loader"
import TapFiliate from "./TapFiliate";

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    borderRadius: '10px'
  }),
};

const MainLeadModal = ({ show, setShow, callLeads }) => {
  const [ipAddress, setIpAddress] = useState('')
  const [fields, setFields] = useState({
    leadAge: "",
    leadDate: '',
    leadGoal: "",
    leadGender: "",
    prename: "",
    surename: "",
    mobilenumber: "",
    email: "",
    leadInformations: ""
  })
  const [fieldError, setFieldError] = useState(stateFieldError)
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state);

  const { trainerData: {
    affiliate_code,
    FranchiseID,
    email,
    trainerID
  } } = reducer?.auth ?? {}

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIpAddress(data.ip))
      .catch(error => console.error('Error fetching IP:', error));

  }, []);

  useEffect(() => {
    let errorFields = { ...fieldError }
    for (const key in fields) {
      if (fields[key] !== "") {
        errorFields[key].type = false;
        errorFields[key].message = '';
      }
    }
    setFieldError(errorFields)
  }, [fields])

  const emptyState = () => {
    setFields({
      leadAge: "",
      leadDate: moment().format('YYYY-MM-DD'),
      leadGoal: "",
      leadGender: "",
      prename: "",
      surename: "",
      mobilenumber: "",
      email: "",
      leadInformations: ""
    })
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value })
  }

  const handleSubmit = () => {
    let error = false;
    let errorFields = { ...fieldError }
    for (const key in fields) {
      if (fields[key] === "") {
        error = true;
        errorFields[key].type = true;
        errorFields[key].message = FieldErrorMessages[key]
      }
    }
    setFieldError(errorFields)
    if (error) return;

    const payload = {
      connected_trainer: trainerID,
      prename: fields.prename,
      surename: fields.surename,
      mobilenumber: fields.mobilenumber,
      email: fields.email,
      lead_source: "Selbst angelegt",
      lead_received: moment(fields.leadDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      lead_status: "received",
      lead_goal: fields.leadGoal.value,
      lead_gender: fields.leadGender.value,
      lead_age: fields.leadAge.value,
      lead_notice: fields.leadInformations
    }
    dispatch(SupplementsActions.createLeadCenterRequest({
      payload,
      callback: async (payload) => {
        if (!payload.success) {
          let errorFields = { ...fieldError }
          Object.entries(payload.response.data.error).map((error) => {
            errorFields[error[0]].type = true;
            errorFields[error[0]].message = error[1][0]
          })
        }
        else {
          callTAPAffiliateApi();
        }
      },

    }))
  }

  const callTAPAffiliateApi = () => {

    try {
      toast("Lead wurde erfolgreich angelegt", { type: "success" })
      const customerData = {
        customer_id: fields.email,
        referral_code: affiliate_code,
        meta_data: {
          vorname: fields.prename,
          nachname: fields.surename,
          franchiseID: FranchiseID,
          email: email,
          user_agent: navigator.userAgent,
          ip: ipAddress
        }
      }
      TapFiliate(customerData)
      emptyState();
      callLeads();
      setShow(false)
    } catch (error) {
      console.log(error)
    }
  }

  function ErrorText({ fieldName }) {
    return (
      <MainLead.MainLeadErrorText>
        {fieldError[fieldName].type && fieldError[fieldName].message}
      </MainLead.MainLeadErrorText>
    )
  }

  return (
    <Modal
      width={'55%'}
      height={'95%'}
      show={show}
    >
      {reducer.supplements.createLeadCenterLoading && <Loader top={'300px'} />}
      <MainLead.LeadCardContainer>
        <div className="lead-card-header">
          <h3>Lege deinen Lead an</h3>
          <div className="close">
            <img
              onClick={() => setShow(false)}
              src={CrossIcon}
              alt="icon"
            />
          </div>

        </div>
        <MainLead.LeadCardBody>
          <div className="lead-card-parent">
            <div className="lead-card-child">
              <label>
                Welches Ziel verfolgt dein Lead?
              </label>
              <Select
                styles={customStyles}
                options={LeadGoalOptions}
                classNamePrefix="react-select"
                onChange={(value) => setFields(prev => ({ ...prev, leadGoal: value }))}
                value={fields.leadGoal}
                placeholder="Wähle das Ziel"
                isSearchable={false}
              />
              {fieldError.leadGoal && <ErrorText fieldName="leadGoal" />}
            </div>
            <div className="lead-card-child">
              <label>
                Welches Geschlecht hat dein Lead?
              </label>
              <Select
                styles={customStyles}
                options={LeadGenderOptions}
                classNamePrefix="react-select"
                onChange={(value) => setFields(prev => ({ ...prev, leadGender: value }))}
                value={fields.leadGender}
                placeholder="Wähle das Geschlecht"
                isSearchable={false}
              />
              {fieldError.leadGender && <ErrorText fieldName="leadGender" />}
            </div>
          </div>
          <div className="lead-card-parent">
            <div className="lead-card-child">
              <label>
                Wie alt ist dein Lead?
              </label>
              <Select
                styles={customStyles}
                options={LeadAgeOptions}
                classNamePrefix="react-select"
                onChange={(value) => setFields(prev => ({ ...prev, leadAge: value }))}
                value={fields.leadAge}
                placeholder="Wie alt ist dein Lead"
                isSearchable={false}
              />
              {fieldError.leadAge && <ErrorText fieldName="leadAge" />}
            </div>
            <div className="lead-card-child">
              <label>Wann trägst du den Lead ein?
              </label>
              <DatePicker
                value={fields.leadDate}
                onDateChange={(date) => { setFields(prev => ({ ...prev, leadDate: date })) }}
                placeholder={'DD.MM.YYYY'}
                format={'DD.MM.YYYY'}
                name="dob"
              />
              {fieldError.leadDate && <ErrorText fieldName="leadDate" />}
            </div>
          </div>
          <h3 style={{ margin: '20px 0px' }}>Persönliche Daten</h3>
          <div className="lead-card-parent">
            <div className="lead-card-child">
              <label>
                Vorname deines Leads:
              </label>
              <Input
                type="text"
                name='prename'
                placeholder="Vorname"
                onChange={handleInputChange}
                value={fields.prename}
              />
              {fieldError.prename && <ErrorText fieldName="prename" />}
            </div>
            <div className="lead-card-child">
              <label>
                Nachname deines Leads:
              </label>
              <Input
                type="text"
                name='surename'
                placeholder="Nachname"
                onChange={handleInputChange}
                value={fields.surename}

              />
              {fieldError.surename && <ErrorText fieldName="surename" />}
            </div>
          </div>
          <div className="lead-card-parent">
            <div className="lead-card-child">
              <label>
                Telefonnummer deines Leads:
              </label>
              <Input
                type="text"
                name='mobilenumber'
                placeholder="Telefonnummer"
                onChange={handleInputChange}
                value={fields.mobilenumber}

              />
              {fieldError.mobilenumber && <ErrorText fieldName="mobilenumber" />}
            </div>
            <div className="lead-card-child">
              <label>
                E-Mail deines Leads:
              </label>
              <Input
                type="text"
                name='email'
                placeholder="E-Mail"
                onChange={handleInputChange}
                value={fields.email}

              />
              {fieldError.email && <ErrorText fieldName="email" />}
            </div>
          </div>
          <h3 style={{ margin: '20px 0px' }}>Notiz</h3>
          <label style={{ fontSize: '14px' }}>Mache Notizen zu deinem Lead:</label>
          <TextArea
            rows={6}
            value={fields.leadInformations}
            name="leadInformations"
            placeholder=""
            onChange={handleInputChange}
            style={{
              margin: "12px 0 5px",
              border: "1px solid #E2E4E9",
              height: "15vh",
              borderRadius: "10px",

            }}
          />
          {fieldError.leadInformations && <ErrorText fieldName="leadInformations" />}
          <MainLead.LeadCardSpanCount>
            <span
              className={
                fields.leadInformations.length > 400
                  ? "text-red font-rubik-medium"
                  : ""
              }
            >
              {fields.leadInformations.length}
            </span>{" "}
            / 400
          </MainLead.LeadCardSpanCount>
          <MainLead.LeadCardBtn>
            <button disabled={reducer.supplements.createLeadCenterLoading} onClick={handleSubmit} className="btn-dark">
              Lead anlegen
            </button>
          </MainLead.LeadCardBtn>
        </MainLead.LeadCardBody>

      </MainLead.LeadCardContainer>


    </Modal>
  )
}

export default MainLeadModal
