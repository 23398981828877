import { takeLatest, put, all, call } from "redux-saga/effects";

import ApiCollections from "configs/services/apiCollections";

import AuthActionTypes from "redux/auth/auth.types";
import * as AuthActions from "./auth.actions";

/*************************************************/

export function* signIn({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.signIn, payload?.formData);
    console.log("SignIn===response", { ...response, isError: false });
    yield put(AuthActions.signInSuccess({ ...response, isError: false }));
    yield put(
      AuthActions.setRegistrationData({
        trainerID: response?.data?.trainerID || null,
        FranchiseID: response?.data?.FranchiseID || null,
      })
    );
    yield put(
      AuthActions.getTrainerDetailsSuccess({
        trainerID: response?.data?.trainerID || null,
        FranchiseID: response?.data?.FranchiseID || null,
      })
    );
    localStorage.setItem("trainer_token", response?.data?.token || null);
    localStorage.setItem("token", response?.data?.token || null);
    yield put(AuthActions.loadingStop());
    payload?.callback({ ...response, isError: false });
  } catch (error) {
    console.log("SignIn===error", { isError: true, message: error?.message });
    yield put(
      AuthActions.signInFailure({ isError: true, message: error?.message })
    );
    yield put(AuthActions.loadingStop());
    payload?.callback({ isError: false, message: error?.message });
  }
}

/*************************************************/

export function* tokenLogin({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.getNutritionToken,
      payload.formData
    );
    yield put(AuthActions.tokenLoginSuccess(response.data));
    payload.formDataLoginWithToken.formData.nutritionconcept_token =
      response.data.nutritionconcept_token;
    yield put(
      AuthActions.signInExistingPlanRequest(payload.formDataLoginWithToken)
    );
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.tokenLoginFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* existingPlanSignIn({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.existingPlanSignIn,
      payload.formData
    );
    yield put(AuthActions.signInExistingPlanSuccess(response.data.token));
    yield put(
      AuthActions.setRegistrationData({
        trainerID: payload.formData.trainerID,
        FranchiseID: payload.formData.FranchiseID,
        ...response.data.userData,
      })
    );
    localStorage.setItem("token", response.data.token);
    yield put(AuthActions.loadingStop());
    payload.func && payload.func();
  } catch (error) {
    yield put(AuthActions.signInExistingPlanFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* trainerSignUp({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.trainerSignUp, payload);
    yield put(AuthActions.trainerSignUpSuccess(response.data.token));
    yield put(
      AuthActions.setRegistrationData({
        trainerID: response.data.trainerID,
        FranchiseID: response.data.FranchiseID,
      })
    );
    localStorage.setItem("trainer_token", response.data.token);
    localStorage.setItem("token", response.data.token);
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.trainerSignUpFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* changePassword({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.changePassword,
      payload.formValues
    );
    yield put(AuthActions.changePasswordSuccess(response.message));
    localStorage.clear();
    yield put(payload.func());
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.changePasswordFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* trainerDetailsEdit({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.trainerDetailsEdit, payload);
    yield put(AuthActions.trainerProfileEditSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.trainerProfileEditFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getUsersByTrainer({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getUsersByTrainer, payload);
    yield put(AuthActions.getUsersByTrainerSuccess(response.data));
    yield put(AuthActions.loadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(AuthActions.getUsersByTrainerFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getInvoicesByTrainer({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getInvoicesByTrainer, payload);
    yield put(AuthActions.getInvoicesByTrainerSuccess(response.data));
    yield put(AuthActions.loadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(AuthActions.getInvoicesByTrainerFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getPaidInvoicesByTrainer({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getInvoicesByTrainer, {
      ...payload,
      formData: {
        ...payload.formData,
        showPaidInvoice: true,
      },
    });

    if (!response || !response.success || !response.data) {
      throw new Error("Could not get paid invoice data");
    }

    yield put(AuthActions.loadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(AuthActions.getInvoicesByTrainerFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getInvoicesOfUserByTrainer({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getInvoicesByUser, payload);
    yield put(AuthActions.getInvoicesOfUserByTrainerSuccess(response.data));
    yield put(AuthActions.loadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(AuthActions.getInvoicesOfUserByTrainerFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getUserDetailsByTrainer({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.getUsersDetailsByTrainer,
      payload
    );
    yield put(AuthActions.getUserDetailsByTrainerSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.getUserDetailsByTrainerFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getClientDetails({ payload }) {
  yield put(AuthActions.getClientDetailsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getClientDetails,
      payload?.payload
    );
    yield put(AuthActions.getClientDetailsSuccess(response));
    yield put(AuthActions.getClientDetailsLoadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(AuthActions.getClientDetailsFailure(error));
    yield put(AuthActions.getClientDetailsLoadingStop());
  }
}

/*************************************************/

export function* updateUserDetailsByTrainer({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.updateUserDetailsByTrainer,
      payload.formData
    );
    yield put(AuthActions.updateUserDetailsByTrainerSuccess(response.data));
    yield put(AuthActions.getUsersByTrainerRequest(payload.getUsersFormData));
    yield put(AuthActions.loadingStop());
    payload.func && payload.func();
  } catch (error) {
    yield put(AuthActions.updateUserDetailsByTrainerFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* passwordReset({ payload }) {
  yield put(AuthActions.passwordResetLoadingStart());
  try {
    const response = yield call(ApiCollections.passwordReset, payload.formData);
    yield put(AuthActions.passwordResetSuccess(response));
    yield put(AuthActions.passwordResetLoadingStop());
    payload?.callback?.();
  } catch (error) {
    yield put(AuthActions.passwordResetFailure(error));
    yield put(AuthActions.passwordResetLoadingStop());
  }
}

/*************************************************/

export function* getTrainerDetails({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getTrainerDetails, payload);
    yield put(AuthActions.getTrainerDetailsSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.getTrainerDetailsFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* updateTrainerDetails({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.updateTrainerDetails,
      payload.formData
    );
    yield put(AuthActions.updateTrainerDetailsSuccess(response));
    yield put(AuthActions.loadingStop());
    payload.funcSuccess(response);
  } catch (error) {
    yield put(AuthActions.updateTrainerDetailsFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getCoachCardWebsiteDetails({ payload }) {
  yield put(AuthActions.getCoachCardWebsiteDetailsLoadingStart());
  try {
    const response = yield call(ApiCollections.getCoachCardWebsiteDetails, payload);
    yield put(AuthActions.getCoachCardWebsiteDetailsSuccess(response.data));
    payload?.callback(response);
    yield put(AuthActions.getCoachCardWebsiteDetailsLoadingStop());
  } catch (error) {
    yield put(AuthActions.getCoachCardWebsiteDetailsFailure(error));
    yield put(AuthActions.getCoachCardWebsiteDetailsLoadingStop());
  }
}

/*************************************************/

export function* updateCoachCardWebsiteDetails({ payload }) {
  yield put(AuthActions.updateCoachCardWebsiteDetailsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.updateCoachCardWebsiteDetails,
      payload.formData
    );
    yield put(AuthActions.updateCoachCardWebsiteDetailsSuccess(response));
    yield put(AuthActions.updateCoachCardWebsiteDetailsLoadingStop());
    payload.funcSuccess(response);
  } catch (error) {
    yield put(AuthActions.updateCoachCardWebsiteDetailsFailure(error));
    yield put(AuthActions.updateCoachCardWebsiteDetailsLoadingStop());
  }
}

/*************************************************/
export function* getCoachCardDetails({ payload }) {
  yield put(AuthActions.getCoachCardDetailsLoadingStart());
  try {
    const response = yield call(ApiCollections.getCoachCardDetails, payload);
    yield put(AuthActions.getCoachCardDetailsSuccess(response.data));
    payload?.callback(response);
    yield put(AuthActions.getCoachCardDetailsLoadingStop());
  } catch (error) {
    yield put(AuthActions.getCoachCardDetailsFailure(error));
    yield put(AuthActions.getCoachCardDetailsLoadingStop());
    payload.funcSuccess(error.response);
  }
}

/*************************************************/

export function* updateCoachCardDetails({ payload }) {
  yield put(AuthActions.updateCoachCardDetailsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.updateCoachCardDetails,
      payload.formData
    );
    yield put(AuthActions.updateCoachCardDetailsSuccess(response));
    yield put(AuthActions.updateCoachCardDetailsLoadingStop());
    payload.funcSuccess(response);
  } catch (error) {
    yield put(AuthActions.updateTrainerDetailsFailure(error));
    yield put(AuthActions.updateCoachCardDetailsLoadingStop());
    payload.funcSuccess(error.response);
  }
}
/*************************************************/

export function* addCoachCardQualification({ payload}) {
  yield put(AuthActions.addCoachCardQualificationLoadingStart());
  try {
    const response = yield call(
      ApiCollections.addCoachCardQualification,
      payload.formData
    );
    yield put(AuthActions.addCoachCardQualificationSucess(response));
    yield put(AuthActions.addCoachCardQualificationLoadingStop());
    payload.funcSuccess(response);
  } catch (error) {
    yield put(AuthActions.addCoachCardQualificationFailure(error));
    yield put(AuthActions.addCoachCardQualificationLoadingStop());
    payload.funcSuccess(error.response);
  }
}
/*************************************************/

export function* updateCoachCardQualification({ payload}) {
  yield put(AuthActions.updateCoachCardQualificationLoadingStart());
  try {
    const response = yield call(
      ApiCollections.updateCoachCardQualification,
      payload.formData
    );
    yield put(AuthActions.updateCoachCardQualificationSucess(response));
    yield put(AuthActions.updateCoachCardQualificationLoadingStop());
    payload.funcSuccess(response);
  } catch (error) {
    yield put(AuthActions.updateCoachCardQualificationFailure(error));
    yield put(AuthActions.updateCoachCardQualificationLoadingStop());
    payload.funcSuccess(error.response);
  }
}
/*************************************************/

export function* removeCoachCardQualification({ payload}) {
  yield put(AuthActions.removeCoachCardQualificationLoadingStart());
  try {
    const response = yield call(
      ApiCollections.removeCoachCardQualification,
      payload
    );
    yield put(AuthActions.removeCoachCardQualificationSucess(response));
    yield put(AuthActions.removeCoachCardQualificationLoadingStop());
    payload.funcSuccess(response);
  } catch (error) {
    yield put(AuthActions.removeCoachCardQualificationFailure(error));
    yield put(AuthActions.removeCoachCardQualificationLoadingStop());
    payload.funcSuccess(error.response);
  }
}
/*************************************************/

export function* getCoachCardQualification({ payload}) {
  yield put(AuthActions.updateCoachCardQualificationLoadingStart());
  try {
    const response = yield call(
      ApiCollections.updateCoachCardQualification,
      payload.updatedQualification
    );
    yield put(AuthActions.getCoachCardQualificationSucess(response));
    yield put(AuthActions.getCoachCardQualificationLoadingStop());
  } catch (error) {
    yield put(AuthActions.getCoachCardQualificationFailure(error));
    yield put(AuthActions.getCoachCardQualificationLoadingStop());
  }
}
/*************************************************/

export function* trainerFeedback({ payload }) {
  // yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.trainerFeedback,
      payload.formData
    );
    yield put(AuthActions.trainerFeedbackSuccess(response));
    // yield put(AuthActions.loadingStop());
    payload.funcSuccess();
  } catch (error) {
    yield put(AuthActions.trainerFeedbackFailure(error));
    // yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getDaysRoutineForUserByTrainer({ payload }) {
  yield put(AuthActions.getDaysRoutineForUserByTrainerLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getDaysRoutineForUserByTrainer,
      payload?.payload
    );
    yield put(
      AuthActions.getDaysRoutineForUserByTrainerSuccess(response[0].routines)
    );
    payload?.callback(response[0].routines);
    yield put(AuthActions.getDaysRoutineForUserByTrainerLoadingStop());
  } catch (error) {
    yield put(AuthActions.getDaysRoutineForUserByTrainerFailure(error));
    yield put(AuthActions.getDaysRoutineForUserByTrainerLoadingStop());
  }
}

/*************************************************/

export function* updateDaysRoutineForUserByTrainer({ payload }) {
  yield put(AuthActions.updateDaysRoutineForUserByTrainerLoadingStart());
  try {
    const response = yield call(
      ApiCollections.updateDaysRoutineForUserByTrainer,
      payload?.payload
    );
    yield put(
      AuthActions.updateDaysRoutineForUserByTrainerSuccess(response[0].routines)
    );
    yield put(AuthActions.updateDaysRoutineForUserByTrainerLoadingStop());
    payload?.callback(response[0].routines);
  } catch (error) {
    yield put(AuthActions.updateDaysRoutineForUserByTrainerLoadingStop());
    yield put(AuthActions.updateDaysRoutineForUserByTrainerFailure(error));
  }
}

/*************************************************/
export function* addDayRoutineForUserByTrainer({ payload }) {
  yield put(AuthActions.addDaysRoutineForUserByTrainerLoadingStart());
  try {
    const response = yield call(
      ApiCollections.addDayRoutineForUserByTrainer,
      payload?.payload
    );
    yield put(AuthActions.addDayRoutineForUserByTrainerSuccess(response.data));
    yield put(AuthActions.addDaysRoutineForUserByTrainerLoadingStop());
    payload?.callback();
  } catch (error) {
    yield put(AuthActions.addDayRoutineForUserByTrainerFailure(error));
    yield put(AuthActions.addDaysRoutineForUserByTrainerLoadingStop());
  }
}

/*************************************************/

export function* deleteDayRoutineForUserByTrainer({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.deleteDayRoutineForUserByTrainer,
      payload.data
    );
    yield put(
      AuthActions.deleteDayRoutineForUserByTrainerSuccess(response.data)
    );
    yield put(AuthActions.loadingStop());
    payload.funcSuccess();
  } catch (error) {
    yield put(AuthActions.deleteDayRoutineForUserByTrainerFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getWeightGraphDetails({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getWeightGraphDetails, payload);
    yield put(AuthActions.getWeightGraphDetailsSuccess(response.userWeight));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.getWeightGraphDetailsFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getTrainerProfile({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getTrainerProfile, payload);
    yield put(AuthActions.getTrainerProfileSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.getTrainerProfileFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* registrationOverrideEmail({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.registrationOverrideEmail,
      payload
    );

    if (response instanceof Error) {
      throw new Error(
        "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal."
      );
    }

    yield put(AuthActions.loadingStop());
    payload.cb && payload.cb(response);
  } catch (err) {
    yield put(AuthActions.registration1Failure(err));
    yield put(AuthActions.loadingStop());
    payload.cb && payload.cb(err);
  }
}

/*************************************************/

export function* registrationPart1({ payload }) {
  yield put(AuthActions.loadingStart());

  try {
    const response = yield call(
      ApiCollections.registrationPart1,
      payload.formValues
    );
    yield put(AuthActions.loadingStop());
    if (response.access_token) {
      localStorage.setItem("token", response.access_token);
      yield put(AuthActions.registration1Success(response.access_token));
      yield put(payload.func(response));
      yield put(
        AuthActions.setRegistrationData({
          nutritionconceptID: response["$nutritionconcept"].id,
        })
      );
    } else if (!response.success) {
      yield put(AuthActions.registration1Failure(response));
      yield put(AuthActions.loadingStop());
    }
  } catch (error) {
    yield put(AuthActions.registration1Failure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* createUserAnamnese({ payload }) {
  try {
    const response = yield call(
      ApiCollections.createUserAnamnese,
      payload.formData
    );
    yield put(AuthActions.createUserAnamneseSuccess(response));
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(AuthActions.createUserAnamneseFailure(error));
  }
}

/*************************************************/

export function* getUserAnamnese({ payload }) {
  try {
    const response = yield call(
      ApiCollections.getUserAnamnese,
      payload.formData
    );
    yield put(AuthActions.getUserAnamneseSuccess(response));
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(AuthActions.getUserAnamneseFailure(error));
  }
}

/*************************************************/

export function* signOut() {
  try {
    localStorage.clear();
    yield put(AuthActions.signOutSuccess());
  } catch (error) {
    yield put(AuthActions.signOutFailure("SignOut Failed"));
  }
}

/*************************************************/

export function* createWebsite({ payload }) {
  yield put(AuthActions.createWebsiteLoading());
  try {
    const response = yield call(ApiCollections.createWebsite, payload.formData);
    yield put(AuthActions.createWebsiteSuccess(response));
    yield put(AuthActions.createWebsiteStop());
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(AuthActions.createWebsiteFailure(error));
    yield put(AuthActions.createWebsiteStop());
  }
}

/*************************************************/

export function* getWeekWiseImages({ payload }) {
  yield put(AuthActions.getWeekWiseImagesLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getWeekWiseImages,
      payload.formData
    );
    yield put(AuthActions.getWeekWiseImagesSuccess(response));
    yield put(AuthActions.getWeekWiseImagesLoadingStop());
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(AuthActions.getWeekWiseImagesFailure(error));
    yield put(AuthActions.getWeekWiseImagesLoadingStop());
  }
}

/*************************************************/

export function* getBodyMeasurements({ payload }) {
  yield put(AuthActions.getBodyMeasurementsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getBodyMeasurements,
      payload.formData
    );
    yield put(AuthActions.getBodyMeasurementsSuccess(response));
    yield put(AuthActions.getBodyMeasurementsLoadingStop());
    payload.callback && payload?.callback(response);
  } catch (error) {
    yield put(AuthActions.getBodyMeasurementsFailure(error));
    yield put(AuthActions.getBodyMeasurementsLoadingStop());
  }
}

/*************************************************/

export function* getWeekWiseWeight({ payload }) {
  yield put(AuthActions.getUserWeightWeekWiseLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getWeekWiseWeight,
      payload?.formData
    );
    yield put(AuthActions.getUserWeightWeekWiseSuccess(response));
    yield put(AuthActions.getUserWeightWeekWiseLoadingStop());
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(AuthActions.getUserWeightWeekWiseFailure(error));
    yield put(AuthActions.getUserWeightWeekWiseLoadingStop());
  }
}

/*************************************************/

export function* getUserWeightGoals({ payload }) {
  yield put(AuthActions.getUserWeightGoalDataLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getUserWeightGoals,
      payload?.formData
    );
    yield put(AuthActions.getUserWeightGoalDataSuccess(response));
    yield put(AuthActions.getUserWeightGoalDataLoadingStop());
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(AuthActions.getUserWeightGoalDataFailure(error));
    yield put(AuthActions.getUserWeightGoalDataLoadingStop());
  }
}

/*************************************************/

export function* setDailyRoutineData(payload) {
  try {
    console.log(payload);
    yield put(AuthActions.setDailyRoutineData({ ...payload }));
  } catch (e) {}
}

/************************* */

export function* updateUserConnectedAccount({ payload }) {
  try {
    const response = yield call(
      ApiCollections.updateUserConnectedAccount,
      payload
    );
    yield put(AuthActions.updateUserConnectedAccountSuccess(response));
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(AuthActions.getWeeklyCallsFailure(error));
  }
}

/*************************************************/

export function* getInfluencers({ payload }) {
  yield put(AuthActions.getInfluencersLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getInfluencers,
      payload?.formData
    );
    yield put(AuthActions.getInfluencersSuccess(response));
    yield put(AuthActions.getInfluencersLoadingStop());
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(AuthActions.getInfluencersFailure(error));
    yield put(AuthActions.getInfluencersLoadingStop());
  }
}

/*************************************************/

export function* getInfluencerDetails({ payload }) {
  yield put(AuthActions.getInfluencerDetailsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getInfluencerDetails,
      payload?.formData
    );
    yield put(AuthActions.getInfluencerDetailsSuccess(response));
    yield put(AuthActions.getInfluencerDetailsLoadingStop());
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(AuthActions.getInfluencersFailure(error));
    yield put(AuthActions.getInfluencerDetailsLoadingStop());
  }
}

/*************************************************/

export function* getFirstMeetingURLByTrainerID({ payload }) {
  yield put(AuthActions.getFirstMeetingURLByTrainerIDLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getFirstMeetingURLByTrainerID,
      payload?.formData
    );
    yield put(AuthActions.getFirstMeetingURLByTrainerIDSuccess(response));
    yield put(AuthActions.getFirstMeetingURLByTrainerIDLoadingStop());
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(AuthActions.getFirstMeetingURLByTrainerIDFailure(error));
    yield put(AuthActions.getFirstMeetingURLByTrainerIDLoadingStop());
  }
}

/*************************************************/

export function* getWeeklyCalls({ payload }) {
  yield put(AuthActions.getWeeklyCallsLoadingStart());
  try {
    const response = yield call(ApiCollections.getWeeklyCalls);
    yield put(AuthActions.getWeeklyCallsSuccess(response));
    yield put(AuthActions.getWeeklyCallsLoadingStop());
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(AuthActions.getWeeklyCallsFailure(error));
    yield put(AuthActions.getWeeklyCallsLoadingStop());
  }
}

/*************************************************/

export function* updateConnectedAccount({ payload }) {
  try {
    const response = yield call(ApiCollections.updateConnectedAccount, payload);
    yield put(AuthActions.updateConnectedAccountSuccess(response));
    payload.callback && payload.callback(response);
  } catch (error) {
    yield put(AuthActions.getWeeklyCallsFailure(error));
  }
}

/*************************************************/

export function* getLessonsCategories({ payload }) {
  yield put(AuthActions.getLessonsCategoriesLoadingStart());
  try {
    const response = yield call(ApiCollections.getLessonsCategories);
    yield put(AuthActions.getLessonsCategoriesSuccess(response));
    payload.callback && payload.callback(response);
    yield put(AuthActions.getLessonsCategoriesLoadingStop());
  } catch (error) {
    yield put(AuthActions.getLessonsCategoriesFailure(error));
    yield put(AuthActions.getLessonsCategoriesLoadingStop());
  }
}

/*************************************************/

export function* getLessonsByCategoryId({ payload }) {
  yield put(AuthActions.getLessonsByCategoryIdLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getLessonsByCategoryId,
      payload?.payload
    );
    yield put(AuthActions.getLessonsByCategoryIdSuccess(response));
    payload.callback && payload.callback(response);
    yield put(AuthActions.getLessonsByCategoryIdLoadingStop());
  } catch (error) {
    yield put(AuthActions.getLessonsByCategoryIdFailure(error));
    yield put(AuthActions.getLessonsByCategoryIdLoadingStop());
  }
}

/*************************************************/

export function* getLessonDetails({ payload }) {
  yield put(AuthActions.getLessonDetailsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getLessonsDetails,
      payload?.payload
    );
    yield put(AuthActions.getLessonDetailsSuccess(response));
    payload.callback && payload.callback(response);
    yield put(AuthActions.getLessonDetailsLoadingStop());
  } catch (error) {
    yield put(AuthActions.getLessonDetailsFailure(error));
    yield put(AuthActions.getLessonDetailsLoadingStop());
  }
}

/*************************************************/

export function* getDownloadableLessons({ payload }) {
  yield put(AuthActions.getDownloadableLessonsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getDownloadableLessons,
      payload?.payload
    );
    yield put(AuthActions.getDownloadableLessonsSuccess(response));
    payload.callback && payload.callback(response);
    yield put(AuthActions.getDownloadableLessonsLoadingStop());
  } catch (error) {
    yield put(AuthActions.getDownloadableLessonsFailure(error));
    yield put(AuthActions.getDownloadableLessonsLoadingStop());
  }
}

/*************************************************/

export function* getAllInstagramCategories({ payload }) {
  yield put(AuthActions.getAllInstagramCategoriesLoadingStart());
  try {
    const response = yield call(ApiCollections.getAllInstagramCategories);
    yield put(AuthActions.getAllInstagramCategoriesSuccess(response));
    payload?.callback(response);
    yield put(AuthActions.getAllInstagramCategoriesLoadingStop());
  } catch (error) {
    yield put(AuthActions.getAllInstagramCategoriesFailure(error));
    yield put(AuthActions.getAllInstagramCategoriesLoadingStop());
  }
}

/*************************************************/

export function* getAllInstagramPosts({ payload }) {
  yield put(AuthActions.getAllInstagramPostsLoadingStart());
  try {
    const response = yield call(ApiCollections.getAllInstagramPosts, payload);
    yield put(AuthActions.getAllInstagramPostsSuccess(response));
    payload?.callback(response);
    yield put(AuthActions.getAllInstagramPostsLoadingStop());
  } catch (error) {
    yield put(AuthActions.getAllInstagramPostsFailure(error));
    yield put(AuthActions.getAllInstagramPostsLoadingStop());
  }
}

/*************************************************/

export function* getAllInstagramPostsByCategories({ payload }) {
  yield put(AuthActions.getAllInstagramPostsByCategoriesLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getAllInstagramPostsByCategories,
      payload
    );
    yield put(AuthActions.getAllInstagramPostsByCategoriesSuccess(response));
    payload?.callback(response);
    yield put(AuthActions.getAllInstagramPostsByCategoriesLoadingStop());
  } catch (error) {
    yield put(AuthActions.getAllInstagramPostsByCategoriesFailure(error));
    yield put(AuthActions.getAllInstagramPostsByCategoriesLoadingStop());
  }
}

/*************************************************/

export function* getInstagramSubCategoriesByCategory({ payload }) {
  yield put(AuthActions.getInstagramSubCategoriesByCategoryLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getInstagramSubCategoriesByCategory,
      payload?.payload
    );
    yield put(AuthActions.getInstagramSubCategoriesByCategorySuccess(response));
    payload?.callback(response);
    yield put(AuthActions.getInstagramSubCategoriesByCategoryLoadingStop());
  } catch (error) {
    yield put(AuthActions.getInstagramSubCategoriesByCategoryFailure(error));
    yield put(AuthActions.getInstagramSubCategoriesByCategoryLoadingStop());
  }
}

/*************************************************/

export function* getAddressDetails({ payload }) {
  yield put(AuthActions.getAddressDetailsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getAddressDetails,
      payload?.payload
    );
    yield put(AuthActions.getAddressDetailsSuccess(response));
    payload?.callback(response);
    yield put(AuthActions.getAddressDetailsLoadingStop());
  } catch (error) {
    yield put(AuthActions.getAddressDetailsFailure(error));
    yield put(AuthActions.getAddressDetailsLoadingStop());
  }
}

/*************************************************/

export function* updateAddressDetails({ payload }) {
  yield put(AuthActions.updateAddressDetailsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.updateAddressDetails,
      payload?.payload
    );
    yield put(AuthActions.updateAddressDetailsSuccess(response));
    payload?.callback(response);
    yield put(AuthActions.updateAddressDetailsLoadingStop());
  } catch (error) {
    yield put(AuthActions.updateAddressDetailsFailure(error));
    yield put(AuthActions.updateAddressDetailsLoadingStop());
  }
}

/*************************************************/

export function* authSagas() {
  yield all([
    yield takeLatest(AuthActionTypes.SIGN_IN_REQUEST, signIn),
    yield takeLatest(AuthActionTypes.TOKEN_LOGIN_REQUEST, tokenLogin),
    yield takeLatest(
      AuthActionTypes.SIGN_IN_EXISTING_PLAN_REQUEST,
      existingPlanSignIn
    ),
    yield takeLatest(AuthActionTypes.SIGN_OUT_REQUEST, signOut),
    yield takeLatest(AuthActionTypes.PASSWORD_RESET_REQUEST, passwordReset),
    yield takeLatest(AuthActionTypes.CHANGE_PASSWORD_REQUEST, changePassword),
    yield takeLatest(AuthActionTypes.TRAINER_SIGN_UP_REQUEST, trainerSignUp),
    yield takeLatest(
      AuthActionTypes.TRAINER_DETAILS_EDIT_REQUEST,
      trainerDetailsEdit
    ),
    yield takeLatest(
      AuthActionTypes.GET_USERS_BY_TRAINER_REQUEST,
      getUsersByTrainer
    ),
    yield takeLatest(
      AuthActionTypes.GET_USER_DETAILS_BY_TRAINER_REQUEST,
      getUserDetailsByTrainer
    ),
    yield takeLatest(
      AuthActionTypes.GET_CLIENT_DETAILS_REQUEST,
      getClientDetails
    ),
    yield takeLatest(
      AuthActionTypes.UPDATE_USER_DETAILS_BY_TRAINER_REQUEST,
      updateUserDetailsByTrainer
    ),
    yield takeLatest(
      AuthActionTypes.GET_INVOICES_BY_TRAINER_REQUEST,
      getInvoicesByTrainer
    ),
    yield takeLatest(
      AuthActionTypes.GET_PAID_INVOICES_BY_TRAINER_REQUEST,
      getPaidInvoicesByTrainer
    ),
    yield takeLatest(
      AuthActionTypes.GET_INVOICES_OF_USER_BY_TRAINER_REQUEST,
      getInvoicesOfUserByTrainer
    ),
    yield takeLatest(
      AuthActionTypes.GET_TRAINER_DETAILS_REQUEST,
      getTrainerDetails
    ),
    yield takeLatest(
      AuthActionTypes.UPDATE_TRAINER_DETAILS_REQUEST,
      updateTrainerDetails
    ),
    yield takeLatest(
      AuthActionTypes.GET_COACH_CARD_WEBSITE_DETAILS_REQUEST,
      getCoachCardWebsiteDetails
    ),
    yield takeLatest(
      AuthActionTypes.UPDATE_COACH_CARD_WEBSITE_DETAILS_REQUEST,
      updateCoachCardWebsiteDetails
    ),
    yield takeLatest(
      AuthActionTypes.GET_COACH_CARD_DETAILS_REQUEST,
      getCoachCardDetails
    ),
    yield takeLatest(
      AuthActionTypes.UPDATE_COACH_CARD_DETAILS_REQUEST,
      updateCoachCardDetails
    ),
    yield takeLatest(
      AuthActionTypes.ADD_COACH_CARD_QUALIFICATION_REQUEST,
      addCoachCardQualification
    ),
    yield takeLatest(
      AuthActionTypes.UPDATE_COACH_CARD_QUALIFICATION_REQUEST,
      updateCoachCardQualification
    ),
    yield takeLatest(
      AuthActionTypes.REMOVE_COACH_CARD_QUALIFICATION_REQUEST,
      removeCoachCardQualification
    ),
    yield takeLatest(AuthActionTypes.TRAINER_FEEDBACK_REQUEST, trainerFeedback),
    yield takeLatest(
      AuthActionTypes.GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_REQUEST,
      getDaysRoutineForUserByTrainer
    ),
    yield takeLatest(
      AuthActionTypes.UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_REQUEST,
      updateDaysRoutineForUserByTrainer
    ),
    yield takeLatest(
      AuthActionTypes.ADD_DAY_ROUTINE_FOR_USER_BY_TRAINER_REQUEST,
      addDayRoutineForUserByTrainer
    ),
    yield takeLatest(
      AuthActionTypes.DELETE_DAY_ROUTINE_FOR_USER_BY_TRAINER_REQUEST,
      deleteDayRoutineForUserByTrainer
    ),
    yield takeLatest(
      AuthActionTypes.GET_WEIGHT_GRAPH_DETAILS_REQUEST,
      getWeightGraphDetails
    ),
    yield takeLatest(
      AuthActionTypes.GET_TRAINER_PROFILE_REQUEST,
      getTrainerProfile
    ),
    yield takeLatest(
      AuthActionTypes.REGISTRATION_PART1_REQUEST,
      registrationPart1
    ),
    yield takeLatest(
      AuthActionTypes.REGISTRATION_OVERRIDE_EMAIL,
      registrationOverrideEmail
    ),
    yield takeLatest(AuthActionTypes.CREATE_WEBSITE_REQUEST, createWebsite),
    yield takeLatest(
      AuthActionTypes.GET_WEEK_WISE_IMAGES_REQUEST,
      getWeekWiseImages
    ),
    yield takeLatest(
      AuthActionTypes.GET_BODY_MEASUREMENTS_REQUEST,
      getBodyMeasurements
    ),

    yield takeLatest(
      AuthActionTypes.GET_USER_WEIGHT_WEEK_WISE_REQUEST,
      getWeekWiseWeight
    ),
    yield takeLatest(
      AuthActionTypes.GET_USER_WEIGHT_GOAL_DATA_REQUEST,
      getUserWeightGoals
    ),
    yield takeLatest(AuthActionTypes.GET_INFLUENCERS_REQUEST, getInfluencers),
    yield takeLatest(
      AuthActionTypes.GET_INFLUENCER_DETAILS_REQUEST,
      getInfluencerDetails
    ),
    yield takeLatest(
      AuthActionTypes.GET_FIRST_MEETING_URL_BY_TRAINER_ID_REQUEST,
      getFirstMeetingURLByTrainerID
    ),
    yield takeLatest(
      AuthActionTypes.GET_WEEKLY_CALLS_FOR_SUGGESTIONS_REQUEST,
      getWeeklyCalls
    ),
    yield takeLatest(
      AuthActionTypes.UPDATE_CONNECTED_ACCOUNT,
      updateConnectedAccount
    ),
    yield takeLatest(
      AuthActionTypes.CREATE_USER_ANAMNESE_REQUEST,
      createUserAnamnese
    ),
    yield takeLatest(
      AuthActionTypes.UPDATE_USER_CONNECTED_ACCOUNT,
      updateUserConnectedAccount
    ),
    yield takeLatest(
      AuthActionTypes.GET_USER_ANAMNESE_REQUEST,
      getUserAnamnese
    ),
    yield takeLatest(
      AuthActionTypes.GET_LESSONS_CATEGORIES_REQUEST,
      getLessonsCategories
    ),
    yield takeLatest(
      AuthActionTypes.GET_LESSONS_BY_CATEGORY_ID_REQUEST,
      getLessonsByCategoryId
    ),
    yield takeLatest(
      AuthActionTypes.GET_LESSON_DETAILS_REQUEST,
      getLessonDetails
    ),
    yield takeLatest(
      AuthActionTypes.GET_DOWNLOADABLE_LESSON_REQUEST,
      getDownloadableLessons
    ),
    yield takeLatest(
      AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_REQUEST,
      getAllInstagramPostsByCategories
    ),
    yield takeLatest(
      AuthActionTypes.GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_REQUEST,
      getInstagramSubCategoriesByCategory
    ),

    yield takeLatest(
      AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_REQUEST,
      getAllInstagramPosts
    ),

    yield takeLatest(
      AuthActionTypes.GET_ALL_INSTAGRAM_CATEGORIES_REQUEST,
      getAllInstagramCategories
    ),

    yield takeLatest(
      AuthActionTypes.GET_ADDRESS_DETAILS_REQUEST,
      getAddressDetails
    ),

    yield takeLatest(
      AuthActionTypes.UPDATE_ADDRESS_DETAILS_REQUEST,
      updateAddressDetails
    ),

    // yield takeLatest(AuthActionTypes.SET_DAILY_ROUTINE_DATA, setDailyRoutineData),
  ]);
}

/*************************************************/
