import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Components
import Button from "components/formComponents/button/Button";

import FoodIconOne from "assets/images/App/techniker-krankenkasse-logo.svg";
import FoodIconTwo from "assets/images/App/aok.svg";
import FoodIconThree from "assets/images/App/DAK-Gesundheit_logo.svg";
import FoodIconFour from "assets/images/App/barmer_krankenkasse_logo.svg";
import FoodIconFive from "assets/images/App/ikk-gesundplus.svg";

import OnboardingSidebar from "./OnboardingSidebar";
import * as S from "./OnBoardingPartOne.styles";

const Starter = (props) => {
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("RegisteredUserDetails");
    localStorage.removeItem("UserRegistration");
    localStorage.removeItem("RegistrationData");
    localStorage.removeItem("SelectedDietItems");
    localStorage.removeItem("HealthScoreData");
    localStorage.removeItem("AnamneseUserId");
    localStorage.removeItem("AnamneseWithValues");
    localStorage.removeItem("Anamnese");
    localStorage.removeItem("NutrtionIds");
    localStorage.removeItem("discount");
  }, []);

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={1} />
          <S.OnBoardingContent className="onboarding-content">
            <div className="starter-content d-flex justify-content-center align-items-center">
              <div className="text-center d-flex flex-column align-items-center">
                <span className="text-size-14-28 text-green font-rubik-medium">
                  Coaching starten
                </span>
                <h1 className="h1 text-dark-1 font-rubik-medium">
                  Foodiary Coaching
                </h1>
                <p className="p1 text-dark-2 font-rubik-regular">
                  Schön, dass wir gemeinsam beginnen und deinen <br />{" "}
                  persönlichen Ernährungsplan entwickeln.
                </p>
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="2rem 0"
                  absolute={{ width: "fit-content", height: "2.8125rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={() => history.push("/onboarding/call-recording")}
                >
                  Lass uns beginnen
                </Button>
                <p className="text-size-14-28 text-dark-1 font-rubik-regular">
                  UNTERSTÜTZ DURCH:
                </p>
                <div className="companies">
                  <img width={70} src={FoodIconTwo} alt="cardIcon" />
                  <img width={50} src={FoodIconOne} alt="cardIcon" />
                  <img width={60} src={FoodIconThree} alt="cardIcon" />
                  <img width={74} src={FoodIconFive} alt="cardIcon" />
                  <img width={80} src={FoodIconFour} alt="cardIcon" />
                </div>
              </div>
            </div>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default Starter;
