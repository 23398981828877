import { useEffect, useState } from "react";
import { formatDate } from "configs/utils";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import { removeSpaceAndToLowerCase } from "configs/utils";
import TranslationMessage from "configs/translations/TranslationMessage";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as SupplementsActions from "redux/supplements/supplements.actions";
import { LAST_CLIENTS_CHART } from "./ChartData";
import * as S from "./newDashboard.styles";

function getAllYearMonthsOptions() {
  const months = [];
  const currentYear = new Date().getFullYear();

  for (let i = 0; i < 12; i++) {
    const date = new Date(currentYear, i);
    months.push({
      value: i + 1,
      month: i + 1,
      year: currentYear,
      label: date.toLocaleString("de-DE", { month: "short", year: "numeric" }),
    });
  }

  return months;
}

function getIsCheckListCompleted(checklist) {
  if (!checklist || !checklist.length) return true;
  const notCompleted = checklist.filter((item) => !item.checked);
  if (notCompleted.length) return false;
  return true;
}

function NoDataPlaceholder({ title, body }) {
  return (
    <S.NoDataPlaceholder>
      <span className="icon"></span>
      <span className="title">{title}</span>
      <p className="body">{body}</p>
    </S.NoDataPlaceholder>
  );
}

function LeadsTable({ trainerLeads }) {
  trainerLeads = trainerLeads ? [...trainerLeads].reverse() : null;
  let leads =
    trainerLeads && (trainerLeads?.slice(0, 4)).length
      ? trainerLeads.slice(0, 4)
      : null;

  return (
    <S.CardBox>
      <S.SelfCardHead>
        <h3>Neue Leads</h3>
        <Link to="/dashboard/leads/all">Alle anzeigen</Link>
      </S.SelfCardHead>
      {leads ? (
        <S.TableWrap>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {leads.map(({ userID, lead_status, prename, surename }) => {
                return (
                  <tr key={userID}>
                    <td>
                      {prename} {surename}
                    </td>
                    <td>
                      <div>
                        <span className="lead-status">
                          <TranslationMessage
                            id={`leads_status.${removeSpaceAndToLowerCase(
                              lead_status
                            )}`}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </S.TableWrap>
      ) : (
        <NoDataPlaceholder
          title={"Du hast aktuell keine Leads"}
          body={
            "Teile deine Webseite mit Kooperationspartnern oder beanspruche Leads von Foodiary"
          }
        />
      )}
    </S.CardBox>
  );
}

function NewClientsTable({ LastFourCustomers }) {
  let clients = (LastFourCustomers?.slice(0, 4)).length
    ? LastFourCustomers?.slice(0, 4)
    : null;

  return (
    <S.CardBox>
      <S.SelfCardHead>
        <h3>Neue Klienten</h3>
        <Link to="/dashboard/customers/list">Alle anzeigen</Link>
      </S.SelfCardHead>
      {clients ? (
        <S.TableWrap>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Vorname</th>
                <th>Nachname</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {clients.map((customer) => {
                const { userID, prename, surname } = customer;
                return (
                  <tr key={userID}>
                    <td>{`#${userID}`}</td>
                    <td>{prename}</td>
                    <td>{surname}</td>
                    <td>
                      <div>
                        <Link
                          to={`/dashboard/clients/overview/${userID}`}
                          className="table-button"
                        >
                          Details
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </S.TableWrap>
      ) : (
        <NoDataPlaceholder
          title={"Du hast aktuell keine Klienten"}
          body={
            "Gewinne Klienten indem, dass du Kooperation schließt und dein Social Media aufbaust. Lerne mehr in der Academy."
          }
        />
      )}
    </S.CardBox>
  );
}

function LeftMainContent({
  LastFourCustomers,
  nextExpertCall,
  onboardingChecks,
  onCheckUpdate,
  trainerLeads,
}) {
  const dispatch = useDispatch();
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const allYearMonthsOptions = getAllYearMonthsOptions();
  const [montlyClientsChartData, setMontlyClientsChartData] = useState(null);
  const [montlyLeadsChartData, setMontlyLeadsChartdata] = useState(null);
  const [currentTab, setCurrentTab] = useState("clients");

  const [chartDateFilter, setChartDateFilter] = useState(
    allYearMonthsOptions.find((op) => op.value === new Date().getMonth() + 1)
  );

  useEffect(() => {
    dispatch(
      DashboardActions.getClientsCurrentMonthChartDataRequest({
        payload: {
          trainerID: trainerProfileData?.trainerID,
          month: chartDateFilter.value,
          year: chartDateFilter.year,
        },
        callback: (response) => {
          console.log("callback hit : ", response);
          if (!response?.getNumberOfClients?.length) return;
          setMontlyClientsChartData(response.getNumberOfClients);
        },
      })
    );
    dispatch(
      SupplementsActions.getNumberofLeadsRequest({
        payload: {
          connected_trainer: trainerProfileData?.trainerID,
          month: chartDateFilter.value,
          year: chartDateFilter.year,
        },
        callback: (response) => {
          console.log("callback hit : ", response);
          if (!response?.getNumberOfLeads?.length) return;
          setMontlyLeadsChartdata(response.getNumberOfLeads);
        },
      })
    );
  }, [dispatch, chartDateFilter]);

  const isCheckListCompleted = getIsCheckListCompleted(onboardingChecks);
  const chartDates =
    currentTab === "clients"
      ? montlyClientsChartData?.map((item) => item.date)
      : montlyLeadsChartData?.map((item) => item.date);
  const chartProUserData =
    currentTab === "clients"
      ? montlyClientsChartData?.map((item) => parseInt(item["PRO-USER"], 10))
      : montlyLeadsChartData?.map((item) =>
          parseInt(item["numberOfLeads"], 10)
        );
  const chartPlusUserData =
    currentTab === "clients"
      ? montlyClientsChartData?.map((item) => parseInt(item["PLUS-USER"], 10))
      : null;

  const chartSeries =
    currentTab === "clients"
      ? [
          { name: "PRO-USER", data: chartProUserData },
          { name: "PLUS-USER", data: chartPlusUserData },
        ]
      : [{ name: "TRINER-LEADS", data: chartProUserData }];

  const chartOptions = {
    ...LAST_CLIENTS_CHART.options,
    xaxis: {
      categories: chartDates,
      labels: { formatter: (value) => new Date(value).getDate() },
    },
  };

  return (
    <S.DashboardLeftBottom>
      <S.DashboardBottomLeft>
        {montlyClientsChartData ? (
          <S.CardBox>
            <S.SelfCardHead>
              <S.ChartTabWrapper>
                <S.ChartTab
                  active={currentTab === "clients"}
                  onClick={() => setCurrentTab("clients")}
                >
                  Klienten
                </S.ChartTab>
                <S.ChartTab
                  active={currentTab === "leads"}
                  onClick={() => setCurrentTab("leads")}
                >
                  Leads
                </S.ChartTab>
              </S.ChartTabWrapper>
              <div className="chart-filters">
                <div className="chart-refs">
                  {currentTab === "clients" ? (
                    <>
                      <span className="chart-reference pro">PRO-Klienten</span>
                      <span className="chart-reference plus">
                        PLUS-Klienten
                      </span>
                    </>
                  ) : (
                    <span className="chart-reference pro">Leads</span>
                  )}
                </div>
                <Select
                  options={allYearMonthsOptions}
                  className="select-width"
                  classNamePrefix="react-select"
                  placeholder={chartDateFilter.label}
                  onChange={setChartDateFilter}
                  isSearchable={false}
                />
              </div>
            </S.SelfCardHead>
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="area"
            />
          </S.CardBox>
        ) : (
          ""
        )}
        <NewClientsTable LastFourCustomers={LastFourCustomers} />
      </S.DashboardBottomLeft>

      <S.DashboardBottomRight>
        <S.CardBox>
          <S.ExpertCallHeader>
            <h3>Nächster Expertencall</h3>
            <Link to="/dashboard/academy/experts-calls">Alle anzeigen</Link>
          </S.ExpertCallHeader>
          {nextExpertCall?.Datum ? (
            <S.ExpertContent>
              <div className="details">
                <span className="referant">{nextExpertCall?.Referent}</span>
                {nextExpertCall?.["Freie_Plätze"] === 0 && (
                  <div className="label danger">Ausgebucht</div>
                )}
                {nextExpertCall?.["Freie_Plätze"] > 0 &&
                  nextExpertCall?.["Freie_Plätze"] < 6 && (
                    <div className="label warning">
                      {nextExpertCall?.["Freie_Plätze"]} Plätze verfügbar
                    </div>
                  )}
                {nextExpertCall?.["Freie_Plätze"] > 5 && (
                  <div className="label success">
                    {nextExpertCall?.["Freie_Plätze"]} Plätze verfügbar
                  </div>
                )}
              </div>
              <div className="date-time">
                <span>
                  {`${formatDate(nextExpertCall?.Datum).day}, ${
                    formatDate(nextExpertCall?.Datum).date
                  }`}
                </span>
                <span>{nextExpertCall?.Uhrzeit}</span>
              </div>
            </S.ExpertContent>
          ) : (
            <S.ExpertContent>
              <div className="label success">Keine bevorstehenden Calls</div>
            </S.ExpertContent>
          )}
        </S.CardBox>

        {onboardingChecks && !isCheckListCompleted ? (
          <S.CardBox>
            <S.DashboardCheckList>
              <h4>Checkliste</h4>
              <p>Vervollständige jetzt dein Foodiary Profil.</p>
              <S.SelfMadeCheckBoxWrap>
                {onboardingChecks.map((item) => (
                  <S.CheckContentWrapper>
                    <S.SelfMadeCheckBoxRow
                      key={item.id}
                      className={item.checked ? "line-through" : ""}
                    >
                      <label className="form-control">
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked={item.checked === 1 ? true : false}
                          onChange={(e) =>
                            onCheckUpdate(e.target.checked, item?.id)
                          }
                        />
                      </label>
                      <span
                        onClick={() =>
                          window.open(item?.link_url || "", "_blank")
                        }
                      >
                        {item?.title}
                      </span>
                    </S.SelfMadeCheckBoxRow>
                    <div className={item.checked ? "completed" : "start"}>
                      {item.checked ? (
                        "Vollendet"
                      ) : (
                        <span
                          onClick={() =>
                            window.open(item?.link_url || "", "_blank")
                          }
                        >
                          Start
                        </span>
                      )}
                    </div>
                  </S.CheckContentWrapper>
                ))}
              </S.SelfMadeCheckBoxWrap>
            </S.DashboardCheckList>
          </S.CardBox>
        ) : (
          ""
        )}

        <LeadsTable trainerLeads={trainerLeads} />
      </S.DashboardBottomRight>
    </S.DashboardLeftBottom>
  );
}

export default LeftMainContent;
